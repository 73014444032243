import React from 'react'

import { Theme, Grid, Typography, makeStyles } from '@material-ui/core'

import Card from 'components/atoms/Card'
import TransactionsCard, { Transactions } from 'components/molecules/TransactionsCard'
import PageLayout from 'components/templates/PageLayout'

export interface PageTransactionsHistoryViewProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  subtitle: string,
  transactions: Transactions[],
}

const useStyles = makeStyles<Theme, PageTransactionsHistoryViewProps>((theme) => ({
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
}), { name: 'PageTransactionsHistoryView' })

export const PageTransactionsHistoryView = (props: PageTransactionsHistoryViewProps) => {
  const classes = useStyles(props)

  const {
    title,
    subtitle,
    transactions,
  } = props

  return (
    <PageLayout>
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Card
            mode={'white'}
          >
            <Typography
              variant={'h3'}
              align={'left'}
              className={classes.shadow}
            >
              {title}
            </Typography>
            <Typography
              variant={'h5'}
              align={'left'}
              color={'secondary'}
            >
              {subtitle}
            </Typography>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <TransactionsCard
            transactions={transactions}
          />
        </Grid>

      </Grid>
    </PageLayout>
  )
}

export default PageTransactionsHistoryView
