import React from 'react'
import classnames from 'classnames'

import { makeStyles, Theme } from '@material-ui/core'

import ErrorMessage from '../../atoms/ErrorMessage'
import LoadingIndicator from '../../atoms/LoadingIndicator'

export interface LoaderProps extends React.HTMLProps<HTMLDivElement> {
  innerRef?: any,
  opacity?: number,
  message?: React.ReactNode,
  loading?: boolean,
  error?: Error | null | undefined,
  children?: React.ReactNode,
  renderError?: (error?: Error, retry?: () => void) => React.ReactNode,
  renderLoader?: () => React.ReactNode,
  retry?: () => void,
  cancel?: () => void,
  cancelable?: boolean,
  doNotUnmountChildren?: boolean,
  wrapperProps?:  React.HTMLProps<HTMLDivElement>,
}

const  defaultRenderLoader = (message?: React.ReactNode) => {
  return <LoadingIndicator message={message} />
}
const defaultRenderError = (error?: Error, retry?: () => void, cancel?: () => void) => {
  return (
    <ErrorMessage
      error={error}
      retry={retry}
      cancel={cancel}
    />
  )
}

const useStyles = makeStyles<Theme, LoaderProps>((theme) => ({
  wrapper: ({ doNotUnmountChildren, loading, error }) => {
    const absoluteStyles = {
      position: 'relative' as const,
    }

    return {
      ...(doNotUnmountChildren ? absoluteStyles : {}),
      display: 'flex',
      width: '100%',
      height: '100%',
      minHeight: 'calc(100vh - 86px)',
      overflow: (loading || error) ? 'hidden' : 'initial',
      [theme.breakpoints.down('xs')]: {
        minHeight: 'calc(100vh - 62px)',
      },
    }
  },
  root:  ({ doNotUnmountChildren, opacity }) => {
    const absoluteStyles = {
      position: 'absolute' as const,
      top: 0,
      left: 0,
      zIndex: 9999,
    }

    return {
      ...(doNotUnmountChildren ? absoluteStyles : {}),
      display: 'flex',
      width: '100%',
      minHeight: '100%',
      backgroundColor: theme.palette.common.white,
      opacity,
    }
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}), { name: 'Loader' } )

export const Loader = (props: LoaderProps) => {
  const {
    innerRef,
    opacity = 1,
    message,
    loading = true,
    error,
    children,
    cancel: cancelFromProps,
    cancelable: cancelableFromProps = false,
    renderError = defaultRenderError,
    renderLoader = defaultRenderLoader,
    doNotUnmountChildren = false,
    retry,
    wrapperProps = {},
    className,
    ...otherProps
  } = props

  const [internalError, setInternalError] = React.useState<Error | null | undefined>(error)
  React.useEffect(() => setInternalError(error), [error])

  const cancel = cancelFromProps || (() => setInternalError(null))
  // always cancelable when `cancel` function is provided
  const cancelable = cancelFromProps ? true : cancelableFromProps

  const classes = useStyles({ opacity, doNotUnmountChildren, loading, error: internalError })
  const { className: wrapperClassName, ...otherWrapperProps } = wrapperProps

  return (
    <div
      ref={innerRef}
      className={classnames([
        classes.wrapper,
        wrapperClassName,
      ])}
      {...otherWrapperProps}
    >
      {
        (loading || internalError) ?
          <div
            className={classnames([
              classes.root,
              className,
              (loading || error) ? classes.center : null,
            ])}
            {...otherProps}
          >
            {
              loading ?
                renderLoader(message)
                :
                internalError ?
                  renderError(internalError, retry, cancelable ? cancel : undefined)
                  :
                  null
            }
          </div>
          :
          null
      }
      {
        doNotUnmountChildren || !(loading || error) ?
          children
          :
          null
      }
    </div>
  )
}

export default React.forwardRef<HTMLDivElement, LoaderProps>((props, ref) => (
  <Loader
    innerRef={ref}
    {...props}
  />
))
