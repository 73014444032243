/* eslint-disable max-len */
import React from 'react'

import PageHowItWorksView from 'domains/main/views/PageHowItWorksView'

import { routeShop, routeQuizzes, routeLogin } from 'domains/main'

import { useAuthContext } from 'auth'

const quizzesText = 'W aplikacji konkursowej Quizi, to Ty stajesz się panem swojego losu. Sam decydujesz, w którym konkursie bierzesz udział. Sprawdzasz wiedzę w swojej ulubionej kategorii i gromadzisz punkty, dzięki którym wygrywasz nagrody. Im więcej punktów zdobywasz, tym pewniejsza jest Twoja wygrana. Do dzieła!'
const rewardText = `W aplikacji Quizi dzielisz się swoją wiedzą i zdobywasz cenne nagrody.
Nagrody dostosowane są do kategorii konkursu, w którym bierzesz udział.
Aby wygrać, nie musisz być geniuszem matematycznym, nie musisz znać wszystkich stolic na świecie. Możesz świetnie gotować, być fanem fitnessu lub gier komputerowych i za to również czeka Cię nagroda. Będzie to nagroda wyjątkowa, bo dopasowana do Twoich zainteresowań. Znajdź interesujący Cię konkurs, pochwal się swoją wiedzą, a dostaniesz to, o czym marzyłeś.`

const tokensText = `Tokeny są środkiem płatniczym w aplikacji Quizi. Dzięki nim bierzesz udział w konkursie i wygrywasz nagrody. Na start dostajesz od nas kilka tokenów, a następnie możesz je dokupić, wygrać lub dostać dostęp do konkursu po zakupieniu produktu naszego sponsora.
Każdy token to jedno pytanie, a każde pytanie, na które udzielisz prawidłowej odpowiedzi to 100 punktów. Zastanawiasz się co z dzieje się z punktami jeśli udzielisz niepoprawnej odpowiedzi? Tracisz punkty? Dostajesz 0 punktów? Nic z tych rzeczy. Za nieprawidłową odpowiedź dostajesz aż 10 punktów.`

export interface CardContent {
  title: string,
  text: string,
  label: string,
  special: boolean,
  onClick: () => void,

}

export const PageHowItWorks = () => {
  const routeToQuizzes = routeQuizzes.useRouteTo()
  const routeToShop = routeShop.useRouteTo()
  const routeToLogin = routeLogin.useRouteTo()

  const { isLoggedIn } = useAuthContext()

  const cardsContent: CardContent[] = [
    {
      title: 'Konkursy...',
      text: quizzesText,
      label: 'Zobacz aktualne konkursy!',
      special: false,
      onClick: () => routeToQuizzes('running'),
    },
    {
      title: 'Nagrody...',
      text: rewardText,
      label: 'Zobacz zdobycze innych graczy!',
      special: false,
      onClick: () => routeToQuizzes('ended'),
    },
    {
      title: 'Tokeny...',
      text: tokensText,
      label: 'Doładuj tokeny!',
      special: true,
      onClick: isLoggedIn ? routeToShop : routeToLogin,
    },
  ]

  return (
    <PageHowItWorksView
      title={'Jak działa Quizi?'}
      cardsContent={cardsContent}
    />
  )
}

export default PageHowItWorks
