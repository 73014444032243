import DirectusSDK from '@directus/sdk-js'
import localforage from 'localforage'

import { addCustomRefresh } from './customRefresh'
import { addCustomLogin } from './customLogin'
import { stripAuthorizationHeaderFromAuthRequests } from './stripAuthorizationHeaderFromAuthRequests'

export const directus = new DirectusSDK(
  window._env_.REACT_APP_API_URL,
  {
    auth: {
      storage: localforage,
      mode: 'json',
      // @HACK - skip first time refresh, auto refreshing will be handled by `customRefresh`
      autoRefresh: false,
    },
  }
)

addCustomRefresh(directus, { autoRefresh: true })
addCustomLogin(directus)
stripAuthorizationHeaderFromAuthRequests(directus)

export default directus
