/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-types */

export const schema = {
  title: 'User',
  type: 'object',
  properties: {
    nick: {
      type: 'string',
      disabled: true,
      minLength: 1,
      label: 'Nick',
      errorMessage: 'Wpisz inny nick',
      uniforms: {
        variant: 'outlined',
      },
    },
    firstName: {
      type: 'string',
      disabled: true,
      minLength: 1,
      label: 'Imię',
      errorMessage: 'Wpisz poprawne imię',
      uniforms: {
        variant: 'outlined',
      },
    },
    lastName: {
      type: 'string',
      disabled: true,
      minLength: 1,
      label: 'Nazwisko',
      errorMessage: 'Wpisz poprawne nazwisko',
      uniforms: {
        variant: 'outlined',
      },
    },
  },
  required: ['nick', 'names'],
}
