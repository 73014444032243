import { formatDuration as formatDurationFNS, intervalToDuration } from 'date-fns'
import pl from 'date-fns/locale/pl'

export const formatDuration = (duration: number) => {
  return formatDurationFNS(
    intervalToDuration({ start: 0, end: duration }),
    {
      locale: pl,
    }
  )
}

export default formatDuration
