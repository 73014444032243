import React from 'react'

import { makeStyles, Theme, CardHeader, CardContent, Typography, List, ListItem, TypographyProps } from '@material-ui/core'

import Card from 'components/atoms/Card'
import LeaderBoard from '../LeaderBoard'

export interface ListCardProps extends React.HTMLProps<HTMLDivElement> {
  title?: string,
  subheader?: string,
  itemsList?: React.ReactNode[],
  leaderBoardProps?: {
    leaders: {
      avatar: string | null,
      email: string,
      score: number,
    }[],
    displayScore?: boolean,
  },
  titleTypographyProps?: TypographyProps,
  subheaderTypographyProps?: TypographyProps,
}

const useStyles = makeStyles<Theme, ListCardProps>((theme) => ({
  root: {},
  content: {
    paddingLeft: theme.spacing(3),
  },
  listItem: {
    justifyContent: 'left',
  },
  medals: {
    justifyContent: 'center',
  },
  header: {

  },
}), { name: 'ListCard' })

export const ListCard = (props: ListCardProps) => {
  const classes = useStyles(props)

  const {
    title,
    subheader,
    itemsList,
    leaderBoardProps,
    titleTypographyProps,
    subheaderTypographyProps,
  } = props

  return (
    <div
      className={classes.root}
    >
      <Card>
        <CardHeader
          title={title}
          subheader={subheader}
          titleTypographyProps={titleTypographyProps}
          subheaderTypographyProps={subheaderTypographyProps}
          classes={{ root: classes.header }}
        />
        <CardContent
          className={
            leaderBoardProps?.leaders ?
              classes.content : classes.textContent}
        >
          <List>
            {
              leaderBoardProps?.leaders ?
                <LeaderBoard
                  leaders={leaderBoardProps.leaders}
                  displayScore={leaderBoardProps.displayScore}
                />
                :
                itemsList?.map((item, index) => (
                  <ListItem
                    key={index}
                    className={classes.listItem}
                    disableGutters={true}
                    dense={true}
                  >
                    <Typography
                      variant={'subtitle1'}
                      color={'textPrimary'}
                      align={'center'}
                    >
                      {item}
                    </Typography>
                  </ListItem>
                ))
            }
          </List>
        </CardContent>
      </Card>
    </div>
  )
}

export default ListCard
