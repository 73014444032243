

/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-types */

export const schema = {
  title: 'PASSWORD',
  type: 'object',
  properties: {
    code: {
      type: 'string',
      errorMessage: 'Podaj kod',
      label: 'Kod',
      minLength: 1,
      uniforms: {
        variant: 'outlined',
      },
    },

  },
}
