import * as React from 'react'

function Cup(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={'24px'}
      height={'24px'}
      viewBox={'0 0 20 18'}
      fill={'none'}
      {...props}
    >
      <path
        // eslint-disable-next-line max-len
        d={'M17.39 2h-2.173V0H4.347v2H2.175C.978 2 0 2.9 0 4v1c0 2.55 2.087 4.63 4.772 4.94.343.75.88 1.41 1.564 1.926a5.66 5.66 0 002.36 1.034V16H4.347v2h10.869v-2h-4.348v-3.1a5.66 5.66 0 002.36-1.034 5.068 5.068 0 001.564-1.926c2.685-.31 4.771-2.39 4.771-4.94V4c0-1.1-.978-2-2.173-2zM2.175 5V4h2.174v3.82C3.087 7.4 2.174 6.3 2.174 5zm7.608 6c-1.793 0-3.26-1.35-3.26-3V2h6.521v6c0 1.65-1.467 3-3.26 3zm7.609-6c0 1.3-.913 2.4-2.174 2.82V4h2.174v1z'}
        fill={'inherit'}
      />
    </svg>
  )
}

export default Cup
