import { fetchCustomEndpoint } from 'api'
import { useAsyncFn } from 'utils/useAsyncFn'


interface PaymentDetails {
  id: string,
  amount: number,
  user: string,
  status: string,
  // eslint-disable-next-line camelcase
  date_created: string | null,
  // eslint-disable-next-line camelcase
  date_updated: string |null,

}

export const getPaymentDetails = async (id: string): Promise<PaymentDetails> => {
  const response = await fetchCustomEndpoint<PaymentDetails>({
    url: '/payments/' + id,
  })

  const data: PaymentDetails = response.data

  return data
}

export const useGetPaymentDetails = () => {
  return useAsyncFn(getPaymentDetails)
}


