import React from 'react'
import { useParams } from 'react-router'

import { makeStyles, Theme, Typography } from '@material-ui/core'
import { CreditCard, Fingerprint, Info, InsertEmoticon, MoodBad, AccessTime as AccessTimeIcon } from '@material-ui/icons'

import QuiziLogo from 'components/icons/QuiziLogo'

import Loader from 'components/molecules/Loader'
import Button from 'components/atoms/Button'
import Card from 'components/atoms/Card'

import PageLayout from 'components/templates/PageLayout'

import { useCoinsContext } from 'domains/main/context/coins'

import { routeQuizzes, routeShop } from '../PageMain'

import { useGetPaymentDetails } from 'domains/main/api/payments/getPaymentDetails'
import { formatDateTime } from 'utils/formatDateTime'

const useStyles = makeStyles<Theme>((theme) => ({
  paymentSuccessContainer: {
    margin: theme.spacing(3),
  },
  coinsTopUppedByPay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3),
  },
  coinsState: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cancelButton: {
    marginTop: theme.spacing(2),
    background: '#EB4D4B',
  },
  transactionDetailsItemWrapper: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  weWishLuckHeading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  emoticonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    color: '#65A8E8',
  },
  emoticon: {
    width: '50px',
    height: '50px',
  },
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
}), { name: 'PageShop' })


interface TransactionDetailsItemProps {
  icon: React.SVGProps<SVGSVGElement>,
  name: string,
  description: string | number,
}

const TransactionDetailsItem = (props: TransactionDetailsItemProps) => {

  const classes = useStyles()

  return (
    <div className={classes.transactionDetailsItemWrapper}>
      {props.icon}
      <Typography variant={'h5'}>
        &nbsp; {props.name} &nbsp;
      </Typography>
      <Typography
        variant={'h5'}
        color={'primary'}
      >
        {props.description}
      </Typography>
    </div>
  )

}

export const PagePaymentSuccess = () => {
  const { id } = useParams<{ id: string }>()

  const classes = useStyles()
  const {
    coins,
  } = useCoinsContext()

  const routeToQuizzes = routeQuizzes.useRouteTo()
  const routeToShop = routeShop.useRouteTo()

  const [paymentDetails, getPaymentDetails] = useGetPaymentDetails()

  React.useEffect(() => {
    getPaymentDetails(id)
    // only at first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const paymentValues = paymentDetails.value

  const translateStatuses = (status: string) => {
    let statusInPolish = ''
    if (status === 'PAID') {
      statusInPolish = 'Zapłacono'
    } else if (status === 'PENDING') {
      statusInPolish = 'Oczekiwanie na potwierdzenie płatności'
    } else {
      statusInPolish = 'Płatność nie powiodła się'
    }
    return statusInPolish
  }

  return (
    <>
      <Loader
        loading={paymentDetails.loading}
        error={paymentDetails.error}
        retry={() => getPaymentDetails(id)}
        message={'Ładowanie szczegółów transkcji...'}
      >
        <PageLayout>
          <Card>
            <div className={classes.paymentSuccessContainer}>
              <Typography
                variant={'h1'}
                className={classes.shadow}
                align={'center'}
              >
                Dziękujemy!
              </Typography>
              <div className={classes.coinsTopUppedByPay}>
                <Typography
                  variant={'h5'}
                  align={'center'}
                >
                  Stan tokenów
                </Typography>
                <Typography
                  variant={'h2'}
                  align={'center'}
                  className={classes.coinsState}
                >
                  {coins}
                </Typography>
                <QuiziLogo />
              </div>
              <Typography
                variant={'h2'}
                align={'center'}
                className={classes.shadow}
              >
                Szczegóły transakcji
              </Typography>
              {paymentValues && <>
                <TransactionDetailsItem
                  icon={<Fingerprint />}
                  name={'Idetyfikator transakcji:'}
                  description={paymentValues.id}
                />
                <TransactionDetailsItem
                  icon={<AccessTimeIcon />}
                  name={'Data transakcji:'}
                  description={paymentValues.date_created ? formatDateTime(paymentValues.date_created) : ''}
                />
                <TransactionDetailsItem
                  icon={<CreditCard />}
                  name={'Metoda płatności:'}
                  description={'TPAY'}
                />
                <TransactionDetailsItem
                  icon={<QuiziLogo />}
                  name={'Ilość zakupionych tokenów:'}
                  description={paymentValues.amount}
                />
                <TransactionDetailsItem
                  icon={<Info />}
                  name={'Status transakcji:'}
                  description={translateStatuses(paymentValues.status)}
                />
                {
                  paymentValues.status === 'PENDING' &&
                  <Button
                    onClick={() => getPaymentDetails(id)}
                    fullWidth
                    label={'Sprawdź status płatności ponownie'}
                  >
                  </Button>
                }
              </>
              }
              {paymentValues && paymentValues.status !== 'FAILED' ?
                <>
                  <Typography
                    variant={'h2'}
                    align={'center'}
                    color={'primary'}
                    className={classes.weWishLuckHeading}
                  >
                    Życzymy powodzenia i samych trafnych odpowiedzi
                  </Typography>
                  <div className={classes.emoticonWrapper}>
                    <InsertEmoticon className={classes.emoticon} />
                  </div>
                </>
                :
                <>
                  <Typography
                    variant={'h2'}
                    align={'center'}
                    color={'primary'}
                    className={classes.weWishLuckHeading}
                  >
                    Niestety Twoja płatność nie została zrealizowana, spróbuj ponownie
                  </Typography>
                  <div className={classes.emoticonWrapper}>
                    <MoodBad className={classes.emoticon} />
                  </div>
                </>}
              <Button
                label={'Konkursy'}
                onClick={() => routeToQuizzes('running')}
                fullWidth
                color={'secondary'}
              />

              <Button
                label={'Wyjdź'}
                className={classes.cancelButton}
                onClick={() => routeToShop()}
                fullWidth
                color={'primary'}
              />
            </div>
          </Card>
        </PageLayout>
      </Loader>
    </>
  )
}

export default PagePaymentSuccess
