import React from 'react'

import { makeStyles, Theme, Divider, Typography } from '@material-ui/core'

import Button from 'components/atoms/Button'
import Card from 'components/atoms/Card'

import Uniform from 'components/molecules/Uniform'

import { schema } from './registerSchema'

export interface RegisterFormModel {
  email: string,
  password: string,
  replyPassword: string,
}
export interface FormRegisterProps extends React.HTMLProps<HTMLDivElement> {
  routeToLogin: () => void,
  registerHandler: (model: RegisterFormModel) => void,
}

const useStyles = makeStyles<Theme, FormRegisterProps>((theme) => ({
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
}), { name: 'FormRegister' })

export const FormRegister = (props: FormRegisterProps) => {
  const classes = useStyles(props)
  const {
    routeToLogin,
    registerHandler,
    ...otherProps
  } = props

  return (
    <Card
      mode={'white'}
      {...otherProps}
    >
      <Typography
        variant={'h1'}
        align={'center'}
        color={'secondary'}
        className={classes.shadow}
      >
        Rejestracja
      </Typography>
      <Uniform
        schema={schema}
        onSubmit={(model: RegisterFormModel) => registerHandler(model)}
        submitField={() => (
          <Button
            className={classes.submitButton}
            type={'submit'}
            label={'Zarejestruj'}
            fullWidth={true}
          />
        )}
      />
      <Divider
        className={classes.divider}
      />
      <Button
        label={'Powrót'}
        fullWidth={true}
        color={'secondary'}
        className={classes.register}
        onClick={routeToLogin}
      />
    </Card>
  )
}

export default FormRegister
