import React from 'react'

import { makeStyles, Theme, Typography } from '@material-ui/core'

import Card, { CardProps } from 'components/atoms/Card'
import Button from 'components/atoms/Button'

export interface CoinsInsufficientMessageProps extends CardProps {
  onClickAddCoins: () => void,
}

const useStyles = makeStyles<Theme, CoinsInsufficientMessageProps>((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
  },
}), { name: 'CoinsInsufficientMessage' } )

export const CoinsInsufficientMessage = (props: CoinsInsufficientMessageProps) => {
  const classes = useStyles(props)
  const {
    onClickAddCoins,
    ...otherProps
  } = props

  return (
    <Card
      mode={'darkBlue'}
      {...otherProps}
    >
      <Typography
        variant={'h3'}
        align={'center'}
        className={classes.text}
      >
        Ups!
      </Typography>
      <Typography
        variant={'body1'}
        align={'center'}
        className={classes.text}
      >
        Wygląda na to, że nie masz czym zapłacić za następne pytanie!
      </Typography>
      <Button
        className={classes.button}
        classes={{ label: classes.buttonLabel }}
        color={'primary'}
        label={'Doładuj konto'}
        onClick={onClickAddCoins}
        fullWidth={true}
      />
    </Card>
  )
}

export default CoinsInsufficientMessage
