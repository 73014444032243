import React from 'react'
import { useHistory } from 'react-router'

import { Router } from 'components/atoms/Router'
import PageMaintenanceModeMessage from 'components/atoms/PageMaintenanceModeMessage'

import LogoControl from 'components/organisms/LogoControl'
import MenuControls from 'components/organisms/MenuControls'
import UserControl from 'components/organisms/UserControl'
import SideBarMenu from 'components/organisms/SideBarMenu'

import MainLayout from 'components/templates/MainLayout'

import { useAuthContext } from 'auth'

import { routes, routeShop, routeLogin, routeProfile, makeMenuPaths } from 'domains/main'

import { CoinsContextProvider, useCoinsContext } from 'domains/main/context/coins'
import { useMaintenance } from 'domains/main/api/app'

import { makeImgSrc } from 'domains/main/api/media'
import { Loader } from 'components/molecules/Loader/Loader'

export const PageMain = () => {
  const {
    isLoggedIn,
  } = useAuthContext()

  return (
    <CoinsContextProvider
      checkOnMount={isLoggedIn}
    >
      <PageMainInner />
    </CoinsContextProvider>
  )
}

export const PageMainInner = () => {
  const history = useHistory()
  const routeToLogin = routeLogin.useRouteTo()
  const routeToShop = routeShop.useRouteTo()
  const routeToProfile = routeProfile.useRouteTo()

  const [maintenanceState, getMaintenance] = useMaintenance()

  const {
    isLoggedIn,
    userData,
    logOut,
  } = useAuthContext()

  const {
    coins,
    refreshCoins,
  } = useCoinsContext()

  React.useEffect(() => {
    getMaintenance()
    // only for first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (isLoggedIn) refreshCoins()
  }, [isLoggedIn, refreshCoins])

  const [isSideBarOpen, setSideBarOpen] = React.useState<boolean>(false)

  const routeTo = React.useCallback((path: string) => {
    history.push(path)
    setSideBarOpen(false)
  }, [history])

  const onClickProfileHandler = React.useCallback(() => {
    routeToProfile()
    setSideBarOpen(false)
  }, [routeToProfile])
  const onClickLoginHandler = React.useCallback(() => {
    routeToLogin()
    setSideBarOpen(false)
  }, [routeToLogin])
  const onClickLogOutHandler = React.useCallback(() => {
    logOut()
    routeToLogin()
    setSideBarOpen(false)
  }, [logOut, routeToLogin])

  const menuItems = React.useMemo(() => makeMenuPaths({ routeTo }), [routeTo])
  const menuItemsFilteredByAuth = menuItems.filter(({ authRequired }) => isLoggedIn ? true : !authRequired)

  return (
    <Loader
      loading={maintenanceState.loading}
      retry={getMaintenance}
    >
      {
        maintenanceState.value?.status ?
          <PageMaintenanceModeMessage
            title={'Prace konserwacyjne'}
            infoText={'Zespół Quizi jest w trakcie prac nad aplikacją.'}
            comeBackText={'Wrócimy do Was wkrótce!'}
            retry={getMaintenance}
          />
          :
          <MainLayout
            isSideBarOpen={isSideBarOpen}
            onClickSideBarClose={() => setSideBarOpen(false)}
            contentSideBarBottom={
              <UserControl
                user={{
                  ...userData,
                  avatar: userData && userData.avatar && makeImgSrc(userData.avatar),
                }}
                isLoggedIn={isLoggedIn}
                onClickProfile={onClickProfileHandler}
                onClickLogin={onClickLoginHandler}
                onClickLogOut={onClickLogOutHandler}
              />
            }
            contentSideBarMain={
              <SideBarMenu
                menuItems={menuItemsFilteredByAuth}
              />
            }
            contentLogo={<LogoControl />}
            contentActions={
              <MenuControls
                isLoggedIn={isLoggedIn}
                coinsAmount={coins}
                onCoinClick={routeToShop}
                onClickOpenSideBar={() => setSideBarOpen(true)}
              />
            }
          >
            <Router<{ isLoggedIn: boolean }>
              filterParams={{ isLoggedIn }}
              routes={routes}
            />
          </MainLayout>
      }
    </Loader>
  )
}

export default PageMain
