import { useAsyncFn } from 'utils/useAsyncFn'

import directus from 'api/directus'

export const setPassword = async (token: string, password: string): Promise<void> => {
  const response = await directus.users.acceptInvite(token, password)

  return response
}

export const useSetPassword = () => {
  return useAsyncFn(setPassword)
}

