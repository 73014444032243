import React from 'react'

import { Typography, makeStyles, Theme } from '@material-ui/core'

import Card from 'components/atoms/Card'

import HowItWorksCardContent from 'components/molecules/HowItWorksCardContent'

import PageLayout from 'components/templates/PageLayout'

import { CardContent } from 'domains/main/pages/PageHowItWorks'

export interface PageHowItWorksViewProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  cardsContent: CardContent[],
}

const useStyles = makeStyles<Theme, PageHowItWorksViewProps>((theme) => ({
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
}), { name: 'PageHowItWorksView' })

export const PageHowItWorksView = (props: PageHowItWorksViewProps) => {
  const classes = useStyles(props)
  const {
    title,
    cardsContent,
  } = props

  return (
    <PageLayout>
      <Card
        mode={'white'}
      >
        <Typography
          variant={'h1'}
          align={'left'}
          color={'secondary'}
          className={classes.shadow}
        >
          {title}
        </Typography>
        {
          cardsContent.map((cardContent, index) => {
            return (
              <div
                key={index}
              >
                <HowItWorksCardContent
                  index={index}
                  label={cardContent.label}
                  title={cardContent.title}
                  text={cardContent.text}
                  special={cardContent.special}
                  onClick={cardContent.onClick}
                />
              </div>
            )
          })
        }
      </Card>
    </PageLayout>
  )
}

export default PageHowItWorksView
