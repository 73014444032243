import { Typography } from '@material-ui/core'
import Button from 'components/atoms/Button'
import Card from 'components/atoms/Card'
import PageLayout from 'components/templates/PageLayout'
import React from 'react'
import { routeShop } from '../PageMain'

export const PagePaymentFailed = () => {

  const routeToShop = routeShop.useRouteTo()

  return (
    <PageLayout>
      <Card mode={'white'}>
        <Typography variant={'h1'}>
          Uppss... Niestety transakacja nie doszła do skutku, spróbuj ponownie
        </Typography>
        <br/>
        <Button
          label={'Spróbuj ponownie'}
          onClick={() => routeToShop()}
          fullWidth
          color={'primary'}
        />
      </Card>
    </PageLayout>
  )
}

export default PagePaymentFailed
