import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import ReactPlayer from 'react-player'

import Ratio16X9 from 'components/atoms/Ratio16X9'

export interface VideoPlayerProps extends React.HTMLProps<HTMLDivElement> {
  url: string,
}

const useStyles = makeStyles<Theme, VideoPlayerProps>((theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
  },
  player: {
    width: '100%',
    height: '100%',
  },
}), { name: 'VideoPlayer' } )

const VideoPlayer = (props: VideoPlayerProps) => {
  const {
    url,
    ...otherProps
  } = props
  const classes = useStyles(props)

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Ratio16X9>
        <ReactPlayer
          className={classes.player}
          playing={true}
          controls={false}
          url={url}
          width={'100%'}
          height={'100%'}
          config={{
            youtube: {
              playerVars: { controls: 0, showinfo: 0 },
            },
            soundcloud: {
              options: {
                // eslint-disable-next-line camelcase
                auto_play: true,
                buying: false,
                sharing: false,
                download: false,
                // eslint-disable-next-line camelcase
                show_playcount: false,
                // eslint-disable-next-line camelcase
                show_artwork: false,
                // eslint-disable-next-line camelcase
                show_user: false,
                // eslint-disable-next-line camelcase
                start_track: 0,
              },
            },
            vimeo: {
              playerOptions: {
                autoplay: true,
              },
            },
          }}
        />
      </Ratio16X9>
    </div>
  )
}

export default VideoPlayer
