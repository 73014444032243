export const makeImgSrc = (src: string, { width = 533, height = 300 }: { width?: number, height?: number } = {}): string => {
  return (
    window._env_.REACT_APP_API_URL +
    '/assets/' +
    src +
    '?withoutEnlargement=true' +
    `width=${width}` +
    `&height=${height}` +
    '&quality=50' +
    '&fit=cover'
  )
}

export default makeImgSrc
