import React from 'react'
import { useParams } from 'react-router'
import uniqBy from 'lodash/uniqBy'

import { Typography } from '@material-ui/core'

import Card from 'components/atoms/Card'

import HeaderQuizzesList from 'components/molecules/HeaderQuizzesList'
import QuizFilterDialog from 'components/organisms/QuizFilterDialog'
import Loader from 'components/molecules/Loader'
import QuizItem from 'components/molecules/QuizItem'

import PageLayout from 'components/templates/PageLayout'

import { useGetQuizzes, QuizStatus, Quiz } from 'domains/main/api/quizzes'

import { routeHome, routeQuiz } from 'domains/main'
import { makeImgSrc } from 'domains/main/api/media'

import { formatDateTime } from 'utils/formatDateTime'
import { makeCategoryName } from 'utils/makeCategoryName'

const titles: Record<QuizStatus, string> = {
  upcoming: 'Nadchodzące konkursy',
  ended: 'Zakończone konkursy',
  running: 'Trwające konkursy',
}

const subtitles: Record<QuizStatus, string> = {
  upcoming: 'Nie przegap wspaniałych nagród!',
  ended: 'Sprawdź, co wygrali nasi gracze!',
  running: 'Wygraj teraz!',
}

const initialFilters = { category: '0', name: '' }

export const PageQuizzes = () => {
  const { status } = useParams<{ status: string }>()

  const [availableCategories, setAvailableCategories] = React.useState<{ label: string, value: string }[]>([])
  const [filters, setFilters] = React.useState<{ category?: string, name?: string }>(initialFilters)
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false)

  const routeToHome = routeHome.useRouteTo()
  const routeToQuiz = routeQuiz.useRouteTo()

  const [quizzesState, getQuizzesByStatus] = useGetQuizzes()
  const getQuizzes = React.useCallback(() => {
    // `status` is `QuizStatus`, if not the redirect in `useEffect` take place
    getQuizzesByStatus(status as QuizStatus)
  }, [status, getQuizzesByStatus])

  React.useEffect(() => {
    setFilters(initialFilters)
    setAvailableCategories([])
  }, [status])

  React.useEffect(() => {
    if (!['upcoming', 'running', 'ended'].includes(status)) {
      routeToHome()
    } else {
      getQuizzes()

    }
  }, [getQuizzes, routeToHome, status])

  const quizzes = quizzesState?.value?.results
  const quizzesFilter = quizzesState?.value?.filter as QuizStatus

  const onClickCTAHandler = React.useCallback((quiz: Quiz) => {
    routeToQuiz(quiz.id)
  }, [routeToQuiz])

  const openDetails = React.useCallback((id: string) => {
    routeToQuiz(id)
  }, [routeToQuiz])

  const mapCategoriesFromQuizzes = React.useCallback(() => {
    const arrayOfAvailableCategories: { label: string, value: string }[] = []
    arrayOfAvailableCategories.push({ label: 'Wszystkie kategorie', value: '0' })
    quizzes?.forEach((quiz: Quiz) => {
      if (quiz.categories && quiz.categories.length > 1) {
        arrayOfAvailableCategories.push({ label: 'Wiedza ogólna', value: '1' })
      } else if (quiz.categories && quiz.categories.length === 1) {
        arrayOfAvailableCategories.push({ label: quiz.categories[0].name, value: quiz.categories[0].id })
      }
    })
    const arrayOfAvaliblesCategoriesWithoutDuplicates = uniqBy(arrayOfAvailableCategories, 'value')
    setAvailableCategories(arrayOfAvaliblesCategoriesWithoutDuplicates)
  }, [quizzes])

  const filteredQuizzes = quizzes?.filter((el) => {
    return (el.name.toLowerCase().includes(filters.name ? filters.name.toLowerCase() : ''))
      &&
      ((el.categories?.length === 1 && el.categories[0]?.id === filters.category)
        || (el.categories && el.categories?.length > 1 && filters.category === '1')
        || !filters.category
        || filters.category === '0')
  })

  return (
    <Loader
      loading={quizzesState.loading}
      error={quizzesState.error}
      retry={getQuizzes}
      message={'Ładowanie quizów...'}
    >
      <PageLayout>
        <HeaderQuizzesList
          title={quizzesFilter && titles[quizzesFilter]}
          subtitle={subtitles[quizzesFilter]}
        />
        {
          filteredQuizzes && filteredQuizzes.length > 0 ?
            filteredQuizzes.map((quiz) => {
              return (
                <QuizItem
                  onClickItem={() => openDetails(quiz.id)}
                  key={quiz.id}
                  img={quiz?.prize?.media && makeImgSrc(quiz?.prize?.media)}
                  category={makeCategoryName(quiz)}
                  title={quiz.name}
                  sponsor={quiz.sponsor?.name}
                  isLocked={quiz.isLocked}
                  isUpcoming={quizzesFilter === 'upcoming'}
                  isEnded={quizzesFilter === 'ended'}
                  timeChipLabel={
                    quizzesFilter === 'upcoming' ?
                      formatDateTime(quiz.startTime)
                      :
                      // for both `ended` and `running` display end date
                      formatDateTime(quiz.endTime)
                  }
                  winner={quiz?.winner?.email}
                  onClickCTA={() => onClickCTAHandler(quiz)}
                />
              )
            })
            :
            <Card>
              <Typography
                gutterBottom={true}
                variant={'h5'}
                align={'center'}
                color={'textSecondary'}
              > Brak wyników do wyświetlenia
              </Typography>
            </Card>
        }
        <QuizFilterDialog
          mapCategoriesFromQuizzes={mapCategoriesFromQuizzes}
          setFilters={setFilters}
          availableCategories={availableCategories}
          open={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
        />
      </PageLayout>
    </Loader>
  )
}

export default PageQuizzes
