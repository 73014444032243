

/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-types */

export const schema = {
  title: 'CODE',
  type: 'object',
  properties: {
    code: {
      type: 'string',
      errorMessage: 'Wpisz kod',
      label: 'Kod',
      minLength: 1,
      uniforms: {
        type: 'string',
        variant: 'outlined',
      },
    },
  },
  required: ['code'],
}
