import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

export interface PageLayoutProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode,
}

const useStyles = makeStyles<Theme, PageLayoutProps>((theme) => ({
  root: {
    width: '100%',
    maxWidth: 600,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: '0 auto',
    height: 'max-content',
  },
}), { name: 'PageLayout' } )

export const PageLayout = (props: PageLayoutProps) => {
  const classes = useStyles(props)
  const {
    children,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      {children}
    </div>
  )
}

export default PageLayout
