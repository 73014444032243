import React from 'react'
import { AxiosError } from 'axios'
import Snackbars from 'components/atoms/Snackbars'

import Loader from 'components/molecules/Loader'
import HeaderQuizQuestion from 'components/molecules/HeaderQuizQuestion'

import PageLayout from 'components/templates/PageLayout'

import QuizQuestion from 'components/organisms/QuizQuestion'
import QuizAnswers, { Answer } from 'components/organisms/QuizAnswers'

import ViewQuestionsExceeded from 'domains/main/views/ViewQuestionsExceeded'
import ViewCoinsInsufficient from 'domains/main/views/ViewCoinsInsufficient'

import { useReportQuestion } from 'domains/main/api/question'
import { makeImgSrc } from 'domains/main/api/media'

import { useCoinsContext } from 'domains/main/context/coins'
import { useGetQuizQuestion } from 'domains/main/api/quizzes'

import { routeQuiz } from 'domains/main/pages'

const QUESTIONS_EXCEEDED_MESSAGE = 'Can\'t find new question'
const COINS_INSUFFICIENT_CODE = 'PAYMENT_REQUIRED'
const QUIZ_IS_NOT_RUNNING_MESSAGE = 'Quiz is not running'

export interface ViewQuizQuestionProps extends React.HTMLProps<HTMLDivElement> {
  quizUuid: string,
  quizName: string,
  categoryName: string,
  selectedAnswer: Answer | null,
  setSelectedAnswer: (answer: Answer) => void,
  saveSelectedAnswer: () => void,
}

export const ViewQuizQuestion = (props: ViewQuizQuestionProps) => {
  const {
    quizUuid,
    quizName,
    categoryName,
    selectedAnswer,
    setSelectedAnswer,
    saveSelectedAnswer,
  } = props

  const routeToQuiz = routeQuiz.useRouteTo()

  const {
    refreshCoins,
  } = useCoinsContext()

  const [quizQuestionState, getQuizQuestion] = useGetQuizQuestion()
  React.useEffect(() => {
    getQuizQuestion(quizUuid)
      .finally(() => refreshCoins())
    // only for first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const questionId = quizQuestionState?.value?.id
  const errorResponse = (quizQuestionState?.error as AxiosError)?.response?.data?.errors[0] as { extensions: { code: string }, message: string }
  const questionsExceeded = errorResponse?.message === QUESTIONS_EXCEEDED_MESSAGE
  const coinsInsufficient = errorResponse?.extensions?.code === COINS_INSUFFICIENT_CODE
  const quizIsNotRunning = errorResponse?.message === QUIZ_IS_NOT_RUNNING_MESSAGE
  if (quizIsNotRunning) routeToQuiz(quizUuid)

  // RAPORTING WRONG QUESTION
  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(null)
  const [reportQuestionState, reportQuestion] = useReportQuestion()
  const report = React.useCallback(async () => {
    if (!questionId) return
    await reportQuestion(questionId)
    setSnackbarMessage('Dziękujemy! Zwrócimy Ci koszt pytania, jeżeli zgłoszenie jest słuszne.')
  }, [questionId, reportQuestion])

  return (
    questionsExceeded ?
      <ViewQuestionsExceeded
        quizUuid={quizUuid}
      />
      :
      coinsInsufficient ?
        <ViewCoinsInsufficient />
        :
        <Loader
          loading={quizQuestionState.loading}
          error={quizQuestionState.error}
          message={'Ładowanie pytania...'}
          retry={() => getQuizQuestion(quizUuid)}
        >
          <Loader
            loading={reportQuestionState.loading}
            error={reportQuestionState.error}
            retry={report}
            message={'Zgłaszanie pytania...'}
          >
            <PageLayout>
              {
                quizQuestionState.value ?
                  <>
                    <HeaderQuizQuestion
                      categoryName={categoryName}
                      quizName={quizName}
                      answerTime={quizQuestionState.value.answerTime}
                      answerTimeRemaining={quizQuestionState.value.answerTimeRemaining}
                      onTimeExceed={saveSelectedAnswer}
                    />
                    <QuizQuestion
                      question={quizQuestionState.value.question}
                      pictureURL={quizQuestionState.value.media && makeImgSrc(quizQuestionState.value.media)}
                      videoOrAudioURL={quizQuestionState.value.video}
                    />
                    <QuizAnswers
                      selectedAnswer={selectedAnswer}
                      onSelect={setSelectedAnswer}
                      onClickReport={report}
                      onConfirm={saveSelectedAnswer}
                      answers={quizQuestionState.value.answers}
                    />
                  </>
                  :
                  null
              }
              <Snackbars
                snackbarMessage={snackbarMessage}
                onClose={() => setSnackbarMessage(null)}
              />
            </PageLayout>
          </Loader>
        </Loader>
  )
}

export type { Answer } from 'components/organisms/QuizAnswers'
export default ViewQuizQuestion
