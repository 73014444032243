import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

import AppBar from 'components/templates/AppBar'
import SideBar from 'components/templates/SideBar'

import { hideScrollbar } from 'utils/hideScrollbar'

export interface MainLayoutProps extends React.HTMLProps<HTMLDivElement> {
  contentLogo: React.ReactNode,
  contentActions: React.ReactNode,
  contentSideBarBottom: React.ReactNode,
  contentSideBarMain: React.ReactNode,
  children: React.ReactNode,

  isSideBarOpen: boolean,
  onClickSideBarClose: () => void,
}


const useStyles = makeStyles<Theme, MainLayoutProps>((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    paddingTop: 86,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 62,
    },
    background: theme.palette.background?.main,
    overflowX: 'hidden',
    overflowY: 'auto',

    ...hideScrollbar,
  },
  main: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    ...hideScrollbar,
  },
}), { name: 'MainLayout' } )

export const MainLayout = (props: MainLayoutProps) => {
  const classes = useStyles(props)
  const {
    contentLogo,
    contentActions,
    contentSideBarBottom,
    contentSideBarMain,
    children,

    isSideBarOpen,
    onClickSideBarClose,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <header>
        <AppBar
          contentLogo={contentLogo}
          contentActions={contentActions}
        />
      </header>
      <aside>
        <SideBar
          isOpen={isSideBarOpen}
          onClickClose={onClickSideBarClose}
          contentBottom={contentSideBarBottom}
          contentMain={contentSideBarMain}
        />
      </aside>
      <main
        className={classes.main}
      >
        {children}
      </main>
    </div>
  )
}

export default MainLayout
