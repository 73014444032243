import React from 'react'
import classnames from 'classnames'

import { makeStyles, Theme } from '@material-ui/core'

import QuiziLogo from 'components/icons/QuiziLogo'

import HeaderAnswer from 'components/atoms/HeaderAnswer'
import Button from 'components/atoms/Button'

import LeaderBoard from 'components/molecules/LeaderBoard'

export interface QuizAnswerResultProps extends React.HTMLProps<HTMLDivElement> {
  questionPrice: number,
  answerWasValid: boolean,
  leaders?: {
    avatar: string | null,
    email: string,
    score: number,
  }[],
  onClickNextQuestion: () => void,
  onClickLeave: () => void,
}

const useStyles = makeStyles<Theme, QuizAnswerResultProps>((theme) => ({
  root: {},
  buttonWrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  buttonNextQuestion: {
    width: 250,
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexWrap: 'nowrap',
    padding: theme.spacing(2),
    margin: '20px auto',
  },
  buttonNextClose: {
    width: 130,
    padding: '5px 0',
  },
  buttonReport: {
    marginBottom: 0,
    cursor: 'pointer',
    fontSize: 11,
    color: theme.palette.error.main,
  },
}), { name: 'QuizAnswerResult' })

export const QuizAnswerResult = (props: QuizAnswerResultProps) => {
  const classes = useStyles(props)
  const {
    questionPrice,
    answerWasValid,
    leaders,
    onClickNextQuestion,
    onClickLeave,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <HeaderAnswer
        text={answerWasValid ? 'To prawidłowa odpowiedź!' : 'Następnym razem się uda.'}
        isValid={answerWasValid}
      />
      <Button
        className={classnames([
          classes.button,
          classes.buttonNextQuestion,
        ])}
        label={'Następne pytanie: ' + questionPrice}
        endIcon={<QuiziLogo />}
        onClick={onClickNextQuestion}
      />
      {
        leaders ?
          <LeaderBoard
            leaders={leaders}
          />
          :
          null
      }
      <div
        className={classes.buttonWrapper}
      >
        <Button
          className={classnames([
            classes.button,
            classes.buttonNextClose,
          ])}
          label={'Wyjdź'}
          variant={'outlined'}
          color={'secondary'}
          onClick={onClickLeave}
        />
      </div>
    </div>
  )
}

export default QuizAnswerResult
