import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { ContactResponse, ContactCooperationData } from './types'

export const contactCooperation = async (data: ContactCooperationData): Promise<ContactResponse> => {
  const response = await fetchCustomEndpoint<ContactResponse>({
    url: '/contact/cooperation',
    method: 'POST',
    data,
  })

  const result: ContactResponse = response.data

  return result
}

export const useContactCooperation = () => {
  return useAsyncFn(contactCooperation)
}
