import React from 'react'
import classnames from 'classnames'

import { Typography, makeStyles, Theme } from '@material-ui/core'

import Card from 'components/atoms/Card'
import Button from 'components/atoms/Button'

export interface BecomeSponsorCardProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  subtitle: string,
  text: string,
  endingText: string,
  showButton?: boolean,
  label?: string,
  route?: () => void,
}

const useStyles = makeStyles<Theme, BecomeSponsorCardProps>((theme) => ({
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
  whiteText: {
    color: theme.palette.secondary.contrastText,
  },
  shadowLight: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
  patreonText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  lastChanceText: {
    marginBottom: theme.spacing(2),
  },
}), { name: 'BecomeSponsorCard' })

export const BecomeSponsorCard = (props: BecomeSponsorCardProps) => {
  const classes = useStyles(props)
  const {
    title,
    subtitle,
    text,
    label,
    showButton,
    endingText,
    route,
  } = props

  return (
    <Card
      mode={'darkBlue'}
    >

      <Typography
        variant={'h1'}
        className={classnames([
          classes.shadowLight,
          classes.whiteText,
        ])}
      >
        {title}
      </Typography>
      <Typography
        variant={'h5'}
        className={classnames([
          classes.shadowLight,
          classes.whiteText,
        ])}
      >
        {subtitle}
      </Typography>
      <Typography
        variant={'h5'}
        align={'center'}
        className={classnames([
          classes.patreonText,
          classes.whiteText,
        ])}
      >
        {text}
      </Typography>
      <Typography
        variant={'h3'}
        align={'center'}
        className={classnames([
          classes.shadowLight,
          classes.whiteText,
          classes.lastChanceText,
        ])}
      >
        {endingText}
      </Typography>
      {
        showButton ?
          <Button
            color={'primary'}
            label={label}
            fullWidth={true}
            onClick={route}
          />
          :
          null
      }
    </Card>
  )
}

export default BecomeSponsorCard
