import React from 'react'

import { CoinsBalance } from 'domains/main/api/coinTransactions'

export interface CoinsContextState {
  coins: number | null,
  refreshCoins: () => Promise<CoinsBalance>,
}

export const CoinsContext = React.createContext<CoinsContextState>({
  coins: null,
  refreshCoins: () => {
    console.error('You probably forgot to wrap component in `CoinsContextProvider`')
    return Promise.reject()
  },
})

export const useCoinsContext = () => React.useContext(CoinsContext)

export default CoinsContext
