import shuffle from 'lodash/shuffle'

import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { QuizQuestion } from './types'

export const getQuizQuestion = async (quizUuid: string): Promise<QuizQuestion> => {
  const response = await fetchCustomEndpoint<QuizQuestion>({
    url: `/quizzes/${quizUuid}/question`,
    method: 'POST',
  })

  const data: QuizQuestion = response.data

  return {
    ...data,
    answers: Array.isArray(data.answers) ? shuffle(data.answers) : data.answers,
  }
}

export const useGetQuizQuestion = () => {
  return useAsyncFn(getQuizQuestion)
}
