/* eslint-disable max-len */
import React from 'react'

function PersonQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={21}
      height={21}
      viewBox={'0 0 21 23'}
      fill={'none'}
      {...props}
    >
      <path
        d={'M19.5 10.5V12H18v-1.5h1.5zm-2-5H16V5a3 3 0 016 0c0 .97-.5 1.88-1.29 2.41l-.3.19c-.57.4-.91 1.01-.91 1.7v.2H18v-.2c0-1.19.6-2.3 1.59-2.95l.29-.19c.39-.26.62-.69.62-1.16a1.5 1.5 0 10-3 0v.5zM8 9c2.67 0 8 1.34 8 4v3H0v-3c0-2.66 5.33-4 8-4zm0-9a4 4 0 110 8 4 4 0 010-8zm0 10.9c-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V13c0-.64-3.13-2.1-6.1-2.1zm0-9a2.1 2.1 0 100 4.2 2.1 2.1 0 000-4.2z'}
        fill={'#225998'}
      />
    </svg>
  )
}

export default PersonQuestion
