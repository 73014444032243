import React from 'react'

import { makeStyles, Theme, Typography, Grid, Divider, Link } from '@material-ui/core'

import Button from 'components/atoms/Button'
import Card, { CardProps } from 'components/atoms/Card'

import Uniform from 'components/molecules/Uniform'

import { schema } from './loginSchema'

export interface FormLoginProps extends Omit<CardProps, 'mode'> {
  mode?: CardProps['mode'],
  onSubmitLoginByEmailAndPassword: (model: { password: string, email: string }) => void,
  onClickLoginByFacebook: () => void,
  onClickLoginByGoogle: () => void,
  onClickRegister: () => void,
  onClickForgetPassword: () => void,
}

const useStyles = makeStyles<Theme, FormLoginProps>((theme) => ({
  submitButton: {
    marginTop: theme.spacing(1),
  },
  forget: {
    'marginBottom': theme.spacing(2),
    'marginTop': theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttons: {
    marginBottom: theme.spacing(1),
  },
  buttonsSpace: {
    padding: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  register: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
}), { name: 'FormLogin' })

export const FormLogin = (props: FormLoginProps) => {
  const classes = useStyles(props)
  const {
    onSubmitLoginByEmailAndPassword,
    onClickLoginByFacebook,
    onClickForgetPassword,
    onClickLoginByGoogle,
    onClickRegister,
    ...otherProps
  } = props

  return (
    <Card
      mode={'white'}
      {...otherProps}
    >
      <Typography
        variant={'h1'}
        align={'center'}
        color={'secondary'}
        className={classes.shadow}
      >
        Logowanie
      </Typography>
      <Uniform
        schema={schema}
        onSubmit={onSubmitLoginByEmailAndPassword}
      />
      <Link
        onClick={onClickForgetPassword}
      >
        <Typography
          variant={'h5'}
          align={'center'}
          className={classes.forget}
          color={'secondary'}
        >
          Nie pamiętasz hasła?
        </Typography>
      </Link>
      <Grid
        container
        className={classes.buttons}
      >
        <Grid
          item
          xs={6}
          className={classes.buttonsSpace}
        >
          <Button
            label={'Kontynuuj z Facebookiem'}
            mode={'facebook'}
            fullWidth={true}
            onClick={onClickLoginByFacebook}
          />
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.buttonsSpace}
        >
          <Button
            label={'Kontynuuj z Googlem'}
            mode={'google'}
            fullWidth={true}
            onClick={onClickLoginByGoogle}
          />
        </Grid>
      </Grid>
      <Divider
        className={classes.divider}
      />
      <Typography
        variant={'h3'}
        align={'center'}
        className={classes.shadow}
        gutterBottom={true}
      >
        Nie masz jeszcze konta?
      </Typography>
      <Button
        label={'Zarejestruj się!'}
        fullWidth={true}
        className={classes.register}
        onClick={onClickRegister}
      />
    </Card>
  )
}

export default FormLogin
