import React from 'react'

import { Typography, makeStyles, Theme, Divider } from '@material-ui/core'

import Card from 'components/atoms/Card'
import Button from 'components/atoms/Button'

interface quizzesProps {
  text: string,
  label: string,
}

interface suggestQuestionProps {
  text: string,
  label: string,
}

interface editProfileProps {
  text: string,
  label: string,
}

export interface WelcomeCardLoggedInProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  subtitle: string,
  quizzes: quizzesProps,
  suggestQuestion: suggestQuestionProps,
  editProfile: editProfileProps,
  routes: {
    routeToQuizzes: () => void,
    routeToAddQuestion: () => void,
    routeToProfile: () => void,
  },
}

const useStyles = makeStyles<Theme, WelcomeCardLoggedInProps>((theme) => ({
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}), { name: 'WelcomeCardLoggedIn' })

export const WelcomeCardLoggedIn = (props: WelcomeCardLoggedInProps) => {
  const classes = useStyles(props)

  const {
    title,
    subtitle,
    quizzes,
    suggestQuestion,
    editProfile,
    routes,
  } = props

  const {
    routeToQuizzes,
    routeToProfile,
    routeToAddQuestion,
  } = routes


  return (
    <Card
      mode={'white'}
    >
      <div
        className={classes.marginTop}
      >
        <Typography
          color={'textPrimary'}
          variant={'h1'}
          className={classes.shadow}
        >
          {title}
        </Typography>
        <Typography
          color={'textPrimary'}
          variant={'h5'}
          className={classes.shadow}
        >
          {subtitle}
        </Typography>
      </div>
      <Divider
        className={classes.divider}
      />
      <div
        className={classes.marginTop}
      >
        <Typography
          color={'textPrimary'}
          variant={'h5'}
          align={'center'}
          className={classes.shadow}
        >
          {quizzes.text}
        </Typography>
        <Button
          fullWidth={true}
          mode={'green'}
          onClick={routeToQuizzes}
          className={classes.marginTop}
          label={quizzes.label}
        />
      </div>
      <div
        className={classes.marginTop}
      >
        <Typography
          color={'textPrimary'}
          variant={'h5'}
          align={'center'}
          className={classes.shadow}
        >
          {suggestQuestion.text}
        </Typography>
        <Button
          fullWidth={true}
          mode={'default'}
          onClick={routeToAddQuestion}
          className={classes.marginTop}
          label={suggestQuestion.label}
        />
      </div>
      <div
        className={classes.marginTop}
      >
        <Typography
          color={'textPrimary'}
          variant={'h5'}
          align={'center'}
          className={classes.shadow}
        >
          {editProfile.text}
        </Typography>
        <Button
          fullWidth={true}
          onClick={routeToProfile}
          color={'secondary'}
          className={classes.marginTop}
          label={editProfile.label}
        />
      </div>
    </Card>
  )
}

export default WelcomeCardLoggedIn
