import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles, Theme } from '@material-ui/core'

import QuiziLogoText from 'components/icons/QuiziLogoText'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LogoControlProps extends React.HTMLProps<HTMLDivElement> {
}

const useStyles = makeStyles<Theme, LogoControlProps>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingLeft: theme.spacing(3),
  },
  link: {
    height: 27,
  },
  logo: {
    height: 27,
  },
}), { name: 'LogoControl' } )

export const LogoControl = (props: LogoControlProps) => {
  const classes = useStyles(props)
  const { ...otherProps } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Link
        to={'/'}
        className={classes.link}
      >
        <QuiziLogoText
          className={classes.logo}
        />
      </Link>
    </div>
  )
}

export default LogoControl
