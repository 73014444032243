import React from 'react'

import { Button, makeStyles, Theme, Typography } from '@material-ui/core'
import { ErrorOutline, Replay, Cancel } from '@material-ui/icons'

export interface ErrorMessageProps extends React.HTMLProps<HTMLDivElement> {
  error: Error | null | undefined,
  retry?: () => void,
  cancel?: () => void,
}

const useStyles = makeStyles<Theme, ErrorMessageProps>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: 60,
  },
  message: {
    marginTop: 16,
  },
  retryButton: {
    marginTop: 8,
    width: 200,
  },
  cancelButton: {
    marginTop: 8,
    width: 200,
  },
}), { name: 'ErrorMessage' } )

export const ErrorMessage = (props: ErrorMessageProps) => {
  const classes = useStyles(props)
  const {
    error,
    retry,
    cancel,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <ErrorOutline
        className={classes.icon}
      />
      {
        error?.message ?
          <Typography
            className={classes.message}
            variant={'caption'}
          >
            {error.message}
          </Typography>
          :
          null
      }
      {
        retry ?
          <Button
            className={classes.retryButton}
            color={'primary'}
            variant={'text'}
            onClick={retry}
            startIcon={<Replay />}
          >
            Ponów
          </Button>
          :
          null
      }
      {
        cancel ?
          <Button
            className={classes.cancelButton}
            color={'primary'}
            variant={'text'}
            onClick={cancel}
            startIcon={<Cancel />}
          >
            Anuluj
          </Button>
          :
          null
      }
    </div>
  )
}

export default ErrorMessage
