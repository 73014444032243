import React from 'react'
import { useParams } from 'react-router'
import { AxiosError } from 'axios'

import Loader from 'components/molecules/Loader'

import ViewQuizQuestion, { Answer } from 'domains/main/views/ViewQuizQuestion'
import ViewQuizAnswerResult from 'domains/main/views/ViewQuizAnswerResult'

import { routeQuiz } from 'domains/main'

import { makeCategoryName } from 'utils/makeCategoryName'

import { useGetQuiz, useAnswerQuizQuestion, AnswerResult } from 'domains/main/api/quizzes'

const QUIZ_IS_NOT_RUNNING_MESSAGE = 'Quiz is not running'

export const PageQuizQuestion = () => {
  const { quizUuid } = useParams<{ quizUuid: string }>()
  const routeToQuiz = routeQuiz.useRouteTo()

  // GETTING QUIZ
  const [quizState, getQuiz] = useGetQuiz()
  React.useEffect(() => {
    getQuiz(quizUuid).then((quiz) => {
      // redirect ended quiz
      if (quiz.endTime < new Date()) routeToQuiz(quizUuid)
    })
  }, [getQuiz, quizUuid, routeToQuiz])

  // ANSWERING QUIZ QUESTION
  const [answering, setAnswering] = React.useState<boolean>(false)
  const [answerResult, setAnswerResult] = React.useState<AnswerResult | null>(null)
  const [selectedAnswer, setSelectedAnswer] = React.useState<Answer | null>(null)
  const [answerQuestionState, answerQuestion] = useAnswerQuizQuestion(quizUuid)
  const saveSelectedAnswer = React.useCallback(async () => {
    setAnswering(true)
    try {
      const result = await answerQuestion(selectedAnswer?.text)
      setAnswerResult(result)
    } catch (error) {
      setAnswerResult(null)
    } finally {
      setSelectedAnswer(null)
      setAnswering(false)
    }
  }, [answerQuestion, selectedAnswer])

  const answerErrorResponse = (answerQuestionState?.error as AxiosError)?.response?.data?.errors[0] as { extensions: { code: string }, message: string }
  const quizIsNotRunning = answerErrorResponse?.message === QUIZ_IS_NOT_RUNNING_MESSAGE
  if (quizIsNotRunning) routeToQuiz(quizUuid)

  return (
    <Loader
      loading={quizState.loading}
      error={quizState.error}
      message={'Ładowanie quizu...'}
    >
      <Loader
        loading={answerQuestionState.loading || answering}
        error={answerQuestionState.error}
        message={'Zapisywanie odpowiedzi...'}
        retry={saveSelectedAnswer}
      >
        {
          quizState.value ?
            answerResult ?
              <ViewQuizAnswerResult
                quizUuid={quizUuid}
                questionId={answerResult.questionId}
                questionPrice={quizState.value.questionPrice}
                answerWasValid={answerResult.wasValid}
                validAnswerText={answerResult.validAnswer.text}
                onClickNextQuestion={() => setAnswerResult(null)}
              />
              :
              <ViewQuizQuestion
                quizUuid={quizUuid}
                quizName={quizState.value.name}
                categoryName={makeCategoryName(quizState.value)}
                selectedAnswer={selectedAnswer}
                setSelectedAnswer={setSelectedAnswer}
                saveSelectedAnswer={saveSelectedAnswer}
              />
            :
            null
        }
      </Loader>
    </Loader>

  )
}

export default PageQuizQuestion
