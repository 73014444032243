import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import { makeStyles, Theme, StylesProvider } from '@material-ui/core'

import AppThemeProvider from 'themes/AppThemeProvider'

import DomainMain from 'domains/main'

import createGenerateClassName from './createGenerateClassName'
import ServiceWorkerUpdateSnackbar from './ServiceWorkerUpdateSnackbar'
import ServiceWorkerInstallSnackbar from './ServiceWorkerInstallSnackbar'

import { logInByEmailAndPassword, checkLogin, logOut, saveTokens } from 'api'

import Auth from 'auth'

const generateClassName = createGenerateClassName({
  productionPrefix: 'quizi-',
})

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
}), { name: 'App' } )

const App = () => {
  const classes = useStyles()

  const accessToken = useSearchParam('accessToken')
  const expiresAt = useSearchParam('expires')
  const refreshToken = useSearchParam('refreshToken')

  const handleOAuthCallback = React.useCallback(async () => {
    if(accessToken && expiresAt && refreshToken) {
      return saveTokens({
        accessToken,
        expiresAt,
        refreshToken,
      }).then(() => {
        window.history.replaceState({}, '', window.location.pathname)
      })
    } else {
      return Promise.reject()
    }
  }, [accessToken, expiresAt, refreshToken])

  return (
    <StylesProvider
      generateClassName={generateClassName}
    >
      <AppThemeProvider
        themeVariant={'main'}
      >
        <div
          className={classes.root}
        >
          <Auth
            logOut={logOut}
            checkLogin={checkLogin}
            handleOAuthCallback={handleOAuthCallback}
            logInByEmailAndPassword={logInByEmailAndPassword}
          >
            <BrowserRouter>
              <DomainMain />
            </BrowserRouter>
          </Auth>
        </div>
        <ServiceWorkerInstallSnackbar />
        <ServiceWorkerUpdateSnackbar />
      </AppThemeProvider>
    </StylesProvider>
  )
}

export default App
