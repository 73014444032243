import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { RegisterUserResponse } from './types'

export const registerUser = async (email: string) => {
  const response = await fetchCustomEndpoint<RegisterUserResponse>({
    url: '/users/register',
    method: 'POST',
    data: {
      email,
      redirect: window._env_.REACT_APP_URL + '/register',
    },
    headers: {
      // do NOT send token even it is stored in the storage
      Authorization: undefined,
    },
  })

  const data: RegisterUserResponse = response.data

  return data
}

export const useRegisterUser = () => {
  return useAsyncFn(registerUser)
}

