import merge from 'lodash/merge'

import { createMuiTheme, SimplePaletteColorOptions, ThemeOptions } from '@material-ui/core/styles'

const mainThemeBaseOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#65A8E8',
    },
    secondary: {
      main: '#225998',
    },
    error: {
      main: '#EB1615',
    },
    warning: {
      main: '#eb4d4b',
    },
    success: {
      main: '#00b894',
    },
    grey: {},
    text: {
      primary: '#225998',
      secondary: '#34495e',
    },
    gradients: {
      primary: 'linear-gradient(270deg, #1D8388 1.77%, #27B5BC 100%)',
      secondary: 'linear-gradient(270deg, #D800B7 1.77%, #FF67E8 100%)',
    },
    background: {
      main: 'linear-gradient(162.1deg, #65A8E8 44.48%, #57B3C6 71.95%, #4DCDB4 100%)',
      special: '#4DCDB4',
      specialHover: '#42ad98',
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
  },
  typography: {
    fontFamily: [
      'open sans',
    ].join(','),
    fontSize: 14,
    h1: {
      fontSize: 34,
      letterSpacing: '0px',
      fontWeight: 700,
      lineHeight: '48px',
    },
    h2: {
      fontSize: 28,
      letterSpacing: '0px',
      fontWeight: 700,
      lineHeight: '36px',
    },
    h3: {
      fontSize: 25,
      letterSpacing: '0px',
      fontWeight: 700,
      lineHeight: '24px',
    },
    h4: {
      fontSize: 22,
      letterSpacing: '',
      fontWeight: 700,
      lineHeight: '24px',
    },
    h5: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '24px',
      textTransform: 'none',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: '24px',
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '24px',
    },
    caption: {
      fontSize: 12,
      letterSpacing: '0.15px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    button: {
      textTransform: 'none',
      fontSize: 15,
      fontWeight: 500,
      lineHeight: '24px',
    },
  },
}

export const mainThemeExtendedOptions: ThemeOptions = {
  overrides: {
    MuiContainer: {
      root: {
        'padding': 16,
        '@media (min-width: 600px)': {
          padding: 24,
        },
      },
    },
    MuiCardHeader: {
      root: {
        'padding-bottom': 8,
      },
    },
    MuiChip: {
      root: {
        flexFlow: 'row-reverse',
      },
      icon: {
        marginLeft: '-6px',
        marginRight: '5px',
      },
      colorPrimary: {
        backgroundColor: mainThemeBaseOptions.palette?.common?.white,
        color: (mainThemeBaseOptions.palette?.secondary as SimplePaletteColorOptions)?.main,
      },
      colorSecondary: {
        backgroundColor: mainThemeBaseOptions.palette?.common?.white,
        color: '#65A8E8',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: mainThemeBaseOptions.palette?.common?.white,
      },
      label: {
        fontSize: 19,
      },
    },
    MuiCard: {
      root: {
        '& .MuiCardContent-root:last-child': {
          paddingBottom: 16,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: (mainThemeBaseOptions.palette?.secondary as SimplePaletteColorOptions)?.main,
      },
    },
    MuiFormLabel: {
      asterisk: {
        'color': (mainThemeBaseOptions.palette?.error as SimplePaletteColorOptions)?.main,
        '&$error': {
          color: (mainThemeBaseOptions.palette?.error as SimplePaletteColorOptions)?.main,
        },
      },
    },
    MuiCardContent: {
      root: {
        'padding': 8,
        '&:last-child': {
          paddingBottom: 8,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 35,
        backgroundColor: mainThemeBaseOptions.palette?.common?.white,
      },
      input: {
        padding: '6px 0 7px 6px',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 700,
      },
      body: {
        fontWeight: 500,
      },
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      InputLabelProps: { shrink: true },
    },
    MuiButton: {
      variant: 'contained',
      color: 'primary',
    },
    MuiRadio: {
      size: 'small',
    },
  },
}

export const mainThemeOptions = createMuiTheme(
  merge({}, mainThemeBaseOptions, mainThemeExtendedOptions)
)

export const mainTheme = createMuiTheme(mainThemeOptions)

export default mainTheme
