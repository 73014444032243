/* eslint-disable max-len */
import React from 'react'

function ScrollIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={21}
      height={21}
      viewBox={'0 0 21 21'}
      fill={'none'}
      {...props}
    >
      <path
        d={'M15.8 18c-.4 1.2-1.5 2-2.8 2H3c-1.7 0-3-1.3-3-3v-1h12.2c.4 1.2 1.5 2 2.8 2h.8zM17 0c1.7 0 3 1.3 3 3v1h-2V3c0-.6-.4-1-1-1s-1 .4-1 1v13h-1c-.6 0-1-.4-1-1v-1H3V3c0-1.7 1.3-3 3-3h11zM6 4v2h7V4H6zm0 4v2h6V8H6z'}
        fill={'#225998'}
      />
    </svg>
  )
}

export default ScrollIcon
