/* eslint-disable max-len */
import React from 'react'

import PageHomeView from 'domains/main/views/PageHomeView'

import { routeQuizzes, routeHowItWorks, routeBecomeSponsor, routeLogin, routeProfile, routeAddQuestion } from 'domains/main'
import { logInByGoogle, logInByFacebook } from 'api/auth'
import { useAuthContext } from 'auth'

import smartphone from 'images/smartphone.jpeg'

const sponsorCardText = `Quizi to niepowtarzalne miejsce na aktywną i unikalną reklamę Twojej marki.

Zostając patronem naszego konkursu, masz gwarancję efektywnej reklamy i szerokiej promocji Twojego produktu lub usługi.

Możemy stworzyć dla Ciebie spersonalizowany konkurs, dedykowany wyłącznie Twojej marce.

Jeśli jesteś zainteresowany podjęciem z nami współpracy i chcesz, aby Twój produkt był nagrodą w naszym konkursie, skontaktuj się z nami używając przygotowanego niżej formularza.`


const howItWorksSteps = [
  'Odpowiadasz na pytania',
  'Zdobywasz cenne punkty',
  'Wygrywasz wyjątkowe nagrody',
]

export const PageHome = () => {
  const routeToQuizzes = routeQuizzes.useRouteTo()
  const routeToProfile = routeProfile.useRouteTo()
  const routeToAddQuestion = routeAddQuestion.useRouteTo()
  const routeToLogin = routeLogin.useRouteTo()
  const routeToHowItWorks = routeHowItWorks.useRouteTo()
  const routeToBecomeSponsor = routeBecomeSponsor.useRouteTo()

  const { isLoggedIn } = useAuthContext()

  return (
    <PageHomeView
      isLoggedIn={isLoggedIn}
      welcomeCardLoggedInProps={{
        title: 'Cześć!',
        subtitle: 'Gotowy na dzisiejsze wyzwania?',
        quizzes: {
          text: 'Wyszukaj quiz i wygraj już teraz!',
          label: 'Wygraj!',
        },
        suggestQuestion: {
          text: 'Masz pomysł na pytanie? Zaproponuj nam je!',
          label: 'Zaproponuj pytanie',
        },
        editProfile: {
          text: 'Wygrałeś i chcesz dodać adres? Chcesz zmienić avatar?',
          label: 'Edytuj profil',
        },
        routes: {
          routeToQuizzes: () => routeToQuizzes('running'),
          routeToProfile,
          routeToAddQuestion,
        },
      }}
      welcomeCardProps={{
        title: 'Witaj w Quizi!',
        firstSubtitle: 'Jesteś gotowy na nowe wyzwania?',
        secondSubtitle: 'Odpowiadaj na pytania z ogromnej ilości kategorii i walcz o nagrody!',
        appImg: smartphone,
        routes: {
          routeToLogin,
          routeToQuizzes: () => routeToQuizzes('running'),
        },
        logInByGoogle,
        logInByFacebook,
        callToAction: {
          header: 'Zaloguj się i wygrywaj!',
          subheader: 'Przy pierwszej rejestracji dostajesz aż:',
          tokenAmount: 5,
          textBeforeButtons: 'Już teraz sprawdź swoją wiedzę!',
        },
        callToActionLoggedIn: {
          header: 'Wygraj już teraz!',
          subheader: 'Znajdź quiz i zdobądź wspaniałe nagrody.',
        },
      }}
      howItWorksCardProps={{
        title: 'Jak działa Quizi?',
        subtitle: 'Wygraj już dziś!',
        steps: howItWorksSteps,
        routes: {
          quizzes: () => routeToQuizzes('running'),
          howItWorks: () => routeToHowItWorks(),
        },
      }}
      becomeSponsorCardProps={{
        title: 'Zostań Patronem!',
        label: 'Sprawdź jak wygląda współpraca!',
        showButton: true,
        subtitle: 'Czyli kilka słów o współpracy',
        text: sponsorCardText,
        endingText: ' Quizi to niepowtarzalne miejsce na aktywną i nietuzinkową reklamę Twojej marki!',
        route: () => routeToBecomeSponsor(),
      }}
    />
  )
}

export default PageHome
