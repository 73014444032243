/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-types */

export const mailSchema = {
  title: 'NotificationMail',
  type: 'object',
  properties: {
    email: {
      type: 'string',
      format: 'email',
      label: 'E-mail',
      disabled: true,
      minLength: 1,
      uniforms: {
        type: 'email',
        variant: 'outlined',
      },
    },
  },
}

export const phoneSchema = {
  title: 'NotificationPhone',
  type: 'object',
  properties: {

    phone: {
      type: 'string',
      default: '+48',
      pattern: '\\+48[0-9]{9}$',
      errorMessage: 'Wpisz wyłącznie liczby oraz prefix +48',
      label: 'Numer telefonu',
      uniforms: {
        variant: 'outlined',
      },
    },
  },
}
