import * as React from 'react'

function ThirdPlace(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={'30px'}
      height={'30px'}
      viewBox={'0 0 30 30'}
      fill={'none'}
      {...props}
    >
      <path
        d={'M24.844.938l-3.047 4.898-3.07 4.992h-7.383l.023-.047 2.93-4.71L17.484.937h7.36z'}
        fill={'url(#prefix__paint0_linear)'}
      />
      <path
        opacity={0.2}
        d={'M24 .938l-5.672 9.14H12.61l5.72-9.14h-.821L14.297 6.07l-2.953 4.711h7.36l3.093-4.945 3.07-4.899H24z'}
        fill={'#424242'}
      />
      <path
        // eslint-disable-next-line max-len
        d={'M19.219 7.969H10.78a1.88 1.88 0 00-1.875 1.875v3.75a.94.94 0 00.938.937c.515 0 1.172-.422 1.172-.937V11.25a.47.47 0 01.468-.469h7.032a.47.47 0 01.468.469v2.32c0 .47.54.867.985.938.07 0 .117.023.187.023a.94.94 0 00.938-.937v-3.75a1.88 1.88 0 00-1.875-1.875z'}
        fill={'#FF9800'}
      />
      <path
        opacity={0.2}
        // eslint-disable-next-line max-len
        d={'M19.219 8.438a1.41 1.41 0 011.406 1.406v3.75a.47.47 0 01-.469.469h-.093c-.282-.047-.61-.305-.61-.493v-2.32a.94.94 0 00-.937-.938h-7.032a.94.94 0 00-.937.938v2.344c0 .21-.375.469-.703.469a.47.47 0 01-.469-.47v-3.75a1.41 1.41 0 011.406-1.405h8.438zm0-.47H10.78a1.88 1.88 0 00-1.875 1.876v3.75a.94.94 0 00.938.937c.515 0 1.172-.422 1.172-.937V11.25a.47.47 0 01.468-.469h7.032a.47.47 0 01.468.469v2.32c0 .47.54.867.985.938.07 0 .117.023.187.023a.94.94 0 00.938-.937v-3.75a1.88 1.88 0 00-1.875-1.875z'}
        fill={'#424242'}
      />
      <path
        d={'M15.023 29.063a8.906 8.906 0 100-17.813 8.906 8.906 0 000 17.813z'}
        fill={'url(#prefix__paint1_radial)'}
      />
      <path
        // eslint-disable-next-line max-len
        d={'M18.352 22.313c0-1.946-1.641-2.32-1.641-2.32s1.477-.61 1.477-2.204c0 0 .164-2.789-3.329-2.789-2.25 0-3.304 1.383-3.304 2.695h1.968s0-1.101 1.383-1.101c1.36 0 1.313 1.289 1.313 1.289s.07 1.383-1.36 1.383h-1.195v1.547h1.219c1.476 0 1.476 1.382 1.476 1.382s.164 1.453-1.43 1.453c-1.5 0-1.523-1.218-1.523-1.218h-1.969c0 1.476 1.102 2.836 3.446 2.836 3.562-.024 3.469-2.813 3.469-2.953z'}
        fill={'#D84315'}
      />
      <path
        d={'M18.727 10.828h-7.383L5.227.938h7.382l6.118 9.89z'}
        fill={'url(#prefix__paint2_linear)'}
      />
      <path
        opacity={0.2}
        // eslint-disable-next-line max-len
        d={'M12.563.938h-.844l5.742 9.14h-5.719L6.023.938h-.82l6.14 9.843h7.36L12.563.937zM15 11.953c4.523 0 8.203 3.68 8.203 8.203 0 4.524-3.68 8.203-8.203 8.203s-8.203-3.68-8.203-8.203 3.68-8.203 8.203-8.203zm0-.703a8.902 8.902 0 00-8.906 8.906A8.902 8.902 0 0015 29.063a8.902 8.902 0 008.906-8.907A8.902 8.902 0 0015 11.25z'}
        fill={'#424242'}
      />
      <defs>
        <linearGradient
          id={'prefix__paint0_linear'}
          x1={21.855}
          y1={0.674}
          x2={16.394}
          y2={8.261}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop
            offset={0.136}
            stopColor={'#1E88E5'}
          />
          <stop
            offset={0.938}
            stopColor={'#1565C0'}
          />
        </linearGradient>
        <linearGradient
          id={'prefix__paint2_linear'}
          x1={9.793}
          y1={1.189}
          x2={14.175}
          y2={10.608}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop
            offset={0.136}
            stopColor={'#64B5F6'}
          />
          <stop
            offset={0.946}
            stopColor={'#2196F3'}
          />
        </linearGradient>
        <radialGradient
          id={'prefix__paint1_radial'}
          cx={0}
          cy={0}
          r={1}
          gradientUnits={'userSpaceOnUse'}
          gradientTransform={'translate(11.145 15.712) scale(17.3002)'}
        >
          <stop
            offset={0.267}
            stopColor={'#FFA726'}
          />
          <stop
            offset={1}
            stopColor={'#FB8C00'}
          />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default ThirdPlace
