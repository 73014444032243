import { Theme } from '@material-ui/core'

import mainTheme from './main'

export type ThemeVariant = 'main'

export const themes: Record<ThemeVariant, Theme> = {
  main: mainTheme,
}

export default themes
