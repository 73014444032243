import React from 'react'
import { useSearchParam } from 'react-use'
import { decode } from 'jsonwebtoken'

import Snackbars from 'components/atoms/Snackbars'

import Loader from 'components/molecules/Loader'

import PageLayout from 'components/templates/PageLayout'

import FormRegister, { RegisterFormModel } from 'components/organisms/FormRegister'
import FormRegisterSetPassword, { FormRegisterSetPasswordModel } from 'components/organisms/FormRegisterSetPassword'

import { useAuthContext } from 'auth'
import { routeHome, routeLogin } from 'domains/main'

import { useRegisterUser, useSetPassword } from 'domains/main/api/user'

export const PageRegister = () => {
  const token = useSearchParam('token')
  const tokenPayload = token && (decode(token) as { email: string })

  const routeToHome = routeHome.useRouteTo()
  const routeToLogin = routeLogin.useRouteTo()

  const [registerUserState, registerUser] = useRegisterUser()
  const [setPasswordState, setPassword] = useSetPassword()

  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(null)
  const [snackbarErrorMessage, setSnackbarErrorMessage] = React.useState<string | null>(null)

  const {
    isLoggedIn,
  } = useAuthContext()

  React.useEffect(() => {
    if (isLoggedIn) routeToHome()
  }, [isLoggedIn, routeToHome])

  const registerHandler = React.useCallback(async (model: RegisterFormModel) => {
    try {
      await registerUser(model.email)
      setSnackbarMessage('Wysłano e-mail, sprawdź swoją skrzynkę!')
    } catch (error) {
      setSnackbarErrorMessage('Coś poszło nie tak. Taki użytkownik może istnieć już w bazie!')
    }
  }, [registerUser])

  const setPasswordHandler = React.useCallback(async (model: FormRegisterSetPasswordModel) => {
    try {
      if(!token) throw new Error('No token!')
      await setPassword(token, model.password)
      routeToLogin()
    } catch (error) {
      setSnackbarErrorMessage('Coś poszło nie tak. Link aktywacyjny może być już nieważny!')
    }
  }, [routeToLogin, setPassword, token])

  return (
    <Loader
      opacity={0.8}
      doNotUnmountChildren={true}
      loading={registerUserState.loading}
      message={'Rejestracja...'}
    >
      <Loader
        opacity={0.8}
        doNotUnmountChildren={true}
        loading={setPasswordState.loading}
        message={'Ustawianie hasła...'}
      >
        <PageLayout>
          {
            (token && tokenPayload) ?
              <FormRegisterSetPassword
                email={tokenPayload.email}
                routeToLogin={routeToLogin}
                registerHandler={setPasswordHandler}
              />
              :
              <FormRegister
                routeToLogin={routeToLogin}
                registerHandler={registerHandler}
              />
          }
        </PageLayout>
        <Snackbars
          snackbarMessage={snackbarMessage}
          snackbarErrorMessage={snackbarErrorMessage}
          onClose={() => setSnackbarMessage(null)}
          onCloseError={() => setSnackbarErrorMessage(null)}
        />
      </Loader>
    </Loader>
  )
}

export default PageRegister
