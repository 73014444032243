import React from 'react'
export interface AuthContextType<UserType extends Record<string, unknown>> {
  isLoggedIn: boolean,
  userData: UserType | null,
  logInByEmailAndPassword: (credentials: { email: string, password: string }) => Promise<UserType>,
  logOut: () => Promise<void>,
}

export const createAuthContext = <UserType extends Record<string, unknown>>() => React.createContext<AuthContextType<UserType>>({
  isLoggedIn: false,
  userData: null,
  logInByEmailAndPassword: () => Promise.reject('Probably you forgot to wrap component with `AuthContextProvider`'),
  logOut: () => Promise.resolve(),
})

export const createUseAuthContext = <UserType extends Record<string, unknown>>(
  Context: React.Context<AuthContextType<UserType>>
) => () => React.useContext(Context)
