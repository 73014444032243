import Ajv from 'ajv'
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema'

const ajv = new Ajv({ allErrors: true, useDefaults: true, $data: true })

const createValidator = (schema: Record<string, unknown>) => {
  const validator = ajv.compile(schema)

  return (model: Record<string, unknown>) => {
    validator(model)

    if (validator.errors && validator.errors.length) {
      console.error(validator.errors)
      return { details: validator.errors }
    }
  }
}

export const schemaToBridge = (schema: Record<string, any>) => {
  const schemaValidator = createValidator(schema)

  const bridge = new JSONSchemaBridge(schema, schemaValidator)

  return bridge
}

export default schemaToBridge


