import React from 'react'

import {
  makeStyles,
  Theme,
  IconButton,
  Drawer,
  DrawerProps
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

export interface SideBarProps extends DrawerProps {
  isOpen: boolean,
  contentBottom: React.ReactNode,
  contentMain: React.ReactNode,
  onClickClose: () => void,
}

const useStyles = makeStyles<Theme, SideBarProps>((theme) => ({
  drawer: {
    width: 260,
    justifyContent: 'space-between',
  },
  close: {
    maxWidth: 24,
    maxHeight: 24,
    alignSelf: 'flex-end',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  menuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}), { name: 'SideBar' } )

export const SideBar = (props: SideBarProps) => {
  const classes = useStyles(props)
  const {
    isOpen,
    contentBottom,
    contentMain,
    onClickClose,
    ...otherProps
  } = props

  return (
    <Drawer
      classes={{
        paper: classes.drawer,
      }}
      anchor={'right'}
      open={isOpen}
      onClose={onClickClose}
      {...otherProps}
    >
      <div
        className={classes.menuWrapper}
      >
        <IconButton
          color={'secondary'}
          component={'span'}
          onClick={onClickClose}
          classes={{
            root: classes.close,
          }}
        >
          <Close />
        </IconButton>
        {contentMain}
      </div>
      <div
        className={classes.bottom}
      >
        {contentBottom}
      </div>
    </Drawer>
  )
}

export default SideBar
