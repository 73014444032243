import React from 'react'

import PageLayout from 'components/templates/PageLayout'
import CoinsInsufficientMessage from 'components/organisms/CoinsInsufficientMessage'

import { routeShop } from 'domains/main'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewCoinsInsufficientProps extends React.HTMLProps<HTMLDivElement> {
}

export const ViewCoinsInsufficient = (props: ViewCoinsInsufficientProps) => {
  const routeToShop = routeShop.useRouteTo()

  return (
    <PageLayout>
      <CoinsInsufficientMessage
        onClickAddCoins={routeToShop}
      />
    </PageLayout>
  )
}

export default ViewCoinsInsufficient
