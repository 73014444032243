import React from 'react'

import { makeStyles, Theme, Typography } from '@material-ui/core'

import Card from 'components/atoms/Card'
import VideoPlayer from 'components/molecules/VideoPlayer'
import Ratio16X9 from 'components/atoms/Ratio16X9'

export interface QuizQuestionProps {
  question: string,
  pictureURL?: string | null,
  videoOrAudioURL?: string | null,
}

const useStyles = makeStyles<Theme, QuizQuestionProps>((theme) => ({
  root: {
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: 20,
    fontWeight: 400,
    color: theme.palette.common.black,
  },
  questionBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  ratioWrapper16x9: {
    position: 'relative',
    paddingTop: '56.25%',
    width: '100%',
  },
  ratioContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 4,
    marginBottom: theme.spacing(2),
  },
}), { name: 'QuizQuestion' })

export const QuizQuestion = (props: QuizQuestionProps) => {
  const classes = useStyles(props)

  const {
    question,
    pictureURL,
    videoOrAudioURL,
  } = props

  const Question = () => {
    return (
      <>
        {
          !videoOrAudioURL && !pictureURL ?
            <div
              className={classes.ratioWrapper16x9}
            >
              <div
                className={classes.ratioContainer}
              >
                <Typography
                  className={classes.text}
                  align={'center'}
                >
                  {question}
                </Typography>
              </div>
            </div>
            :
            <div
              className={classes.questionBox}
            >
              <Typography
                className={classes.text}
                align={'center'}
              >
                {question}
              </Typography>
            </div>
        }
      </>
    )
  }

  return (
    <Card
      className={classes.root}
      mode={'white'}
    >

      {
        videoOrAudioURL ?
          <VideoPlayer
            url={videoOrAudioURL || ''}
            key={videoOrAudioURL}
          />
          :
          pictureURL ?
            <Ratio16X9>
              <img
                alt={'nagroda'}
                src={pictureURL || ''}
                className={classes.img}
              />
            </Ratio16X9>
            :
            null
      }
      <Question />
    </Card>
  )
}

export default QuizQuestion
