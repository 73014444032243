import React from 'react'

import QuiziLogo from 'components/icons/QuiziLogo'

import Button from 'components/atoms/Button'
import Card from 'components/atoms/Card'

import { Loader } from 'components/molecules/Loader/Loader'
import Uniform from 'components/molecules/Uniform'

import PageLayout from 'components/templates/PageLayout'

import { Dialog, makeStyles, TextField, Theme, Typography } from '@material-ui/core'

import { useGetPaymentUrl } from 'domains/main/api/payments'
import { useRedeemPromoCode } from 'domains/main/api/payments/redeemPromoCode'
import { useCoinsContext } from 'domains/main/context/coins'
import { useAsyncFn } from 'utils/useAsyncFn'
import { routeQuizzes } from '../PageMain'
import { schema as codeSchema } from './codeSchema'


const useStyles = makeStyles<Theme>((theme) => ({
  topUpCardContent: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  codeCardHeader: {
    color: theme.palette.common.white,
    filter: 'drop-shadow(1px 1px 3px #FFFFFF)',
  },
  coinsConverterVisualizationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  quiziLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginTop: '3px',
  },
  changeCoinsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  counterButton: {
    fontSize: '16px',
    margin: '0 10px',
    paddingTop: '2px',
  },
  counterTextField: {
    width: '15%',
  },
  addCoinsButton: {
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginTop: theme.spacing(2),
    background: '#EB4D4B',
  },
  coinsTopUppedByCode: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3),
  },
  coinsState: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
}), { name: 'PageShop' })


export const PageShop = () => {
  const [coinsString, setCoinsString] = React.useState<string>('10')
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false)
  const [topUpByCodeStep, setTopUpByCodeStep] = React.useState<number>(1)
  const [coinsNumber, setCoins] = React.useState<number>(10)
  const [paymentState, getPaymentUrl] = useGetPaymentUrl()
  const [promoCodeState, redeemPromoCode] = useRedeemPromoCode()

  const routeToQuizzes = routeQuizzes.useRouteTo()

  const classes = useStyles()

  const {
    coins,
    refreshCoins,
  } = useCoinsContext()
  const [coinsState, reloadCoins] = useAsyncFn(refreshCoins)

  const handleChangeCoinsValue = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCoinsString(e.target.value)
  }, [])

  const checkCoinValue = React.useCallback(() => {
    const coinsParsed = parseInt(coinsString, 10)
    if (Number.isNaN(coinsParsed) || coinsParsed <= 1) setCoinsString('1')
  }, [coinsString])


  const handleAddCoins = React.useCallback(() => {
    const coinsParsed = parseInt(coinsString, 10)
    if (Number.isNaN(coinsParsed) || coinsParsed < 1) setCoinsString('1')
    else {
      const coinsParsedPlusOne = coinsParsed + 1
      setCoinsString(coinsParsedPlusOne.toString())
    }
  }, [coinsString])

  const handleMinusCoins = React.useCallback(() => {
    const coinsParsed = parseInt(coinsString, 10)
    if (Number.isNaN(coinsParsed) || coinsParsed <= 1) setCoinsString('1')
    else {
      const coinsParsedMinusOne = coinsParsed - 1
      setCoinsString(coinsParsedMinusOne.toString())
    }
  }, [coinsString])

  const handleTopUpByCode = React.useCallback(async (data) => {
    try {
      await redeemPromoCode(data.code)
      await reloadCoins()
      setTopUpByCodeStep(2)
      // eslint-disable-next-line no-empty
    } catch { }
  }, [redeemPromoCode, reloadCoins])

  const handleTopUpByPay = React.useCallback(async () => {
    const response = await getPaymentUrl(coinsNumber)
    window.location.href = response.redirectURL
  }, [getPaymentUrl, coinsNumber])


  React.useEffect(() => {
    const coinsParsed = parseInt(coinsString, 10)
    if (Number.isNaN(coinsParsed)) {
      setCoins(1)
    } else {
      setCoins(coinsParsed)
    }
  }, [coinsString])


  return (
    <>
      <Loader
        loading={paymentState.loading}
        error={paymentState.error}
        retry={() => getPaymentUrl(coinsNumber)}
        message={'Ładowanie płątności...'}
      >
        <Loader
          loading={promoCodeState.loading}
          error={promoCodeState.error}
          cancelable={true}
          doNotUnmountChildren={true}
          opacity={0.95}
          message={'Sprawdzanie kodu rabatowego...'}
        >
          <Loader
            loading={coinsState.loading}
            error={coinsState.error}
            retry={reloadCoins}
            doNotUnmountChildren={true}
            opacity={0.95}
            message={'Sprawdzanie ilości tokenów...'}
          >
            <PageLayout>
              <Card>
                <div className={classes.topUpCardContent}>
                  <Typography
                    variant={'h1'}
                    align={'center'}
                    className={classes.shadow}
                  >
                    Doładowanie
                  </Typography>
                  <Typography
                    variant={'h4'}
                    align={'center'}
                    color={'primary'}
                  >
                    Zwiększ swoje szanse na wygranie fantasytycznych nagród
                  </Typography>
                  <div className={classes.coinsConverterVisualizationWrapper}>
                    <Typography
                      variant={'h2'}
                      align={'center'}
                    >
                      1 zł  → 1
                    </Typography>
                    <div className={classes.quiziLogoContainer}>
                      <QuiziLogo />
                    </div>
                  </div>
                  <div className={classes.changeCoinsContainer}>
                    <Button
                      label={'-'}
                      onClick={handleMinusCoins}
                      className={classes.counterButton}
                    />
                    <TextField
                      value={coinsString}
                      type={'number'}
                      onChange={(e) => handleChangeCoinsValue(e)}
                      onBlur={() => checkCoinValue()}
                      className={classes.counterTextField}
                    />
                    <Button
                      label={'+'}
                      onClick={handleAddCoins}
                      className={classes.counterButton}

                    />
                  </div>
                  <Button
                    className={classes.addCoinsButton}
                    label={'Dodaj tokeny'}
                    onClick={handleTopUpByPay}
                    fullWidth
                    color={'secondary'}
                  />
                </div>

              </Card>
              <Card
                mode={'darkBlue'}
              >
                <Typography
                  variant={'h2'}
                  align={'center'}
                  color={'primary'}
                  className={classes.codeCardHeader}
                >
                  Masz kod na tokeny?
                </Typography>
                <Button
                  className={classes.addCoinsButton}
                  label={'Zrealizuj go tutaj!'}
                  onClick={() => setIsDialogOpen(true)}
                  fullWidth
                />
                <Dialog open={isDialogOpen}>
                  <Card
                    mode={'white'}
                    className={classes.card}
                  >
                    {topUpByCodeStep === 1 &&
                      <>
                        <Typography
                          variant={'h2'}
                          align={'center'}
                        >
                          Masz kod na tokeny?
                        </Typography>
                        <Uniform
                          schema={codeSchema}
                          onSubmit={handleTopUpByCode}
                          submitField={() => (
                            <Button
                              label={'Doładuj'}
                              fullWidth
                              type={'submit'}
                              color={'secondary'}
                            />
                          )}
                        />
                        <Button
                          label={'Anuluj'}
                          className={classes.cancelButton}
                          onClick={() => setIsDialogOpen(false)}
                          fullWidth
                          color={'primary'}
                        />
                      </>}
                    {topUpByCodeStep === 2 &&
                      <>
                        <Typography
                          variant={'h2'}
                          align={'center'}
                        >
                          Gratulacje
                        </Typography>
                        <div className={classes.coinsTopUppedByCode}>
                          <Typography
                            variant={'h5'}
                            align={'center'}
                          >

                            Stan tokenów
                          </Typography>
                          <Typography
                            variant={'h2'}
                            align={'center'}
                            className={classes.coinsState}
                          >
                            {coins}
                          </Typography>
                          <QuiziLogo />

                        </div>
                        <Typography
                          variant={'h2'}
                          align={'center'}
                          color={'primary'}
                        >
                          Powodzenia :)
                        </Typography>
                        <Button
                          label={'Konkursy'}
                          onClick={() => routeToQuizzes('running')}
                          fullWidth
                          color={'secondary'}
                        />

                        <Button
                          label={'Wyjdź'}
                          className={classes.cancelButton}
                          onClick={() => { setIsDialogOpen(false); setTopUpByCodeStep(1) }}
                          fullWidth
                          color={'primary'}
                        />
                      </>}
                  </Card>

                </Dialog>
              </Card>
            </PageLayout>
          </Loader>
        </Loader>
      </Loader>
    </>
  )
}

export default PageShop
