import { UserInfo } from '@directus/sdk-js/dist/types/handlers/users'
import { AxiosError } from 'axios'
import { UserAddress } from 'domains/main/api/user'

import { directus } from './directus'

export type User = UserInfo & UserAddress

export const logInByEmailAndPassword = async ({ password, email }: { password: string, email: string }): Promise<Partial<User>> => {
  await directus.auth.login({ email, password })

  const user = await directus.users.me.read()

  return user.data
}

export const checkLogin = async (): Promise<Partial<UserInfo>> => {
  try {
    await directus.auth.refresh(true)
  } catch (error: unknown) {
    if(!error || !(typeof error === 'object') || !(error as { isAxiosError?: boolean })?.isAxiosError) throw error

    const axiosError = error as AxiosError
    const status = axiosError?.response?.status

    if(status === 401) logOut()

    throw error
  }

  if (!directus.auth.token) throw new Error('User is not already logged in!')

  const user = await directus.users.me.read()

  return user.data
}

export const logOut = () => {
  return directus.auth.logout()
}

const loginByThirdPartyProvider = (providerName: string) => {
  window.location.href = (
    window._env_.REACT_APP_API_URL +
    '/custom/oauth/' +
    providerName +
    '?redirect=' +
    window._env_.REACT_APP_URL
  )
}

export const logInByGoogle = () => {
  loginByThirdPartyProvider('google')
}

export const logInByFacebook = () => {
  loginByThirdPartyProvider('facebook')
}

export interface HandleOauthCallbackParams {
  accessToken: string,
  expiresAt: string,
  refreshToken: string,
}

export const saveTokens = async (params: HandleOauthCallbackParams) => {
  const {
    accessToken,
    expiresAt,
    refreshToken,
  } = params

  await Promise.all([
    // @ts-ignore
    directus.auth.storage.setItem('directus_access_token', accessToken),
    // @ts-ignore
    directus.auth.storage.setItem('directus_access_token_expires', expiresAt),
    // @ts-ignore
    directus.auth.storage.setItem('directus_refresh_token', refreshToken),
  ])

  return
}
