export const schema = {
  title: 'Register',
  type: 'object',
  required: ['email'],
  properties: {
    email: {
      type: 'string',
      format: 'email',
      label: 'E-mail',
      errorMessage: 'Podaj poprawny adres e-mail',
      minLength: 1,
      uniforms: {
        type: 'email',
        variant: 'outlined',
      },
    },
  },
}
