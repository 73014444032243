import React from 'react'

import Loader from 'components/molecules/Loader'

import PageAddQuestionView from 'domains/main/views/PageAddQuestionView'
import ViewSuggestingIsNotActive from 'domains/main/views/ViewSuggestingIsNotActive'

import { useGetWeeklySuggestions } from 'domains/main/api/weeklySuggestions/getWeeklySuggestions'
import { useFindAllCategories } from 'domains/main/api/category'
import { makeImgSrc } from 'domains/main/api/media'

export const PageAddQuestion = () => {
  const [weeklySuggestionsState, getWeeklySuggestions] = useGetWeeklySuggestions()
  const [categoriesState, findAllCategories] = useFindAllCategories()

  React.useEffect(() => {
    getWeeklySuggestions()
    findAllCategories()
  // only for first mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const weeklySuggestions = weeklySuggestionsState.value

  return (
    <Loader
      loading={weeklySuggestionsState.loading}
      error={weeklySuggestionsState.error}
      retry={getWeeklySuggestions}
    >
      <Loader
        loading={categoriesState.loading}
        error={categoriesState.error}
        retry={findAllCategories}
      >
        {
          (weeklySuggestions && categoriesState.value) ?
            weeklySuggestions.active ?
              <PageAddQuestionView
                categories={categoriesState.value}
                titleCardProps={{
                  title: 'Zaproponuj nam swoje pytanie!',
                  subheader: 'Wygraj wspaniałe nagrody za swoją kreatywność!',
                }}
                infoListCardProps={{
                  title: 'Cotygodniowy konkurs dla autorów pytań!',
                  subheader: 'Jak wziąć udział:',
                  itemsList: weeklySuggestions.description ? weeklySuggestions.description.split('\n').map((line) => <>{line}<br /></>) : [],
                  titleTypographyProps: {
                    variant: 'h5',
                    color: 'textPrimary',
                    align: 'left',
                  },
                  subheaderTypographyProps: {
                    variant: 'body1',
                    color: 'textPrimary',
                    align: 'left',
                  },
                }}
                mediaCardProps={{
                  img: weeklySuggestions.prize?.media && makeImgSrc(weeklySuggestions?.prize?.media),
                  reward: true,
                  title: weeklySuggestions.prize.name,
                  desc: weeklySuggestions.prize.description,
                }}
                timerCardProps={{
                  time: weeklySuggestions.timeRemaining,
                  title: 'Weź udział juź dziś!',
                  text: 'Obecny konkurs kończy się za:',
                  label: 'Zaproponuj swoje pytanie!',
                }}
                topListCardProps={{
                  title: 'Najaktywniejsi autorzy pytań!',
                  subheader: 'Na prowadzeniu znajdują się:',
                  leaderBoardProps: {
                    displayScore: true,
                    leaders: weeklySuggestions?.ranking?.map(
                      (leader) => ({ ...leader, avatar: leader.avatar && makeImgSrc(leader.avatar, { width: 40, height: 40 }) })
                    ) || [],
                  },
                  titleTypographyProps: {
                    variant: 'h5',
                    color: 'textPrimary',
                    align: 'left',
                  },
                  subheaderTypographyProps: {
                    variant: 'body1',
                    color: 'textPrimary',
                    align: 'left',
                  },
                }}
              />
              :
              <ViewSuggestingIsNotActive />
            :
            null
        }
      </Loader>
    </Loader>
  )
}

export default PageAddQuestion
