import React from 'react'

import { makeStyles, Theme, Typography } from '@material-ui/core'

import Card from 'components/atoms/Card'

export type SuggestingIsNotActiveMessageProps = React.HTMLProps<HTMLDivElement>

const useStyles = makeStyles<Theme, SuggestingIsNotActiveMessageProps>((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  text: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
}), { name: 'SuggestingIsNotActiveMessage' } )

export const SuggestingIsNotActiveMessage = (props: SuggestingIsNotActiveMessageProps) => {
  const classes = useStyles(props)
  const {
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Card
        mode={'darkBlue'}
      >
        <Typography
          variant={'h3'}
          align={'center'}
          className={classes.text}
        >
          Sugerowanie pytań nie jest w tym momencie dostępne!
        </Typography>
        <Typography
          variant={'body2'}
          align={'center'}
          className={classes.text}
        >
          Sprawdź za jakiś czas!
        </Typography>
      </Card>
    </div>
  )
}

export default SuggestingIsNotActiveMessage
