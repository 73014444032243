import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { RouterContextProvider } from './RouterContext'

import { Routes, IRouteEnhanced } from './types'

export interface RouterProps<FilterParams = Record<string, unknown>> {
  routes: Routes,
  filterParams?: FilterParams,
  name?: string,
}

export const Router = <FilterParams extends Record<string, unknown>>(props: RouterProps<FilterParams>) => {
  const {
    routes,
    filterParams,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    name,
  } = props
  const { url, path = '' }  = useRouteMatch()

  const filteredRoutes =  React.useMemo(() => {
    return routes && routes.filter((route, i, arr) => {
      const isLast = i === arr.length - 1
      if(isLast) return true

      const routeEnhanced = route as IRouteEnhanced

      return routeEnhanced.filter ? routeEnhanced.filter(filterParams || {}) : true
    })
  }, [filterParams, routes])

  const renderedRoutes = React.useMemo(() => {
    return filteredRoutes && filteredRoutes.map((route, i, arr) => {
      const isLast = i === arr.length - 1
      if(isLast) return (
        <Route
          key={'404'}
          component={route.component}
        />
      )

      const routeEnhanced = route as IRouteEnhanced
      const Component = route.component

      const routeFullPath = (path === '/' ? '' : path) + routeEnhanced?.path

      return(
        <Route
          key={`${i}-${routeFullPath}`}
          exact={routeEnhanced?.exact || false}
          path={routeFullPath}
          component={Component}
        />
      )
    })
  }, [filteredRoutes, path])

  return (
    <RouterContextProvider
      value={{
        baseUrl: url,
      }}
    >
      <Switch>
        {renderedRoutes}
      </Switch>
    </RouterContextProvider>
  )
}

export default Router
