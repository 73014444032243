/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-types */

export const schema = {
  title: 'UserName',
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
      label: 'Imię',
      errorMessage: 'Wpisz poprawne imię',
      uniforms: {
        variant: 'outlined',
      },
    },
    lastName: {
      type: 'string',
      minLength: 1,
      label: 'Nazwisko',
      errorMessage: 'Wpisz poprawne nazwisko',
      uniforms: {
        variant: 'outlined',
      },
    },
  },
  required: ['firstName', 'lastName'],
}
