import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { convertCoinTransactions } from './converters'

import { CoinTransactionResult, CoinTransactionResultRaw } from './types'

export const findAllCoinTransactions = async (): Promise<CoinTransactionResult> => {
  const response = await fetchCustomEndpoint<CoinTransactionResultRaw>({
    url: '/coin_transactions',
  })

  const data: CoinTransactionResultRaw = response.data

  return {
    ...data,
    coinTransactions: convertCoinTransactions(data.coinTransactions),
  }
}

export const useFindAllCoinTransactions = () => {
  return useAsyncFn(findAllCoinTransactions)
}
