import React from 'react'

import { Grid, Typography, makeStyles, Theme } from '@material-ui/core'

import PageLayout from 'components/templates/PageLayout'
import Card from 'components/atoms/Card'
import Button from 'components/atoms/Button'
import FormContact, { FormContactModel } from 'components/organisms/FormContact/FormContact'

export interface ContactCardProps {
  title: string,
  subtitle: string,
  contactFormSchema: Record<string, any>,
  onFormSubmit: (formValues: FormContactModel) => void,
  onFbButtonClick: () => void,
}

interface SponsorCardProps {
  title: string,
  subtitle: string,
  route: () => void,
}

export interface PageContactViewProps extends React.HTMLProps<HTMLDivElement> {
  contactCardProps: ContactCardProps,
  sponsorCardProps: SponsorCardProps,
}

const useStyles = makeStyles<Theme, PageContactViewProps>((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    width: '90%',
  },
  whiteText: {
    color: '#FFF',
  },
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #FFF)',
  },
}), { name: 'PageContactView' })

export const PageContactView = (props: PageContactViewProps) => {
  const classes = useStyles(props)
  const {
    contactCardProps,
    sponsorCardProps,
  } = props

  const {
    title,
    subtitle,
    route,
  } = sponsorCardProps

  return (
    <PageLayout>
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <FormContact
            {...contactCardProps}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Card
            mode={'darkBlue'}
          >
            <Typography
              variant={'h3'}
              align={'left'}
              className={`${classes.whiteText} ${classes.shadow}`}
              gutterBottom={false}
            >
              {title}
            </Typography>
            <Typography
              variant={'h5'}
              align={'left'}
              className={classes.whiteText}
              gutterBottom={true}
            >
              {subtitle}
            </Typography>
            <Button
              fullWidth={true}
              label={'Współpraca'}
              onClick={route}
            />
          </Card>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default PageContactView
