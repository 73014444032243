import React from 'react'

import { makeStyles, Theme, AppBar as MuiAppBar } from '@material-ui/core'

export interface AppBarProps extends React.HTMLProps<HTMLDivElement> {
  contentLogo: React.ReactNode,
  contentActions: React.ReactNode,
}

const useStyles = makeStyles<Theme, AppBarProps>((theme) => ({
  appBar: {
    height: 86,
    [theme.breakpoints.down('xs')]: {
      height: 62,
    },
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  barActions: {
    display: 'flex',
    padding: theme.spacing(1),
  },
}), { name: 'AppBar' } )

export const AppBar = (props: AppBarProps) => {
  const classes = useStyles(props)
  const {
    contentLogo,
    contentActions,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <MuiAppBar
        className={classes.appBar}
      >
        {contentLogo}
        <div
          className={classes.barActions}
        >
          {contentActions}
        </div>
      </MuiAppBar>
    </div>
  )
}

export default AppBar
