import { useAsyncFn } from 'utils/useAsyncFn'

import { directus } from 'api'

import { UserName } from './types'

export const updateUserName = async (data: UserName) => {

  await directus.users.me.update(data)
  // @TODO
}

export const useUpdateUserName = () => {
  return useAsyncFn(updateUserName)
}
