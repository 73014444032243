import React from 'react'

import Snackbars from 'components/atoms/Snackbars'

import Loader from 'components/molecules/Loader'

import { FormContactModel } from 'components/organisms/FormContact'

import PageContactView from 'domains/main/views/PageContactView'

import { routeBecomeSponsor } from 'domains/main'
import { useContact } from 'domains/main/api/app'

import { schema } from './contactSchema'

export const PageContact = () => {
  const routeToBecomeSponsor = routeBecomeSponsor.useRouteTo()

  const [useContactState, contact] = useContact()
  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(null)

  const onFormSubmit = React.useCallback(async (data: FormContactModel) => {
    await contact(data)
    setSnackbarMessage('Wysłano wiadomość! Dziękujemy za kontakt!')
  }, [contact])

  return (
    <Loader
      doNotUnmountChildren={true}
      opacity={0.8}
      loading={useContactState.loading}
      error={useContactState.error}
      cancelable={true}
    >
      <PageContactView
        contactCardProps={{
          title: 'Masz pytania?',
          subtitle: 'Skorzystaj z formularza  kontaktowego lub',
          contactFormSchema: schema,
          onFormSubmit,
          onFbButtonClick: () => window.open(window._env_.REACT_APP_FACEBOOK_URL),
        }}
        sponsorCardProps={{
          title: 'Chcesz zostać patronem konkursu?',
          subtitle: 'Porozmawiajmy o współpracy!',
          route: () => routeToBecomeSponsor(),
        }}
      />
      <Snackbars
        snackbarMessage={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
      />
    </Loader>
  )
}

export default PageContact
