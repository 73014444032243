import React from 'react'
import { useHistory } from 'react-router'

import { useRouterContext } from './RouterContext'
import { IRoute } from './types'

export type UseRouteTo = (...params: any[]) => void

export const useRouteTo = <FilterParams = Record<string, unknown>>(route: IRoute<FilterParams>): UseRouteTo => {
  const history = useHistory()
  const { baseUrl } = useRouterContext()

  const makePath = route.makePath as (...params: any[] | never) => string

  const routeTo = React.useCallback((...params: Parameters<typeof makePath>): void => {
    if(!makePath || typeof makePath !== 'function') {
      console.warn('This route do not have a `makePath` method!')
    }
    const wholePath = (baseUrl + makePath(...params)).replaceAll('//', '/')
    history.push(wholePath)
  }, [baseUrl, history, makePath])

  return routeTo
}

export const makeUseRouteTo = <FilterParams = Record<string, unknown>>(route: IRoute<FilterParams>): IRoute<FilterParams> & { useRouteTo: UseRouteTo  } => {
  return {
    ...route,
    useRouteTo: () => useRouteTo(route),
  }
}
