import * as React from 'react'

function SecondPlace(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={'30px'}
      height={'30px'}
      viewBox={'0 0 30 30'}
      fill={'none'}
      {...props}
    >
      <path
        d={'M24.844.938l-3.047 4.898-3.07 4.992h-7.383l.023-.047 2.93-4.71L17.484.937h7.36z'}
        fill={'url(#prefix__paint0_linear)'}
      />
      <path
        opacity={0.2}
        d={'M24 .938l-5.672 9.14H12.61l5.72-9.14h-.821L14.297 6.07l-2.953 4.711h7.36l3.093-4.945 3.07-4.899H24z'}
        fill={'#424242'}
      />
      <path
        // eslint-disable-next-line max-len
        d={'M19.219 7.969H10.78a1.88 1.88 0 00-1.875 1.875v3.75a.94.94 0 00.938.937c.515 0 1.172-.422 1.172-.937V11.25a.47.47 0 01.468-.469h7.032a.47.47 0 01.468.469v2.32c0 .47.54.867.985.938.07 0 .117.023.187.023a.94.94 0 00.938-.937v-3.75a1.88 1.88 0 00-1.875-1.875z'}
        fill={'#BDBDBD'}
      />
      <path
        opacity={0.2}
        // eslint-disable-next-line max-len
        d={'M19.219 8.438a1.41 1.41 0 011.406 1.406v3.75a.47.47 0 01-.469.469h-.093c-.282-.047-.61-.305-.61-.493v-2.32a.94.94 0 00-.937-.938h-7.032a.94.94 0 00-.937.938v2.344c0 .21-.375.469-.703.469a.47.47 0 01-.469-.47v-3.75a1.41 1.41 0 011.406-1.405h8.438zm0-.47H10.78a1.88 1.88 0 00-1.875 1.876v3.75a.94.94 0 00.938.937c.515 0 1.172-.422 1.172-.937V11.25a.47.47 0 01.468-.469h7.032a.47.47 0 01.468.469v2.32c0 .47.54.867.985.938.07 0 .117.023.187.023a.94.94 0 00.938-.937v-3.75a1.88 1.88 0 00-1.875-1.875z'}
        fill={'#424242'}
      />
      <path
        d={'M18.727 10.828h-7.383L8.414 6.07 5.227.937h7.382l3.329 5.391 2.765 4.453.024.047z'}
        fill={'url(#prefix__paint1_linear)'}
      />
      <path
        opacity={0.2}
        d={'M15.938 6.328L12.562.938h-.843l5.742 9.14h-5.719L6.023.938h-.82L8.414 6.07l2.93 4.711h7.36l-2.767-4.453z'}
        fill={'#424242'}
      />
      <path
        d={'M15 29.063a8.906 8.906 0 100-17.813 8.906 8.906 0 000 17.813z'}
        fill={'url(#prefix__paint2_radial)'}
      />
      <path
        opacity={0.2}
        // eslint-disable-next-line max-len
        d={'M15 11.953c4.523 0 8.203 3.68 8.203 8.203 0 4.524-3.68 8.203-8.203 8.203s-8.203-3.68-8.203-8.203 3.68-8.203 8.203-8.203zm0-.703a8.902 8.902 0 00-8.906 8.906A8.902 8.902 0 0015 29.063a8.902 8.902 0 008.906-8.907A8.902 8.902 0 0015 11.25z'}
        fill={'#424242'}
      />
      <path
        // eslint-disable-next-line max-len
        d={'M11.86 18.304s-.024-3.094 3.304-3.094 3 2.883 3 2.883c.023 1.735-2.508 3.703-3.68 5.11h4.032v1.547H12.07v-1.313c1.055-1.172 4.22-4.148 4.22-5.11 0 0 .257-1.593-1.173-1.593-1.43 0-1.36 1.57-1.36 1.57H11.86z'}
        fill={'#757575'}
      />
      <defs>
        <linearGradient
          id={'prefix__paint0_linear'}
          x1={21.006}
          y1={0.667}
          x2={16.95}
          y2={7.928}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop
            offset={0.136}
            stopColor={'#1E88E5'}
          />
          <stop
            offset={0.938}
            stopColor={'#1565C0'}
          />
        </linearGradient>
        <linearGradient
          id={'prefix__paint1_linear'}
          x1={9.475}
          y1={1.211}
          x2={14.643}
          y2={10.859}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop
            offset={0.136}
            stopColor={'#64B5F6'}
          />
          <stop
            offset={0.946}
            stopColor={'#2196F3'}
          />
        </linearGradient>
        <radialGradient
          id={'prefix__paint2_radial'}
          cx={0}
          cy={0}
          r={1}
          gradientUnits={'userSpaceOnUse'}
          gradientTransform={'translate(10.078 14.824) scale(16.2872)'}
        >
          <stop
            offset={0.283}
            stopColor={'#EEE'}
          />
          <stop
            offset={1}
            stopColor={'#BDBDBD'}
          />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default SecondPlace
