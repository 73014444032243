import React from 'react'

import { makeStyles, Theme, Typography, List } from '@material-ui/core'

import LeaderItem from 'components/atoms/LeaderItem'

export interface LeaderBoardProps extends React.HTMLProps<HTMLDivElement> {
  leaders: {
    avatar: string | null,
    email: string,
    score: number,
  }[],
  displayScore?: boolean,
}

const useStyles = makeStyles<Theme, LeaderBoardProps>((theme) => ({
  root: {},
}), { name: 'LeaderBoard' })

export const LeaderBoard = (props: LeaderBoardProps) => {
  const classes = useStyles(props)
  const {
    leaders,
    displayScore = true,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Typography
        align={'center'}
        variant={'subtitle2'}
      >
        Obecnie prowadzą
      </Typography>
      <List>
        {
          leaders.slice(0, 3).map(({ email, score, avatar }, index) => {
            return (
              <LeaderItem
                key={email}
                avatar={avatar}
                email={email}
                score={displayScore ? score : undefined}
                index={index as 0 | 1 | 2}
              />
            )
          })
        }
      </List>
    </div>
  )
}

export default LeaderBoard
