import React from 'react'

import { Button } from '@material-ui/core'
import Snackbars from 'components/atoms/Snackbars'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

export interface ServiceWorkerState {
  waitingWorker: ServiceWorkerRegistration['waiting'],
  newVersionAvailable: boolean,
}

const ServiceWorkerUpdateSnackbar = () => {
  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(null)
  const [serviceWorkerState, setServiceWorkerState] = React.useState<ServiceWorkerState>({
    waitingWorker: null,
    newVersionAvailable: false,
  })

  const onServiceWorkerUpdate = React.useCallback((registration: ServiceWorkerRegistration) => {
    setSnackbarMessage('Dostępna jest nowa wersja!')
    setServiceWorkerState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    })
  }, [])

  const updateServiceWorker = React.useCallback(() => {
    const { waitingWorker } = serviceWorkerState
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' })
    setServiceWorkerState({
      ...serviceWorkerState,
      newVersionAvailable: false,
    })
    window.location.reload()
  }, [serviceWorkerState])

  React.useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      url: window._env_.REACT_APP_URL,
      onUpdate: onServiceWorkerUpdate,
    })
  // only on first mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Snackbars
        snackbarMessage={snackbarMessage}
        lower={true}
        autoHideDuration={null}
        alertProps={{
          severity: 'success',
          action: (
            <Button
              color={'inherit'}
              size={'small'}
              variant={'text'}
              onClick={updateServiceWorker}
            >
              Odśwież
            </Button>
          ),
        }}
      />
    </>
  )
}

export default ServiceWorkerUpdateSnackbar
