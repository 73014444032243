import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { SetReminderData, SetReminderResponse } from './types'

export const setReminder = async (data: SetReminderData): Promise<SetReminderResponse> => {
  const response = await fetchCustomEndpoint<SetReminderResponse>({
    url: '/reminders',
    method: 'POST',
    data,
  })

  const result: SetReminderResponse = response.data

  return result
}

export const useSetReminder = () => {
  return useAsyncFn(setReminder)
}
