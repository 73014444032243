import React from 'react'
import { useParams } from 'react-router'

import { Dialog, makeStyles, Theme, Typography } from '@material-ui/core'

import Snackbars from 'components/atoms/Snackbars'
import Button from 'components/atoms/Button'
import Card from 'components/atoms/Card'
import Ratio16X9 from 'components/atoms/Ratio16X9'

import LeaderBoard from 'components/molecules/LeaderBoard'
import Loader from 'components/molecules/Loader'
import Uniform from 'components/molecules/Uniform'

import PageLayout from 'components/templates/PageLayout'

import ViewHeaderQuizDetails from 'domains/main/views/ViewHeaderQuizDetails'

import { useCoinsContext } from 'domains/main/context/coins'

import { useUnlock } from 'domains/main/api/quizzes/unlock'
import { useSetReminder } from 'domains/main/api/reminders'
import { makeImgSrc } from 'domains/main/api/media'
import { useGetQuiz, useGetQuizRanking } from 'domains/main/api/quizzes'

import { useAuthContext } from 'auth'
import { routeLogin, routeQuizQuestion, routeShop } from 'domains/main'

import { mailSchema as reminderSchemaMail } from './reminderSchema'
import { phoneSchema as reminderSchemaPhone } from './reminderSchema'
import { schema as unlockSchema } from './unlockSchema'

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  loader: {
    minHeight: 0,
  },
  dialogCard: {
    width: '100%',
    marginBottom: 0,
  },
  chip: {
    background: '#fff',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(350)]: {
      marginRight: theme.spacing(0),
    },
  },
  radiusButton: {
    minWidth: 135,
    borderRadius: '16px',
    maxHeight: '32px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(350)]: {
      marginRight: theme.spacing(0),
    },
  },
  prizeImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',

  },
  sponsorWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  sponsorImg: {
    maxWidth: '80%',
    margin: 'auto',
  },
  sponsorButton: {
    marginTop: theme.spacing(2),
  },
  quizHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(350)]: {
      display: 'block',
    },
  },
  quizHeaderInfo: {
    [theme.breakpoints.down(350)]: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
    },
  },
  quizHeaderCta: {
    display: 'flex',
    [theme.breakpoints.down(350)]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  prizeDetailsWrapper: {
    margin: theme.spacing(2),
  },
  unlockReturnBtn: {
    background: theme.palette.error.light,
    marginTop: theme.spacing(2),
  },
  buttonSendSMS: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}), { name: 'PageQuiz' })

export const PageQuiz = () => {
  const { quizUuid } = useParams<{ quizUuid: string }>()
  const classes = useStyles()

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false)
  const [dialogType, setDialogType] = React.useState<string>('regulations')
  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(null)
  const [snackbarErrorMessage, setSnackbarErrorMessage] = React.useState<string | null>(null)

  const [quizState, getQuiz] = useGetQuiz()
  const [quizRankingState, getQuizRanking] = useGetQuizRanking()
  const [unlockState, unlock] = useUnlock()
  const [setReminderState, setReminder] = useSetReminder()

  const routeToLogin = routeLogin.useRouteTo()
  const routeToQuizQuestion = routeQuizQuestion.useRouteTo()
  const routeToShop = routeShop.useRouteTo()

  const {
    isLoggedIn,
    userData,
  } = useAuthContext()

  const {
    coins,
  } = useCoinsContext()

  React.useEffect(() => {
    getQuiz(quizUuid)
    getQuizRanking(quizUuid)
    // only at first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const details = quizState.value
  const ranking = quizRankingState.value?.map((leader) => ({ ...leader, avatar: leader.avatar && makeImgSrc(leader.avatar, { width: 40, height: 40 }) }))

  const quizStatus = details?.quizStatus

  const openDialog = React.useCallback((dialogTypeValue: string) => {
    setIsDialogOpen(true)
    setDialogType(dialogTypeValue)
    if (dialogTypeValue === 'save') {
      setReminder({ quizId: quizUuid })
    }
  }, [quizUuid, setReminder])

  const handleClickSendNotification = React.useCallback(async (data) => {
    await setReminder({ quizId: quizUuid, phone: data.phone })
    setIsDialogOpen(false)
  }, [quizUuid, setReminder])

  const submitCode = React.useCallback(async (data: { code: string }) => {
    try {
      await unlock(quizUuid, data.code)
    } catch (error) {
      setSnackbarErrorMessage('Nie udało się odblokować quizu')
      return
    }
    setIsDialogOpen(false)
    await getQuiz(quizUuid)
    setSnackbarMessage('Odblokowano quiz!')
  }, [getQuiz, quizUuid, unlock])

  return (
    <>
      <Loader
        loading={quizState.loading}
        error={quizState.error}
        retry={() => getQuiz(quizUuid)}
        message={'Ładowanie quizów...'}
      >
        <Loader
          loading={quizRankingState.loading}
          error={quizRankingState.error}
          retry={() => getQuizRanking(quizUuid)}
          message={'Ładowanie quizów...'}
        >
          <Loader
            opacity={0.8}
            doNotUnmountChildren={true}
            loading={setReminderState.loading}
            error={setReminderState.error}
            message={'Wysyłanie przypomnienia...'}
            cancelable={true}
          >
            {details ?
              <PageLayout>
                <ViewHeaderQuizDetails
                  ranking={ranking}
                  classes={classes}
                  details={details}
                  openDialog={openDialog}
                  isLoggedIn={isLoggedIn}
                  coins={coins}
                />
                <Card
                  mode={'white'}
                  className={classes.card}
                >
                  <Typography
                    variant={'subtitle2'}
                    align={'left'}
                  >
                    {details.categories && details.categories.length === 1 ? details.categories[0].name : 'Wiedza ogólna'}
                  </Typography>
                  <Typography
                    variant={'h2'}
                    align={'left'}
                  >
                    {details.name}
                  </Typography>
                </Card>
                {details.prize ?
                  <Card
                    className={classes.card}
                  >
                    <>
                      <Ratio16X9>
                        <img
                          alt={'nagroda'}
                          src={details.prize && details.prize.media ? makeImgSrc(details.prize.media) : undefined}
                          className={classes.prizeImg}
                        />
                      </Ratio16X9>
                      <div className={classes.prizeDetailsWrapper}>
                        <Typography
                          variant={'h4'}
                          align={'left'}
                          gutterBottom={true}
                        >
                          {details.prize && details.prize.name}
                        </Typography>
                        <Typography>
                          {details.prize && details.prize.description}
                        </Typography>
                        {
                          !isLoggedIn &&
                          <Button
                            label={'Zaloguj się'}
                            fullWidth={true}
                            className={classes.sponsorButton}
                            onClick={() => routeToLogin()}
                            color={'primary'}
                          />
                        }
                        {
                          (
                            (!details.isLocked && quizStatus === 'running' && isLoggedIn && details.questionPrice === 0) ||
                            (!details.isLocked && quizStatus === 'running' && coins && isLoggedIn && coins >= details.questionPrice)
                          ) ?
                            <Button
                              fullWidth={true}
                              className={classes.sponsorButton}
                              label={'Wygraj'}
                              onClick={() => routeToQuizQuestion(details.id)}
                              color={'primary'}
                            />
                            :
                            null
                        }
                        {
                          (
                            (!details.isLocked && quizStatus === 'running' && coins === 0 && isLoggedIn && details.questionPrice !== 0) ||
                            (!details.isLocked && quizStatus === 'running' && coins && isLoggedIn && coins < details.questionPrice)
                          ) ?
                            <Button
                              fullWidth={true}
                              className={classes.sponsorButton}
                              label={'Doładuj'}
                              onClick={() => routeToShop()}
                              color={'primary'}
                            />
                            :
                            null
                        }
                        {
                          quizStatus === 'upcoming' && isLoggedIn &&
                          <Button
                            fullWidth={true}
                            className={classes.sponsorButton}
                            label={'PRZYPOMNIJ'}
                            onClick={() => openDialog('save')}
                            color={'primary'}
                          />
                        }
                        {
                          details.isLocked && quizStatus === 'running' && isLoggedIn &&
                          <Button
                            fullWidth={true}
                            className={classes.sponsorButton}
                            label={'Odblokuj'}
                            onClick={() => openDialog('unlock')}
                            color={'primary'}
                          />
                        }
                      </div>
                    </>
                  </Card> : null}
                {details.sponsor ?
                  <Card
                    mode={'white'}
                    className={classes.card}
                  >
                    <div className={classes.sponsorWrapper}>
                      <img
                        alt={'sponsor'}
                        src={details.sponsor && details.sponsor.media ? makeImgSrc(details.sponsor.media) : undefined}
                        className={classes.sponsorImg}
                      >
                      </img>
                      <div>
                        <Typography
                          variant={'h4'}
                          align={'left'}
                          gutterBottom={true}
                        >
                          {details.sponsor && details.sponsor.name}
                        </Typography>
                        <Typography>
                          {details.sponsor && details.sponsor.description}
                        </Typography>
                      </div>
                    </div>
                  </Card> : null}
                {
                  ranking && details.quizStatus === 'running' ?
                    <Card
                      mode={'white'}
                      className={classes.card}
                    >
                      <LeaderBoard
                        className={classes.card}
                        leaders={ranking}
                      />
                    </Card>
                    :
                    null
                }
                <Card
                  mode={'white'}
                  className={classes.card}
                >
                  <Typography>
                    Informacje dodatkowe
                  </Typography>

                  <Typography>
                    {details.information}
                  </Typography>
                </Card>
                <Card
                  mode={'white'}
                  className={classes.card}
                >
                  <Button
                    label={'Zobacz regulamin konkursu'}
                    onClick={() => { openDialog('regulations') }}
                    fullWidth
                    color={'secondary'}
                  />
                </Card>
                <Dialog open={isDialogOpen}>
                  <Loader
                    wrapperProps={{
                      className: classes.loader,
                    }}
                    opacity={0.8}
                    doNotUnmountChildren={true}
                    loading={unlockState.loading}
                  >
                    <Card
                      mode={'white'}
                      className={classes.dialogCard}
                    >
                      {dialogType === 'regulations' &&
                        <>
                          <Typography
                            variant={'h2'}
                            align={'center'}
                          >
                            Regulamin
                          </Typography>
                          <Typography>
                            {details.regulations ? details.regulations.split('\n').map((line) => <>{line}<br /></>) : 'Brak regulaminu dla tego konkursu'}
                          </Typography>
                        </>}
                      {
                        dialogType === 'save' &&
                        <>
                          <Typography
                            variant={'h2'}
                            align={'center'}
                          >
                            Przypomnienie
                          </Typography>
                          <Typography
                            align={'center'}
                          >
                            Dziękujemy za zapisanie się do konkursu
                          </Typography>
                          <Uniform
                            schema={reminderSchemaMail}
                            model={{ email: userData?.email }}
                            submitField={() => null}
                          />
                          <Typography>
                            Jeżeli chcesz otrzymywać dodatkowe powiadomienia w formie sms, prosimy o podanie numeru telefonu:
                          </Typography>
                          <Uniform
                            schema={reminderSchemaPhone}
                            onSubmit={handleClickSendNotification}
                            model={{ phone: userData?.phone }}
                            submitField={() => (
                              <Button
                                className={classes.buttonSendSMS}
                                fullWidth
                                label={'Wyślij również powiadomienie SMS'}
                                type={'submit'}
                              />
                            )}
                          />
                        </>
                      }
                      {
                        dialogType === 'unlock' &&
                        <>
                          <Typography
                            variant={'h2'}
                            align={'center'}
                          >
                            Odblokuj konkurs
                          </Typography>
                          <Uniform
                            schema={unlockSchema}
                            onSubmit={submitCode}
                            submitField={() => (
                              <Button
                                type={'submit'}
                                label={'ODBLOKUJ'}
                                fullWidth
                                color={'secondary'}
                              />
                            )}
                          />
                        </>
                      }
                      <Button
                        label={dialogType === 'save' ? 'Wróć' : 'Zamknij'}
                        onClick={() => setIsDialogOpen(false)}
                        fullWidth
                        className={dialogType === 'unlock' ? classes.unlockReturnBtn : ''}
                        color={'secondary'}
                      />
                    </Card>
                  </Loader>
                </Dialog>
              </PageLayout>
              :
              null
            }
            <Snackbars
              snackbarMessage={snackbarMessage}
              onClose={() => setSnackbarMessage(null)}
              snackbarErrorMessage={snackbarErrorMessage}
              onCloseError={() => setSnackbarErrorMessage(null)}
            />
          </Loader>
        </Loader>
      </Loader>
    </>
  )
}

export default PageQuiz
