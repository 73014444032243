/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-types */

export const schema = {
  title: 'UserPassword',
  type: 'object',
  properties: {
    newPassword: {
      type: 'string',
      minLength: 1,
      label: 'Nowe hasło',
      errorMessage: 'Wprowadź hasło',
      uniforms: {
        variant: 'outlined',
        type: 'password',
      },
    },
    replyNewPassword: {
      type: 'string',
      minLength: 1,
      label: 'Powtórz nowe hasło',
      errorMessage: 'Wprowadź dwa identyczne hasła',
      const: {
        $data: '1/newPassword',
      },
      uniforms: {
        variant: 'outlined',
        type: 'password',
      },
    },
  },

  required: ['newPassword', 'replyNewPassword'],
}
