import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { CoinsBalance } from './types'

export const getUserBalance = async (): Promise<CoinsBalance> => {
  const response = await fetchCustomEndpoint<CoinsBalance>({
    url: '/coin_transactions/balance',
  })

  const data: CoinsBalance = response.data

  return data
}

export const useGetUserBalance = () => {
  return useAsyncFn(getUserBalance)
}
