import React from 'react'

import { makeStyles, Typography, Theme } from '@material-ui/core'

import CardAnswer from 'components/molecules/CardAnswer'

export interface Answer {
  text: string,
}

export interface QuizAnswersProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onSelect'> {
  selectedAnswer: Answer | null,
  answers: Answer[],
  onConfirm?: (answer: Answer) => void,
  onSelect?: (answer: Answer) => void,
  onClickReport: () => void,
}

const useStyles = makeStyles<Theme, QuizAnswersProps>((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  answer: {
    'flexBasis': `calc(50% - ${theme.spacing(1) / 2}px)`,
    'marginTop': theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonReport: {
    cursor: 'pointer',
    fontSize: 11,
    color: theme.palette.error.main,
    display: 'block',
    margin: ' 0 auto',
    marginBottom: theme.spacing(2),
  },
}), { name: 'QuizAnswers' })

export const QuizAnswers = (props: QuizAnswersProps) => {
  const classes = useStyles(props)
  const {
    selectedAnswer,
    answers,
    onClickReport,
    onConfirm,
    onSelect,
    ...otherProps
  } = props

  const answerClickHandler = React.useCallback((answer: Answer) => {
    if (selectedAnswer === answer) {
      onConfirm && onConfirm(answer)
      return
    }

    onSelect && onSelect(answer)
  }, [onConfirm, onSelect, selectedAnswer])

  return (
    <>
      <div
        className={classes.root}
        {...otherProps}
      >
        {
          answers?.map((answer, i) => {
            const isSelected = selectedAnswer === answer
            return (
              <CardAnswer
                key={`${answer}-${i}`}
                className={classes.answer}
                selected={isSelected}
                text={isSelected ? 'Potwierdź wybór' : answer.text}
                onClick={() => answerClickHandler(answer)}
              />
            )
          })
        }
      </div>
      <Typography
        component={'span'}
        align={'center'}
        className={classes.buttonReport}
        onClick={onClickReport}
      >
        Zauważyłeś błąd?
      </Typography>
    </>
  )
}

export default QuizAnswers
