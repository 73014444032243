import { createAuth } from 'components/atoms/Auth'

import { User } from 'api'

export const {
  useAuthContext,
  AuthContext,
  Auth,
} = createAuth<Partial<User>>()

export default Auth
