import React from 'react'

export interface RouterContextState {
  baseUrl: string,
}

export const RouterContext = React.createContext<RouterContextState>({ baseUrl: '' })

export const RouterContextProvider = RouterContext.Provider

export const useRouterContext = () => React.useContext(RouterContext)

export default RouterContext
