export const schema = {
  title: 'Contact',
  type: 'object',
  properties: {
    email: {
      type: 'string',
      format: 'email',
      minLength: 1,
      label: 'E-mail',
      errorMessage: 'Wpisz poprawny adres email.',
      uniforms: {
        type: 'email',
        variant: 'outlined',
      },
    },
    firstName: {
      type: 'string',
      errorMessage: 'Wpisz imię',
      label: 'Imię',
      uniforms: {
        type: 'string',
        variant: 'outlined',
      },
    },
    lastName: {
      type: 'string',
      errorMessage: 'Wpisz nazwisko',
      label: 'Nazwisko',
      uniforms: {
        type: 'string',
        variant: 'outlined',
      },
    },
    message: {
      type: 'string',
      errorMessage: 'Napisz swoją wiadomość',
      label: 'Wiadomość',
      minLength: 1,
      uniforms: {
        type: 'string',
        variant: 'outlined',
      },
    },
  },
  required: ['email', 'message'],
}
