import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { RequestPasswordResetResponse } from './types'

export const requestPasswordReset = async (email: string): Promise<RequestPasswordResetResponse> => {
  const response = await fetchCustomEndpoint<RequestPasswordResetResponse>({
    url: '/users/remind',
    method: 'POST',
    data: {
      email,
      redirect: window._env_.REACT_APP_URL + '/forgot-password',
    },
    headers: {
      // do NOT send token even it is stored in the storage
      Authorization: undefined,
    },
  })

  const data: RequestPasswordResetResponse = response.data

  return data
}

export const useRequestPasswordReset = () => {
  return useAsyncFn(requestPasswordReset)
}
