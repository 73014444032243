import { fetchCustomEndpoint } from 'api'
import { useAsyncFn } from 'utils/useAsyncFn'

export const redeemPromoCode = async (code: number): Promise<{ promoCodeId: string }> => {
  try {
    const response = await fetchCustomEndpoint<{ promoCodeId: string }>({
      url: '/promo_codes/redeem/' + code,
    })

    const data: { promoCodeId: string } = response.data

    return data

  } catch (error) {
    const status = error.response.status
    if (status === 404) {
      throw new Error('Błędny kod promocyjny')
    } else if(status === 422) {
      throw new Error('Kod został już wykorzystany')
    } else {
      throw error
    }
  }
}

export const useRedeemPromoCode = () => {
  return useAsyncFn(redeemPromoCode)
}


