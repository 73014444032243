import { directus } from 'api'
import { useAsyncFn } from 'utils/useAsyncFn'
import { User } from './types'


export const getUser = async () => {
  const result = await directus.users.me.read() as unknown as { data: User }

  return result.data
}

export const useGetUser = () => {
  return useAsyncFn(getUser)
}
