import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { UnlockQuizResponse } from './types'

export const unlock = async (quizUuid: string, code: string): Promise<UnlockQuizResponse> => {
  const response = await fetchCustomEndpoint<UnlockQuizResponse>({
    url: `/quizzes/${quizUuid}/unlock`,
    method: 'POST',
    data: { code },
  })

  const data: UnlockQuizResponse = response.data

  return data
}

export const useUnlock = () => {
  return useAsyncFn(unlock)
}
