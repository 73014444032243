import React from 'react'
import { Redirect } from 'react-router-dom'

export const RedirectToMainRoute = () => {
  return (
    <Redirect to={'/'} />
  )
}

export default RedirectToMainRoute
