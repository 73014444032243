import { useAsyncFn } from 'utils/useAsyncFn'

import directus from 'api/directus'

export const resetPassword = async (token: string, password: string): Promise<void> => {
  const response = await directus.auth.password.reset(token, password)

  return response
}

export const useResetPassword = () => {
  return useAsyncFn(resetPassword)
}
