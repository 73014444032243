import React, { DependencyList } from 'react'

import { useAsyncFn as useAsyncFnOriginal } from 'react-use'

// this types are copied from the library as is not exported from `react-use`
export type AsyncState<T> =
  | {
    loading: boolean,
    error?: undefined,
    value?: undefined,
  }
  | {
    loading: true,
    error?: Error | undefined,
    value?: T,
  }
  | {
    loading: false,
    error: Error,
    value?: undefined,
  }
  | {
    loading: false,
    error?: undefined,
    value: T,
  }

export type PromiseType<P extends Promise<any>> = P extends Promise<infer T> ? T : never
export type FnReturningPromise = (...args: any[]) => Promise<any>
export type StateFromFnReturningPromise<T extends FnReturningPromise> = AsyncState<PromiseType<ReturnType<T>>>
export type AsyncFnReturn<T extends FnReturningPromise = FnReturningPromise> = [StateFromFnReturningPromise<T>, T]

export const useAsyncFn = <T extends FnReturningPromise>(
  fn: T,
  deps: DependencyList = [],
  initialState: StateFromFnReturningPromise<T> = { loading: false }
): AsyncFnReturn<T> => {
  const [state, callback] = useAsyncFnOriginal(fn, deps, initialState)

  const callbackEnhanced = React.useCallback(async (...cbArgs: Parameters<T>): Promise<ReturnType<T>> => {
    const result = await callback(...cbArgs)
    if(result instanceof Error) throw result
    return result
  }, [callback])

  return [state, (callbackEnhanced as unknown) as T]
}
