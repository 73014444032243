import { createAuth } from './createAuth'

export const {
  useAuthContext,
  AuthContext,
  Auth,
} = createAuth<Record<string, unknown>>()

export default Auth

