import React from 'react'

import { Grid, Typography, makeStyles, Theme, TypographyProps } from '@material-ui/core'

import Card from 'components/atoms/Card'
import Snackbars from 'components/atoms/Snackbars'

import ListCard from 'components/molecules/ListCard'
import MediaCard from 'components/molecules/MediaCard'
import TimerCard from 'components/molecules/TimerCard'
import PageLayout from 'components/templates/PageLayout'

import AddQuestionForm, { QuestionModelRaw, Category } from './AddQuestionForm'

import { useSuggestQuestion } from 'domains/main/api/question'

interface TitleCardProps {
  title: string,
  subheader: string,
}

interface InfoListCardProps {
  title: string,
  subheader: string,
  itemsList: React.ReactNode[],
  titleTypographyProps: TypographyProps,
  subheaderTypographyProps: TypographyProps,
}

interface MediaCardProps {
  title: string,
  desc: string,
  img: string,
  reward: boolean,
}

interface TimerCardProps {
  time: number,
  title: string,
  text: string,
  label: string,
}

interface TopListCardProps {
  title: string,
  subheader: string,
  leaderBoardProps: {
    leaders: {
      avatar: string | null,
      email: string,
      score: number,
    }[],
    displayScore: boolean,
  },
  titleTypographyProps: TypographyProps,
  subheaderTypographyProps: TypographyProps,
}

export interface PageAddQuestionViewProps extends React.HTMLProps<HTMLDivElement> {
  titleCardProps: TitleCardProps,
  infoListCardProps: InfoListCardProps,
  mediaCardProps: MediaCardProps,
  timerCardProps: TimerCardProps,
  topListCardProps: TopListCardProps,
  categories: Category[],
}

export interface AnswerJSON {
  text: string,
  valid: boolean,
}

export interface QuestionModel {
  media?: File,
  video?: string,
  question: string,
  answers: string,
  category: string,
}

const useStyles = makeStyles<Theme, PageAddQuestionViewProps>((theme) => ({
  white: {
    color: theme.palette.common.white,
  },
  whiteWithShadow: {
    color: theme.palette.common.white,
    filter: 'drop-shadow(1px 1px 3px #225998)',
    marginBottom: theme.spacing(1),
  },
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
}), { name: 'PageAddQuestionView' })

export const PageAddQuestionView = (props: PageAddQuestionViewProps) => {
  const classes = useStyles(props)

  const [suggestQuestionState, suggestQuestion] = useSuggestQuestion()

  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const [questionStep, setQuestionStep] = React.useState<number>(1)
  const [videoLink, setVideoLink] = React.useState<string>('')
  const [photo, setPhoto] = React.useState<File>()

  const {
    titleCardProps,
    infoListCardProps,
    mediaCardProps,
    timerCardProps,
    topListCardProps,
    categories,
  } = props

  const closeModal = React.useCallback(() => {
    setIsModalOpen(false)
    setVideoLink('')
    setPhoto(undefined)
  }, [])

  const makeAnswersArray = React.useCallback((data: QuestionModelRaw) => {
    const answers = [
      { text: data.answerA, valid: false },
      { text: data.answerB, valid: false },
      { text: data.answerC, valid: false },
      { text: data.answerD, valid: false },
    ]

    answers[data.correctAnswer].valid = true

    return JSON.stringify(answers)
  }, [])

  const submitForm = React.useCallback(async ( data: QuestionModelRaw ) => {
    const model: QuestionModel = {
      media: photo,
      video: videoLink,
      question: data.question,
      answers: makeAnswersArray(data),
      category: data.category,
    }

    await suggestQuestion(model)
    setIsSnackbarOpen(true)
    closeModal()
  }, [closeModal, makeAnswersArray, photo, suggestQuestion, videoLink])

  return (
    <PageLayout>
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Card
            mode={'white'}
          >
            <Typography
              variant={'h3'}
              align={'left'}
              className={classes.shadow}
            >
              {titleCardProps.title}
            </Typography>
            <Typography
              variant={'h5'}
              align={'left'}
              color={'secondary'}
            >
              {titleCardProps.subheader}
            </Typography>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.o}
        >
          <ListCard
            {...infoListCardProps}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <MediaCard
            {...mediaCardProps}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TimerCard
            setIsModalOpen={setIsModalOpen}
            {...timerCardProps}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ListCard
            {...topListCardProps}
          />
        </Grid>
      </Grid>
      <AddQuestionForm
        suggestQuestionState={suggestQuestionState}
        categories={categories}
        isModalOpen={isModalOpen}
        questionStep={questionStep}
        videoLink={videoLink}
        photo={photo}
        setQuestionStep={setQuestionStep}
        closeModal={closeModal}
        submitForm={submitForm}
        setVideoLink={setVideoLink}
        setPhoto={setPhoto}
      />
      <Snackbars
        snackbarMessage={isSnackbarOpen ? 'Twoja sugestia została wysłana' : ''}
        onClose={() => setIsSnackbarOpen(false)}
      />
    </PageLayout>
  )
}

export default PageAddQuestionView
