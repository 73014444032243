import * as React from 'react'

function Upcoming(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={'24px'}
      height={'24px'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      {...props}
    >
      <path
        d={'M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z'}
        fill={'#225998'}
      />
      <path
        d={'M8 16l5-4-5-4v8zm5-4v4h2V8h-2v4z'}
        fill={'#225998'}
      />
    </svg>
  )
}

export default Upcoming
