import React from 'react'

import {
  makeStyles,
  Theme,
  List,
  ListProps,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'

export interface SideBarMenuItem {
  isSpecial?: boolean,
  text: React.ReactNode,
  textSecondary?: React.ReactNode,
  icon: React.ReactNode,
  onClick: () => void,
}

export interface SideBarMenuProps extends ListProps {
  menuItems: SideBarMenuItem[],
}

const useStyles = makeStyles<Theme, SideBarMenuProps>((theme) => ({
  list: {
    margin: theme.spacing(1),
  },
  listItem: {
    borderRadius: 4,
  },
  icons: {
    color: theme.palette.secondary.main,
  },
  textSecondary: {
    color: theme.palette.grey[500],
    fontSize: 12,
    fontWeight: 400,
  },
  special: {
    'backgroundColor': theme.palette.background.special,
    '&:hover': {
      backgroundColor: theme.palette.background.specialHover,
    },
  },
  specialFont: {
    fontWeight: 600,
  },
}), { name: 'SideBarMenu' } )

export const SideBarMenu = (props: SideBarMenuProps) => {
  const classes = useStyles(props)
  const {
    menuItems,
    ...otherProps
  } = props

  const renderedItems = React.useMemo(() => {
    return menuItems
      .map(({ onClick, isSpecial, icon, text, textSecondary }, index) => {
        return (
          <ListItem
            key={index}
            button={true}
            onClick={onClick}
            className={isSpecial ? classes.special : ''}
            classes={{
              root: classes.listItem,
            }}
          >
            <ListItemIcon
              className={classes.icons}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={text}
              secondary={textSecondary}
              primaryTypographyProps={{
                noWrap: true,
                color: 'textPrimary',
              }}
              secondaryTypographyProps={{
                noWrap: true,
                className: classes.textSecondary,
              }}
              classes={{
                primary: isSpecial ? classes.specialFont : '',
              }}
            />
          </ListItem>
        )
      })
  }, [classes.icons, classes.listItem, classes.special, classes.specialFont, classes.textSecondary, menuItems])

  return (
    <List
      classes={{
        root: classes.list,
      }}
      {...otherProps}
    >
      {renderedItems}
    </List>
  )
}

export default SideBarMenu
