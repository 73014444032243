import React from 'react'

import { Grid } from '@material-ui/core'

import PageLayout from 'components/templates/PageLayout'

import BecomeSponsorCard from 'components/organisms/BecomeSponsorCard'
import FormSponsor, { SponsorContactFormModel } from 'components/organisms/FormSponsor'

export interface PageBecomeSponsorViewProps extends React.HTMLProps<HTMLDivElement> {
  becomeSponsorCardProps: {
    title: string,
    subtitle: string,
    text: string,
    showButton: boolean,
    endingText: string,
  },
  formSponsorProps: {
    title: string,
    subtitle: string,
    sponsorFromSchema: Record<string, any>,
    onFormSubmit: (formValues: SponsorContactFormModel) => void,
  },
}

export const PageBecomeSponsorView = (props: PageBecomeSponsorViewProps) => {
  const { becomeSponsorCardProps, formSponsorProps } = props

  return (
    <PageLayout>
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <BecomeSponsorCard
            {...becomeSponsorCardProps}
          />
          <FormSponsor
            {...formSponsorProps}
          />
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default PageBecomeSponsorView
