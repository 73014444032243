import React from 'react'
import classnames from 'classnames'

import { makeStyles, Theme, Typography } from '@material-ui/core'

export interface PageNotFoundMessageProps extends React.HTMLProps<HTMLDivElement> {
  retry?: () => void,
  cancel?: () => void,
}

const useStyles = makeStyles<Theme, PageNotFoundMessageProps>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 86px)',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 62px)',
    },
  },
  message: {
    fontSize: '24px',
    marginTop: '10px',
    textAlign: 'center',
  },
  status: {
    fontSize: '100px',
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    color: theme.palette.common.white,
  },
}), { name: 'PageNotFoundMessage' } )

export const PageNotFoundMessage = (props: PageNotFoundMessageProps) => {
  const classes = useStyles(props)
  const {
    className,
    ...otherProps
  } = props

  return (
    <div
      className={classnames([
        classes.root,
        className,
      ])}
      {...otherProps}
    >
      <Typography
        className={classes.status}
      >
        404
      </Typography>
      <Typography
        className={classes.message}
      >
        Wygląda na to, że
        <br />
        nic tu nue ma!
      </Typography>
    </div>
  )
}

export default PageNotFoundMessage
