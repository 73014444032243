export const schema = {
  title: 'Register',
  type: 'object',
  required: ['email', 'password', 'replyPassword'],
  properties: {
    email: {
      type: 'string',
      format: 'email',
      label: 'E-mail',
      errorMessage: 'Podaj poprawny adres e-mail',
      minLength: 1,
      uniforms: {
        type: 'email',
        variant: 'outlined',
        disabled: true,
      },
    },
    password: {
      type: 'string',
      errorMessage: 'Wpisz hasło',
      label: 'Hasło',
      minLength: 1,
      uniforms: {
        type: 'password',
        variant: 'outlined',
      },
    },
    replyPassword: {
      type: 'string',
      errorMessage: 'Powtórz poprawnie hasło',
      label: 'Powtórz Hasło',
      minLength: 1,
      const: {
        $data: '1/password',
      },
      uniforms: {
        type: 'password',
        variant: 'outlined',
      },
    },
  },
}
