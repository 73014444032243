import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'

export interface Ratio16X9Props extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode,
}

const useStyles = makeStyles<Theme, Ratio16X9Props>((theme) => ({
  ratioWrapper16x9: {
    position: 'relative',
    paddingTop: '56.25%',
    width: '100%',
  },
  ratioContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}), { name: 'Ratio16X9' } )

export const Ratio16X9 = (props: Ratio16X9Props) => {
  const classes = useStyles(props)
  const { children, ...otherProps } = props

  return (
    <div
      className={classes.ratioWrapper16x9}
      {...otherProps}
    >
      <div
        className={classes.ratioContainer}
      >
        {children}
      </div>
    </div>
  )
}

export default Ratio16X9
