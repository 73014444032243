import { fetchCustomEndpoint } from 'api'
import { useAsyncFn } from 'utils/useAsyncFn'

export const getPaymentUrl = async (amount: number): Promise<{ redirectURL: string }> => {
  const response = await fetchCustomEndpoint<{ redirectURL: string }>({
    url: '/payments',
    method: 'POST',
    data: {
      amount,
      provider: 'tpay',
    },
  })

  const data: { redirectURL: string } = response.data

  return data
}

export const useGetPaymentUrl = () => {
  return useAsyncFn(getPaymentUrl)
}


