/* eslint-disable camelcase */
import omit from 'lodash/omit'

import { CoinTransaction, CoinTransactionRaw } from './types'

export const convertCoinTransaction = (transaction: CoinTransactionRaw): CoinTransaction  => {
  return {
    ...omit(transaction, ['promo_code', 'date_created', 'date_updated', 'user_created', 'user_updated']),
    promoCode: transaction.promo_code,
    dateCreated: new Date(transaction.date_created || 0),
    dateUpdated: new Date(transaction.date_updated || 0),
    userCreated: transaction.user_created,
    userUpdated: transaction.user_updated,
  }
}

export const convertCoinTransactions = (transactions: CoinTransactionRaw[]): CoinTransaction[]  => {
  return transactions.map(convertCoinTransaction)
}
