import React from 'react'
import classnames from 'classnames'

import { useDropzone, DropzoneOptions, DropzoneState } from 'react-dropzone'

import { Button, Typography, ButtonProps, makeStyles, Theme } from '@material-ui/core'

import formatBytes from 'utils/formatBytes'

export const DEFAULT_ALLOWED_MIMETYPE = [
  'image/jpeg',
  'image/png',
]

export const DEFAULT_ACCEPT_DESC = [
  'jpeg',
  'jpg',
  'png',
]

const useStyles = makeStyles<Theme, DropzoneProps>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  baseStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[700],
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    height: 181,
  },
  activeStyle: {},
  acceptStyle: {},
  rejectStyle: {
    borderColor: theme.palette.error.main,
  },
  buttonSend: {
    backgroundColor: theme.palette.secondary.main,
  },
  buttonReset: {
    'backgroundColor': theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}), { name: 'Dropzone' })

const makeFileListItem = (file: { path?: string } & File) => (
  file ?
    <li
      style={{ fontSize: 'small' }}
      key={file.path}
    >
      <Typography
        style={{ fontSize: 'small' }}
        noWrap={true}
        component={'p'}
      >
        {file.path} - {formatBytes(file.size)}
      </Typography>
    </li>
    :
    null
)

export interface DropzoneProps {
  onSubmit?: (acceptedFiles: File[], rejectedFiles?: File[]) => void,
  buttonProps?: ButtonProps,
  dropzoneProps?: DropzoneOptions,
  acceptDesc?: string[],
  submitImmediately?: boolean,
  submitLabel?: string,
}

const Dropzone = (props: DropzoneProps) => {
  const {
    dropzoneProps = {
      maxSize: 1024 * 1024,
      accept: DEFAULT_ALLOWED_MIMETYPE,
      maxFiles: 1,
    },
    buttonProps = {},
    submitImmediately = false,
    acceptDesc = DEFAULT_ACCEPT_DESC,
    submitLabel = 'Wyślij wybrany plik',
    onSubmit,
  } = props

  const classes = useStyles(props)

  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([])
  const [rejectedFiles, setRejectedFiles] = React.useState<File[]>([])
  const resetState = React.useCallback(() => {
    setAcceptedFiles([])
    setRejectedFiles([])
  }, [])

  const {
    acceptedFiles: acceptedFilesDropzone,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ ...dropzoneProps }) as { rejectedFiles?: File[] } & DropzoneState
  const rejectedFilesDropzone = React.useMemo(() => fileRejections?.map(({ file }) => file), [fileRejections])

  React.useEffect(() => {
    setAcceptedFiles(acceptedFiles.concat(acceptedFilesDropzone))
    setRejectedFiles(rejectedFiles.concat(rejectedFilesDropzone || []))

    if(submitImmediately && onSubmit && Array.isArray(acceptedFilesDropzone) && acceptedFilesDropzone.length > 0){
      onSubmit(acceptedFilesDropzone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFilesDropzone, rejectedFilesDropzone])

  const acceptedFilesItems = acceptedFiles.map(makeFileListItem)
  const rejectedFilesItems = rejectedFiles.map(makeFileListItem)

  return (
    <div
      className={classes.root}
    >
      <div
        {...getRootProps()}
        className={classnames([
          classes.baseStyle,
          isDragActive ? classes.activeStyle : null,
          isDragAccept ? classes.acceptStyle : null,
          isDragReject ? classes.rejectStyle : null,
        ])}
      >
        <input
          {...getInputProps()}
          multiple={false}
        />
        <p>
          Przeciągnij plik tutaj lub kliknij w przycisk aby wybrać.
        </p>
        {
          dropzoneProps && dropzoneProps.accept &&
          <em>
            Akceptowane są wyłącznie pliki:{' '}
            {
              acceptDesc ?
                acceptDesc.join(', ')
                :
                Array.isArray(dropzoneProps.accept) ?
                  dropzoneProps.accept.join(', ')
                  :
                  dropzoneProps?.accept
            }
          </em>
        }
        {
          dropzoneProps && dropzoneProps.maxSize &&
          <em>
            Maksymalny rozmiar pliku to: {formatBytes(dropzoneProps.maxSize)}
          </em>
        }
      </div>
      {
        !submitImmediately ?
          <>
            <aside>
              {
                acceptedFiles.length > 0 ?
                  <>
                    <h4>Zaakceptowane pliki:</h4>
                    <ol>
                      {acceptedFilesItems}
                    </ol>
                  </>
                  :
                  null
              }
              {
                rejectedFiles.length > 0 ?
                  <>
                    <h4>Odrzucone pliki:</h4>
                    <ol>
                      {rejectedFilesItems}
                    </ol>
                  </>
                  :
                  null
              }
            </aside>
            {
              onSubmit && acceptedFiles.length > 0 ?
                <Button
                  className={classes.buttonSend}
                  style={{ margin: '1rem 0 0.5rem 0' }}
                  fullWidth={true}
                  onClick={() => onSubmit(acceptedFiles, rejectedFiles)}
                  {...buttonProps}
                >
                  {submitLabel}
                </Button>
                :
                null
            }
            {
              acceptedFiles.length > 0 || rejectedFiles.length > 0 ?
                <Button
                  className={classes.buttonReset}
                  fullWidth={true}
                  onClick={resetState}
                  {...buttonProps}
                >
                  Resetuj pliki
                </Button>
                :
                null
            }
          </>
          :
          null
      }
    </div>
  )
}

export default Dropzone
