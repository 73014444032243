import React from 'react'

import Card from 'components/atoms/Card'

import Loader from 'components/molecules/Loader'

import QuizAnswerResult from 'components/organisms/QuizAnswerResult'

import PageLayout from 'components/templates/PageLayout'

import { useGetQuizRanking } from 'domains/main/api/quizzes'

import { routeHome } from 'domains/main'
import { makeImgSrc } from 'domains/main/api/media'

export interface ViewQuizAnswerResultProps extends React.HTMLProps<HTMLDivElement> {
  quizUuid: string,
  questionId: string,
  questionPrice: number,
  answerWasValid: boolean,
  validAnswerText: string,
  onClickNextQuestion: () => void,
}

export const ViewQuizAnswerResult = (props: ViewQuizAnswerResultProps) => {
  const {
    quizUuid,
    questionPrice,
    answerWasValid,
    onClickNextQuestion,
  } = props

  const routeToHome = routeHome.useRouteTo()

  const [quizRankingState, getQuizRanking] = useGetQuizRanking()
  React.useEffect(() => {
    getQuizRanking(quizUuid)
  }, [getQuizRanking, quizUuid])

  const leaders = quizRankingState.value?.map((leader) => ({ ...leader, avatar: leader.avatar && makeImgSrc(leader.avatar, { width: 40, height: 40 }) }))

  return (
    <Loader
      loading={quizRankingState.loading}
      error={quizRankingState.error}
      message={'Ładowanie rankingu...'}
    >
      <PageLayout>
        <Card
          mode={'white'}
        >
          <QuizAnswerResult
            onClickNextQuestion={onClickNextQuestion}
            onClickLeave={routeToHome}
            questionPrice={questionPrice}
            answerWasValid={answerWasValid}
            leaders={leaders}
          />
        </Card>
      </PageLayout>
    </Loader>
  )
}

export default ViewQuizAnswerResult
