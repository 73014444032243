import { omit } from 'lodash'
import { Quiz, QuizRaw } from './types'

export const convertQuiz = (quiz: QuizRaw): Quiz => {
  return {
    ...omit(quiz, ['start_time', 'end_time']),
    startTime: new Date(quiz.start_time),
    endTime: new Date(quiz.end_time),
    questionPrice: quiz.question_price,
  }
}

export const convertQuizzes = (quizzes: QuizRaw[]): Quiz[] => {
  return quizzes.map(convertQuiz)
}
