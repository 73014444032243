/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-types */

export const makeSchema = (isDisabled: boolean) =>  {
  return {
    title: 'Address',
    type: 'object',
    properties: {
      city: {
        type: 'string',
        minLength: 1,
        disabled: isDisabled,
        label: 'Miasto',
        errorMessage: 'Wpisz poprawną nazwę miasta',
        uniforms: {
          variant: 'outlined',
        },
      },
      street: {
        type: 'string',
        disabled: isDisabled,
        minLength: 1,
        label: 'Ulica',
        errorMessage: 'Wpisz poprawną nazwę ulicy oraz numer domu',
        uniforms: {
          variant: 'outlined',
        },
      },
      postCode: {
        type: 'string',
        pattern: '^[0-9]{2}-[0-9]{3}$',
        disabled: isDisabled,
        errorMessage: 'Wpisz poprawny kod pocztowy',
        label: 'Kod pocztowy',
        uniforms: {
          variant: 'outlined',
        },
      },
      phoneNumber: {
        type: 'string',
        disabled: isDisabled,
        pattern: '\\+48[0-9]{9}$',
        errorMessage: 'Wpisz wyłącznie liczby oraz prefix +48',
        label: 'Numer telefonu',
        uniforms: {
          variant: 'outlined',
        },
      },
    },
    required: ['city', 'street', 'postCode', 'phoneNumber'],
  }}

