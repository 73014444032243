import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { WeeklySuggestions } from './types'

export const getWeeklySuggestions = async (): Promise<WeeklySuggestions> => {
  const response = await fetchCustomEndpoint<WeeklySuggestions>({
    url: '/weekly_suggestions',
  })

  const data: WeeklySuggestions = response.data

  return data
}

export const useGetWeeklySuggestions = () => {
  return useAsyncFn(getWeeklySuggestions)
}
