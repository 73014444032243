/* eslint-disable max-len */

import * as React from 'react'

function Lock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={'24px'}
      height={'24px'}
      viewBox={'0 0 25 25'}
      fill={'none'}
      {...props}
    >
      <path
        d={'M12.563 14.299a1.674 1.674 0 00-.757 3.16v1.985h1.389v-1.923a1.674 1.674 0 00-.632-3.222z'}
        fill={'#225998'}
      />
      <path
        d={'M18.055 10.417V7.444A5.694 5.694 0 0012.5 1.64a5.694 5.694 0 00-5.556 5.805v2.973H4.861v11.805a1.389 1.389 0 001.389 1.39h12.5a1.389 1.389 0 001.389-1.39V10.417h-2.084zM8.333 7.444A4.306 4.306 0 0112.5 3.028a4.306 4.306 0 014.167 4.416v2.973H8.333V7.444zM6.25 22.222V11.806h12.5v10.416H6.25z'}
        fill={'#225998'}
      />
    </svg>
  )
}

export default Lock
