import React from 'react'

import { Typography, makeStyles, Theme } from '@material-ui/core'

import Card from 'components/atoms/Card'
import Button from 'components/atoms/Button'
import Uniform from 'components/molecules/Uniform'

export interface SponsorContactFormModel {
  email: string,
  company: string,
  firstName: string,
  lastName: string,
  message: string,
}

export interface FormSponsorProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  subtitle: string,
  sponsorFromSchema: Record<string, any>,
  onFormSubmit: (formValues: SponsorContactFormModel) => void,
}

const useStyles = makeStyles<Theme, FormSponsorProps>((theme) => ({
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
}), { name: 'FormSponsor' })

export const FormSponsor = (props: FormSponsorProps) => {
  const classes = useStyles(props)

  const {
    title,
    subtitle,
    sponsorFromSchema,
    onFormSubmit,
  } = props

  return (
    <Card
      mode={'white'}
    >
      <Typography
        className={classes.shadow}
        variant={'h3'}
      >
        {title}
      </Typography>
      <Typography
        variant={'h5'}
        gutterBottom={true}
      >
        {subtitle}
      </Typography>
      <Uniform
        schema={sponsorFromSchema}
        onSubmit={(model: SponsorContactFormModel) => onFormSubmit(model)}
        submitField={() => (
          <Button
            type={'submit'}
            label={'Wyślij'}
            fullWidth={true}
          />
        )}
      />
    </Card>
  )
}

export default FormSponsor
