import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { MaintenanceResponse } from './types'

export const maintenance = async (): Promise<MaintenanceResponse> => {
  const response = await fetchCustomEndpoint<MaintenanceResponse>({
    url: '/settings',
  })

  const data: MaintenanceResponse = response.data

  return data
}

export const useMaintenance = () => {
  return useAsyncFn(maintenance)
}
