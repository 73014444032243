import React from 'react'

import {
  makeStyles,
  Theme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'

import Card from 'components/atoms/Card'
import QuiziLogo from 'components/icons/QuiziLogo'

export type TransactionInitiator = 'question' | 'bonus' | 'payment' | 'code'

export interface Transactions {
  date: string,
  quizTitle?: string,
  amount?: number,
  id: string,
  initiator?: TransactionInitiator,
}

export interface TransactionsCardProps extends React.HTMLProps<HTMLDivElement> {
  transactions: Transactions[],
}

const useStyles = makeStyles<Theme, TransactionsCardProps>((theme) => ({
  whiteText: {
    color: theme.palette.secondary.contrastText,
    marginBottom: theme.spacing(2),
  },
  info: {
    minHeight: '20%',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  amountContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logo: {
    marginLeft: 6,
    transform: 'scale(0.6)',
  },
}), { name: 'TransactionsCard' })

const initiatorTexts: Record<TransactionInitiator, string> = {
  question: 'Płatność za pytanie',
  bonus: 'Bonus',
  payment: 'Dołądowanie konta',
  code: 'Wykorzystanie kodu',
}

export const TransactionsCard = (props: TransactionsCardProps) => {
  const classes = useStyles(props)

  const { transactions } = props

  const transactionsList = (transactionsToList: Transactions[]) => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Informacja o płatności</TableCell>
              <TableCell align={'right'}>Ilość tokenów</TableCell>
              <TableCell align={'right'}>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsToList.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell
                  size={'small'}
                >
                  {
                    transaction.initiator ?
                      initiatorTexts[transaction.initiator]
                      :
                      null
                  }
                </TableCell>
                <TableCell
                  component={'th'}
                  scope={'row'}
                  size={'small'}
                >
                  {
                    transaction.quizTitle ?
                      <div
                        className={classes.amountContainer}
                      >
                        {transaction.quizTitle}
                      </div>
                      :
                      <div
                        className={classes.amountContainer}
                      >
                        {transaction.amount}
                        <QuiziLogo
                          className={classes.logo}
                        />
                      </div>
                  }
                </TableCell>
                <TableCell
                  align={'right'}
                  size={'small'}
                >
                  {transaction.date}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const noTransactionsInfo = () => {
    return (
      <div
        className={classes.info}
      >
        <Typography
          className={classes.whiteText}
          variant={'h3'}
        >
          Wygląda na to, że nic tu nie ma!
        </Typography>
      </div>
    )
  }

  return (
    <>
      <Card
        mode={'darkBlue'}
      >
        <Typography
          variant={'h5'}
          align={'left'}
          className={classes.whiteText}
        >
          Historia zakupionych tokenów oraz odblokowanych pytań.
        </Typography>
        {
          transactions.length === 0 ?
            noTransactionsInfo()
            :
            transactionsList(transactions)}

      </Card>
    </>
  )
}

export default TransactionsCard
