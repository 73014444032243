// @HACK - directus have a bug that sends expired accessTokens even with login and refresh request
// and that causes them to fail to 401

import DirectusSDK from '@directus/sdk-js'
import omit from 'lodash/omit'

const AUTH_URL = [
  '/auth/refresh',
  '/auth/login',
  '/users/invite/accept',
  '/auth/password/reset',
]

export const stripAuthorizationHeaderFromAuthRequests = (directus: DirectusSDK) => {
  directus.axios.interceptors.request.use(
    async (config) => {
      if(config.url && AUTH_URL.includes(config.url)){
        config.headers = omit(config.headers, ['Authorization'])
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export default stripAuthorizationHeaderFromAuthRequests
