/* eslint-disable max-len */

import * as React from 'react'

function FinishFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={'24px'}
      height={'24px'}
      viewBox={'0 0 18 18'}
      fill={'none'}
      {...props}
    >
      <path
        d={'M0 0v18h1.555v-6H17.1V0H0zm1.555 1.5h2.331v2.25h2.332V1.5H8.55v2.25h2.332V1.5h2.332v2.25h2.332V6h-2.332v2.25h2.332v2.25h-2.332V8.25h-2.332v2.25H8.55V8.25H6.218v2.25H3.886V8.25H1.555V6h2.331V3.75H1.555V1.5zM3.886 6v2.25h2.332V6H3.886zm2.332 0H8.55V3.75H6.218V6zM8.55 6v2.25h2.332V6H8.55zm2.332 0h2.332V3.75h-2.332V6z'}
        fill={'#225998'}
      />
    </svg>
  )
}

export default FinishFlag
