import React from 'react'

import { Avatar, makeStyles, Theme, Typography } from '@material-ui/core'

import Card from 'components/atoms/Card'

import Button from 'components/atoms/Button'

import Uniform from 'components/molecules/Uniform'

import FormProfileDialog from './FormProfileDialog'

import { makeSchema as makeProfileAddressSchema } from './profileAddressSchema'

import { SchemaVariant, User } from 'domains/main/api/user/types'
import { makeImgSrc } from 'domains/main/api/media'

import { schema as profileInfoSchema } from './profileInfoSchema'

export interface FormProfileProps extends React.HTMLProps<HTMLDivElement> {
  isDialogOpen: boolean,
  selectedSchema: SchemaVariant,
  userData: User,
  updateForm: (data: File[] | Record<string, unknown>) => void,
  openSelectedDialog: (selectedVariant: SchemaVariant) => void,
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const useStyles = makeStyles<Theme, FormProfileProps>((theme) => ({
  root: {},
  card: {
    margin: '10px 0',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: '30px',
  },
  avatarImg: {
    width: '75px',
    height: '75px',
    color: theme.palette.common.white,
    background: theme.palette.grey[600],
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px',
  },
  editProfileButton: {
    width: '47%',
  },
  editAddressButton: {
    marginTop: '10px',
  },
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
}), { name: 'FormProfile' })

export const FormProfile = (props: FormProfileProps) => {
  const classes = useStyles(props)
  const {
    userData,
    isDialogOpen,
    selectedSchema,
    updateForm,
    setIsDialogOpen,
    openSelectedDialog,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Card
        className={classes.card}
        mode={'white'}
        {...otherProps}
      >
        <Typography
          variant={'h1'}
          className={classes.shadow}
          align={'center'}
          color={'secondary'}
          gutterBottom={true}
        >
          Twój Profil
        </Typography>
        <div
          className={classes.avatarContainer}
        >
          <Avatar
            className={classes.avatarImg}
            src={userData.avatar ? makeImgSrc(userData.avatar) : undefined}
          />
          <Button
            label={'Zmień avatar'}
            color={'secondary'}
            onClick={() => openSelectedDialog('profileAvatarSchema')}
          />
        </div>
        <Uniform
          model={{ nick: userData.email, firstName: userData.first_name, lastName: userData.last_name }}
          schema={profileInfoSchema}
          errorsField={() => null}
          showInlineError={true}
          submitField={() => null}
        />
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.editProfileButton}
            label={'Zmień hasło'}
            onClick={() => openSelectedDialog('profilePasswordSchema')}
          />
          <Button
            className={classes.editProfileButton}
            label={'Edytuj'}
            onClick={() => openSelectedDialog('profileNameSchema')}
            color={'secondary'}
          />
        </div>
      </Card>
      <Card
        className={classes.card}
        mode={'white'}
        {...otherProps}
      >
        <Typography
          variant={'h1'}
          className={classes.shadow}
          align={'center'}
          color={'secondary'}
          gutterBottom={true}
        >
          Adres
        </Typography>
        <Uniform
          model={{ street: userData.street, city: userData.city, postCode: userData.zipcode, phoneNumber: userData.phone }}
          schema={makeProfileAddressSchema(true)}
          errorsField={() => null}
          showInlineError={true}
          submitField={() => null}
        />
        <Button
          className={classes.editAddressButton}
          label={'Edytuj'}
          color={'secondary'}
          onClick={() => openSelectedDialog('profileAddressSchema')}
          fullWidth={true}
        />
      </Card>
      <FormProfileDialog
        open={isDialogOpen}
        userData={userData}
        selectedSchema={selectedSchema}
        updateForm={updateForm}
        setIsDialogOpen={setIsDialogOpen}
      />
    </div>
  )
}

export default FormProfile
