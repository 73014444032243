import React from 'react'

import { Dialog, DialogProps, makeStyles, Theme, Typography } from '@material-ui/core'

import Button from 'components/atoms/Button'

import Uniform from 'components/molecules/Uniform'

import Dropzone from 'components/atoms/Dropzone'

import { makeSchema as makeProfileAddressSchema } from '../profileAddressSchema'
import { schema as profilePasswordSchema } from './../profilePasswordSchema'
import { schema as profileNameSchema } from './../profileNameSchema'

export interface FormProfileDialogProps extends DialogProps {
  open: boolean,
  selectedSchema:  'profileAddressSchema' | 'profileNameSchema' | 'profilePasswordSchema' | 'profileAvatarSchema' | null,
  userData: Partial<Record<'street' | 'city' | 'zipcode' | 'phone' | 'first_name' | 'last_name', string | null>>,
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  updateForm: (data: File[] | Record<string, unknown>) => void,
}

const useStyles = makeStyles<Theme, FormProfileDialogProps>((theme) => ({
  root: {
    padding: '20px 20px 10px 20px',
    width: '80%',
  },
  buttonClose: {
    marginTop: '10px',
    background: theme.palette.error.light,
  },
}), { name: 'FormProfileDialog' })

export const FormProfileDialog = (props: FormProfileDialogProps) => {
  const classes = useStyles(props)
  const { open,
    userData,
    selectedSchema,
    updateForm,
    setIsDialogOpen,
    ...otherProps
  } = props

  const selectSchemaType =  React.useCallback(() => {
    // eslint-disable-next-line no-shadow
    let schema = {}
    // eslint-disable-next-line no-shadow
    let schemaName = ''
    // eslint-disable-next-line no-shadow
    let schemaModel = {}
    switch (selectedSchema) {
      case 'profileAddressSchema':
        schema = makeProfileAddressSchema(false)
        schemaName = 'Zmień adres'
        schemaModel = { street: userData.street, city: userData.city, postCode: userData.zipcode, phoneNumber: userData.phone }
        break
      case 'profileNameSchema':
        schema = profileNameSchema
        schemaName = 'Zmień profil'
        schemaModel = { firstName: userData.first_name, lastName: userData.last_name }
        break
      case 'profilePasswordSchema':
        schema = profilePasswordSchema
        schemaName = 'Zmień hasło '
        break
      case 'profileAvatarSchema':
        schema = {}
        schemaName = 'Zmień awatar'
        break
      default:
        schema = {}
        schemaName = ''
        schemaModel = {}
    }
    return { schema, schemaName, schemaModel }
  }, [selectedSchema, userData.city, userData.first_name, userData.last_name, userData.phone, userData.street, userData.zipcode])

  const { schemaModel, schema, schemaName } = selectSchemaType()

  return (
    <Dialog
      PaperProps={{ className: classes.root }}
      open={open}
      {...otherProps}
    >
      <Typography
        variant={'h2'}
        align={'center'}
        color={'secondary'}
      >
        {schemaName}
      </Typography>

      {
        selectedSchema === 'profileAvatarSchema' ?
          <Dropzone onSubmit={updateForm} />
          :
          <Uniform
            onSubmit={updateForm}
            model={schemaModel}
            schema={schema}
            submitField={() => <Button
              className={classes.buttonSave}
              label={'Zapisz'}
              type={'submit'}
              color={'secondary'}
              fullWidth={true} />}
          />
      }
      <Button
        className={classes.buttonClose}
        label={'Anuluj'}
        onClick={() => setIsDialogOpen(false)}
        fullWidth={true}
      />
    </Dialog>

  )
}

export default FormProfileDialog

