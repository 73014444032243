import React from 'react'

import { PageNotFoundMessage } from 'components/atoms/PageNotFoundMessage'

export const PageNotFound = () => {
  return (
    <PageNotFoundMessage />
  )
}

export default PageNotFound
