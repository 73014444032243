import React from 'react'
import classnames from 'classnames'

import { AutoForm } from 'uniforms-material'

import { makeStyles, Theme } from '@material-ui/core'

import Button from 'components/atoms/Button'

import AutoFieldCustom from './AutoFieldCustom'

import schemaToBridge from './schemaToBridge'

type AutoFormProps = React.ComponentProps<typeof AutoForm>
export interface UniformProps<Model = Record<string, any>> extends AutoFormProps {
  schema: Record<string, any>,
  classNameSubmitButton?: string,
  model?: Model,
  onSubmit?: (model: Model) => void,
}

const useStyles = makeStyles<Theme>((theme) => ({
  submitButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}), { name: 'Uniform' })

export const Uniform = <Model extends Record<string, any>>(props: UniformProps<Model>) => {
  const classes = useStyles(props)
  const {
    schema,
    classNameSubmitButton,
    onSubmit,
    ...otherProps
  } = props

  const bridge = React.useMemo(() => schemaToBridge(schema), [schema])

  return (
    <AutoForm
      schema={bridge}
      autoField={AutoFieldCustom}
      errorsField={() => null}
      showInlineError={true}
      submitField={
        () => (
          <Button
            className={classnames([
              classes.submitButton,
              classNameSubmitButton,
            ])}
            type={'submit'}
            label={'Zaloguj'}
            fullWidth={true}
          />
        )
      }
      onSubmit={onSubmit}
      {...otherProps}
    />
  )
}

export default Uniform
