// @HACK
// this is the content of https://github.com/directus/directus/blob/main/packages/sdk-js/src/handlers/auth.ts file
// we must change its behaviour to:
// - do not send `Authorization` header with this request as it broke it returning 401

// @ts-ignores are added to allow overwriting private properties

import axios from 'axios'
import DirectusSDK from '@directus/sdk-js'
import { LoginCredentials, AuthResponse } from '@directus/sdk-js/dist/types/handlers'

const login = async function(credentials: LoginCredentials): Promise<{ data: AuthResponse }> {
  // @ts-ignore
  this.removeTimeout()

  // @HACK this is main part of the hack - use bare axios to strip unnecessary authorization headers
  // @ts-ignore
  const response = await axios.post(this.axios.defaults.baseURL + '/auth/login', { ...credentials, mode: this.mode })

  const data = response.data.data
  // @ts-ignore
  this.token = data.access_token
  // @ts-ignore
  this.expiresAt = Date.now() + data.expires

  // @ts-ignore
  await this.storage.setItem('directus_access_token', this.token)
  // @ts-ignore
  await this.storage.setItem('directus_access_token_expires', this.expiresAt)

  // @ts-ignore
  if (this.mode === 'json') {
    // @ts-ignore
    await this.storage.setItem('directus_refresh_token', data.refresh_token)
  }
  // @ts-ignore
  if (this.autoRefresh) {
    // @ts-ignore
    this.refresh(true)
  }

  return response.data
}

export const addCustomLogin = (directus: DirectusSDK): void => {
  directus.auth.login = login
}
