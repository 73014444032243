/* eslint-disable max-len */
import React from 'react'

import Snackbars from 'components/atoms/Snackbars'

import Loader from 'components/molecules/Loader'

import { SponsorContactFormModel } from 'components/organisms/FormSponsor'

import PageBecomeSponsorView from 'domains/main/views/PageBecomeSponsorView'

import { useContactCooperation } from 'domains/main/api/app'

import { schema } from './sponsorSchema'

const sponsorCardText = `Zostając patronem naszego konkursu, masz gwarancję efektywnej reklamy i szerokiej promocji Twojego produktu lub usługi.
Możemy stworzyć dla Ciebie spersonalizowany konkurs, dedykowany wyłącznie Twojej marce.
Jeśli jesteś zainteresowany podjęciem z nami współpracy i chcesz, aby Twój produkt był nagrodą w naszym konkursie, skontaktuj się z nami używając przygotowanego niżej formularza.`

export const PageBecomeSponsor = () => {

  const [useContactState, contact] = useContactCooperation()
  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(null)

  const onFormSubmit = React.useCallback(async (data: SponsorContactFormModel) => {
    await contact(data)
    setSnackbarMessage('Wysłano wiadomość! Dziękujemy za kontakt!')
  }, [contact])

  return (
    <Loader
      doNotUnmountChildren={true}
      opacity={0.8}
      loading={useContactState.loading}
      error={useContactState.error}
      cancelable={true}
    >
      <PageBecomeSponsorView
        becomeSponsorCardProps={{
          title: 'Stwórz swój własny Quiz!',
          subtitle: 'Współpraca z Quizi:',
          text: sponsorCardText,
          showButton: false,
          endingText: ' Quizi to niepowtarzalne miejsce na aktywną i nietuzinkową reklamę Twojej marki!',
        }}
        formSponsorProps={{
          title: 'Chcesz dowiedzieć się więcej?',
          subtitle: 'Odpowiemy na wszystkie pytania!',
          sponsorFromSchema: schema,
          onFormSubmit,
        }}
      />
      <Snackbars
        snackbarMessage={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
      />
    </Loader>
  )
}

export default PageBecomeSponsor
