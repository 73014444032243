export const makeSchema = (availableCategories: { label: string, value: string }[]) => {
  return {
    title: 'Search',
    type: 'object',
    properties: {
      name: {
        type: 'string',
        label: '',
      },
      category: {
        type: 'string',
        label: '',
        default: '0',
        options: availableCategories,
      },
    },
  }
}
