import React from 'react'

import { makeStyles, Theme, Chip as MuiChip, ChipProps as MuiChipProps } from '@material-ui/core'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ChipProps extends MuiChipProps {
}

const useStyles = makeStyles<Theme, ChipProps>((theme) => ({
  root: {},
  tokens: {
    transform: 'scale(1.7)',
    padding: 5,
  },
}), { name: 'Chip' } )

export const Chip = (props: ChipProps) => {
  const classes = useStyles(props)
  const { ...otherProps } = props

  return (
    <MuiChip
      classes={{ iconColorSecondary: classes.tokens }}
      {...otherProps}
    />
  )
}

export default Chip
