import PageNotFound from '../PageNotFound'
import PageLogin from '../PageLogin'
import PageHome from '../PageHome'
import PageShop from '../PageShop'

import PageProfile from '../PageProfile'
import PageTransactionsHistory from '../PageTransactionsHistory'
import PageAddQuestion from '../PageAddQuestion'
import PageQuizzes from '../PageQuizzes'
import PageBecomeSponsor from '../PageBecomeSponsor'
import PageQuiz from '../PageQuiz'
import PageQuizQuestion from '../PageQuizQuestion'
import PageHowItWorks from '../PageHowItWorks'
import PageContact from '../PageContact'
import PageRegulations from '../PageRegulations'
import PageRegister from '../PageRegister'
import PageForgotRegister from '../PageForgotRegister'
import PagePaymentSuccess from '../PagePaymentSuccess'

import { makeUseRouteTo, IRouteEnhanced, IRoute404 } from 'components/atoms/Router'

import { QuizStatus } from 'domains/main/api/quizzes'
import PagePaymentFailed from '../PagePaymentFailed'

const enhanceRoute = makeUseRouteTo

type IRouteEnhanceRouterShell = IRouteEnhanced<{ isLoggedIn: boolean }>

export const routeHome: IRouteEnhanceRouterShell = enhanceRoute({
  exact: true,
  path: '/',
  makePath: () => '/',
  component: PageHome,
})

export const routeLogin: IRouteEnhanceRouterShell = enhanceRoute({
  exact: true,
  path: '/login',
  makePath: () => '/login',
  component: PageLogin,
})

export const routeShop: IRouteEnhanceRouterShell = enhanceRoute({
  filter: ({ isLoggedIn }) => isLoggedIn,
  exact: true,
  path: '/shop',
  makePath: () => '/shop',
  component: PageShop,
})


export const routePaymentSuccess: IRouteEnhanceRouterShell = enhanceRoute({
  filter: ({ isLoggedIn }) => isLoggedIn,
  exact: true,
  path: '/payment-success/:id',
  makePath: () => '/payment-success',
  component: PagePaymentSuccess,
})

export const routePaymentFailed: IRouteEnhanceRouterShell = enhanceRoute({
  filter: ({ isLoggedIn }) => isLoggedIn,
  exact: true,
  path: '/payment-failed',
  makePath: () => '/payment-failed',
  component: PagePaymentFailed,
})

export const routeProfile: IRouteEnhanceRouterShell = enhanceRoute({
  filter: ({ isLoggedIn }) => isLoggedIn,
  exact: true,
  path: '/profile',
  makePath: () => '/profile',
  component: PageProfile,
})

export const routeTransactionsHistory: IRouteEnhanceRouterShell = enhanceRoute({
  filter: ({ isLoggedIn }) => isLoggedIn,
  exact: true,
  path: '/transactions-history',
  makePath: () => '/transactions-history',
  component: PageTransactionsHistory,
})

export const routeAddQuestion: IRouteEnhanceRouterShell = enhanceRoute({
  filter: ({ isLoggedIn }) => isLoggedIn,
  exact: true,
  path: '/add-question',
  makePath: () => '/add-question',
  component: PageAddQuestion,
})

export const routeBecomeSponsor: IRouteEnhanceRouterShell = enhanceRoute({
  exact: true,
  path: '/become-sponsor',
  makePath: () => '/become-sponsor',
  component: PageBecomeSponsor,
})

export const routeQuizzes: IRouteEnhanceRouterShell = enhanceRoute({
  exact: true,
  path: '/quizzes/:status',
  makePath: (status: QuizStatus) => '/quizzes/' + status,
  component: PageQuizzes,
})

export const routeQuiz: IRouteEnhanceRouterShell = enhanceRoute({
  exact: true,
  path: '/quizzes/:quizUuid/overview',
  makePath: (quizUuid: string) => `/quizzes/${quizUuid}/overview`,
  component: PageQuiz,
})

export const routeQuizQuestion: IRouteEnhanceRouterShell = enhanceRoute({
  filter: ({ isLoggedIn }) => isLoggedIn,
  exact: true,
  path: '/quizzes/:quizUuid/question',
  makePath: (quizUuid: string) => `/quizzes/${quizUuid}/question`,
  component: PageQuizQuestion,
})

export const routeHowItWorks: IRouteEnhanceRouterShell = enhanceRoute({
  exact: true,
  path: '/how-it-works',
  makePath: () => '/how-it-works',
  component: PageHowItWorks,
})

export const routeContact: IRouteEnhanceRouterShell = enhanceRoute({
  exact: true,
  path: '/contact',
  makePath: () => '/contact',
  component: PageContact,
})

export const routeRegulations: IRouteEnhanceRouterShell = enhanceRoute({
  exact: true,
  path: '/regulations',
  makePath: () => '/regulations',
  component: PageRegulations,
})

export const routeRegister: IRouteEnhanceRouterShell = enhanceRoute({
  filter: ({ isLoggedIn }) => !isLoggedIn,
  exact: true,
  path: '/register',
  makePath: () => '/register',
  component: PageRegister,
})

export const routeForgotPassword: IRouteEnhanceRouterShell = enhanceRoute({
  exact: true,
  path: '/forgot-password',
  makePath: () => '/forgot-password',
  component: PageForgotRegister,
})

export const routeNotFound: IRoute404 = {
  component: PageNotFound,
}

export type RoutesGeneric<T extends unknown[]> = [...T, IRoute404]
export type Routes = RoutesGeneric<IRouteEnhanced[]>

export const routes: Routes = [
  routeHome,
  routeLogin,
  routeShop,
  routePaymentSuccess,
  routePaymentFailed,
  routeProfile,
  routeTransactionsHistory,
  routeAddQuestion,
  routeBecomeSponsor,
  routeQuizzes,
  routeQuiz,
  routeQuizQuestion,
  routeHowItWorks,
  routeContact,
  routeRegulations,
  routeRegister,
  routeForgotPassword,
  routeNotFound,
]

export default routes
