import React from 'react'

import { Typography, Theme, Grid, makeStyles } from '@material-ui/core'

import Button from 'components/atoms/Button'
import Card from 'components/atoms/Card'

import PageLayout from 'components/templates/PageLayout'

import { routeContact, routeHome } from 'domains/main'

import { regulationsText } from './regulationsText'

const useStyles = makeStyles<Theme>((theme) => ({
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
  regLink: {
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'none',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    width: '90%',
  },
}))

export const PageRegulations = () => {
  const classes = useStyles()

  const routeToContact = routeContact.useRouteTo()
  const routeToHome = routeHome.useRouteTo()

  return (
    <PageLayout>
      <Card
        mode={'white'}
      >
        <Typography
          variant={'h1'}
          color={'secondary'}
          align={'center'}
          className={classes.shadow}
        >
          Regulamin
        </Typography>
        <Typography
          variant={'body1'}
          align={'left'}
          gutterBottom={true}
        >
          {regulationsText}
        </Typography>
        <Typography
          variant={'body1'}
          color={'secondary'}
          gutterBottom={true}
          align={'center'}
        >
          Regulamin oraz polityka prywatności dostępna do pobrania:
        </Typography>
        <a
          href={'https://regulamin.quizi.pl/'}
          target={'_blank'}
          rel={'noreferrer'}
          className={classes.regLink}
        >
          <Button
            className={classes.regButton}
            label={'Pobierz'}
          />
        </a>
        <Grid
          container
        >
          <Grid
            item
            classes={{ root: classes.buttons }}
            xs={6}
          >
            <Button
              classes={{ root: classes.button }}
              label={'Kontakt'}
              color={'secondary'}
              onClick={routeToContact}
            />
          </Grid>
          <Grid
            item
            xs={6}
            classes={{ root: classes.buttons }}
          >
            <Button
              classes={{ root: classes.button }}
              label={'Strona główna'}
              color={'secondary'}
              onClick={routeToHome}
            />
          </Grid>
        </Grid>
      </Card >
    </PageLayout>
  )
}

export default PageRegulations
