import React from 'react'

import { Typography, makeStyles, Theme } from '@material-ui/core'

import Card from 'components/atoms/Card'
import Button from 'components/atoms/Button'
import QuiziLogo from 'components/icons/QuiziLogo'

export interface CallToAction {
  header: string,
  subheader: string,
  tokenAmount: number,
  textBeforeButtons: string,
}

interface CallToActionLoggedIn {
  header: string,
  subheader: string,
}

export interface WelcomeCardProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  firstSubtitle: string,
  secondSubtitle: string,
  appImg: string,
  callToAction: CallToAction,
  callToActionLoggedIn: CallToActionLoggedIn,
  routes: {
    routeToLogin: () => void,
    routeToQuizzes: () => void,
  },
  logInByGoogle: () => void,
  logInByFacebook: () => void,
}

const useStyles = makeStyles<Theme, WelcomeCardProps>((theme) => ({
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
  heroMain: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  heroImageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  heroImage: {
    maxWidth: '300px',
    maxHeight: '400px',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '200px',
      maxHeight: '300px',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  icon: {
    fill: theme.palette.secondary.main,
    height: '100%',
    width: 35,
    margin: theme.spacing(1.5),
    marginLeft: 0,
  },
  heroLogin: {
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  buttonsHalf: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonHalf: {
    width: '48%',
  },
  buttonFull: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  tokens: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  heroText: {
    width: '100%',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  heroTextSet: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  tokenIcon: {
    transform: 'scale(1.2)',
    marginLeft: theme.spacing(1.5),
    filter: 'drop-shadow(1px 1px 3px #225998)',
  },
}), { name: 'WelcomeCard' })

export const WelcomeCard = (props: WelcomeCardProps) => {
  const classes = useStyles(props)

  const {
    title,
    firstSubtitle,
    secondSubtitle,
    appImg,
    callToAction,
    routes,
    logInByGoogle,
    logInByFacebook,
  } = props

  const {
    header,
    subheader,
    tokenAmount,
    textBeforeButtons,
  } = callToAction

  const {
    routeToLogin,
  } = routes

  return (
    <Card
      mode={'white'}
    >
      <div>
        <Typography
          color={'textPrimary'}
          variant={'h1'}
          className={classes.shadow}
        >
          {title}
        </Typography>
        <Typography
          color={'textPrimary'}
          variant={'h5'}
          className={classes.shadow}
        >
          {firstSubtitle}
        </Typography>
        <Typography
          color={'textPrimary'}
          variant={'h5'}
          className={classes.shadow}
        >
          {secondSubtitle}
        </Typography>
      </div>
      <div
        className={classes.heroMain}
      >
        <div
          className={classes.heroImageContainer}
        >
          <img
            className={classes.heroImage}
            src={appImg}
            alt={appImg}
          />
        </div>
        <div
          className={classes.heroLogin}
        >
          <div>
            <Typography
              color={'textPrimary'}
              variant={'h2'}
              align={'center'}
              className={classes.shadow}
            >
              {header}
            </Typography>
            <Typography
              color={'textPrimary'}
              variant={'h5'}
              align={'center'}
            >
              {subheader}
            </Typography>
            <div
              className={classes.tokens}
            >
              <Typography
                color={'textPrimary'}
                variant={'h1'}
                align={'center'}
                className={classes.shadow}
              >
                {tokenAmount}
              </Typography>
              <QuiziLogo
                className={classes.tokenIcon}
              />
            </div>
            <Typography
              color={'textPrimary'}
              variant={'h5'}
              align={'center'}
            >
              {textBeforeButtons}
            </Typography>
            <div>
              <div
                className={classes.buttonsHalf}
              >
                <Button
                  className={classes.buttonHalf}
                  mode={'facebook'}
                  label={'Facebook'}
                  onClick={logInByFacebook}
                />
                <Button
                  className={classes.buttonHalf}
                  mode={'google'}
                  label={'Google'}
                  onClick={logInByGoogle}
                />
              </div>
              <Button
                className={classes.buttonFull}
                label={'Logowanie/Rejestracja'}
                fullWidth={true}
                onClick={routeToLogin}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default WelcomeCard
