import React from 'react'

import Uniform from '../Uniform'
import { makeSchema as makeSearchSchema } from './searchSchema'

export interface SortingQuizzesListProps extends React.HTMLProps<HTMLDivElement> {
  availableCategories: { label: string, value: string }[],
  mapCategoriesFromQuizzes: () => void,
  setFilters: React.Dispatch<React.SetStateAction<{ category?: string | undefined, name?: string | undefined }>>,
}

export const SortingQuizzesList = (props: SortingQuizzesListProps) => {
  const {
    mapCategoriesFromQuizzes,
    setFilters,
    availableCategories,
    ...otherProps
  } = props

  React.useEffect(() => {
    mapCategoriesFromQuizzes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      {...otherProps}
    >
      <Uniform
        onChangeModel={(data: { category?: string, name?: string }) => setFilters(data)}
        schema={makeSearchSchema(availableCategories)}
        submitField={() => null}
      />
    </div>
  )
}

export default SortingQuizzesList
