import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { QuizLeader } from './types'

export const getQuizRanking = async (quizUuid: string): Promise<QuizLeader[]> => {
  const response = await fetchCustomEndpoint<QuizLeader[]>({
    url: `/quizzes/${quizUuid}/ranking`,
  })

  const data: QuizLeader[] = response.data

  return data
}

export const useGetQuizRanking = () => {
  return useAsyncFn(getQuizRanking)
}
