import React from 'react'

import { formatDateTime } from 'utils/formatDateTime'

import Loader from 'components/molecules/Loader'

import PageTransactionsHistoryView from 'domains/main/views/PageTransactionsHistoryView'

import { useFindAllCoinTransactions } from 'domains/main/api/coinTransaction/findAllCoinTransactions'

export const PageTransactionsHistory = () => {
  const [coinTransactionsState, findAllCoinTransactions] = useFindAllCoinTransactions()
  React.useEffect(() => {
    findAllCoinTransactions()
  // only for the first mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const transactions = React.useMemo(() => {
    return coinTransactionsState?.value?.coinTransactions?.map((coinTransaction) => {
      return {
        id: coinTransaction.id || '',
        date: coinTransaction?.dateCreated ? formatDateTime(coinTransaction?.dateCreated) : '',
        quizTitle: '',
        amount: coinTransaction?.amount,
        initiator: coinTransaction?.initiator,
      }
    })
  }, [coinTransactionsState?.value?.coinTransactions])

  return (
    <Loader
      loading={coinTransactionsState.loading}
      error={coinTransactionsState.error}
      retry={findAllCoinTransactions}
    >
      {
        transactions ?
          <PageTransactionsHistoryView
            title={'Historia transakcji'}
            subtitle={'Sprawdź swoje ostatnie zakupy!'}
            transactions={transactions}
          />
          :
          null
      }
    </Loader>
  )
}

export default PageTransactionsHistory
