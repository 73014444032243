import React from 'react'
import classnames from 'classnames'

import { makeStyles, Theme, Button as MuiButton, ButtonProps as MUIButtonProps } from '@material-ui/core'

export type ButtonNode = 'default' | 'cancel' | 'quizCard' | 'heroCard' | 'facebook' | 'green' | 'google' | 'tokens'

export interface ButtonProps extends MUIButtonProps {
  label: React.ReactNode,
  mode?: ButtonNode,
}

const useStyles = makeStyles<Theme, ButtonProps>((theme) => ({
  cancel: {
    'backgroundColor': theme.palette.warning.main,
    '&:hover': {
      background: '#cc413f',
    },
  },
  default: {},
  quizCard: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '200px',
  },
  heroCard: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '200px',
  },
  facebook: {
    'backgroundColor': '#3b5998',
    '&:hover': {
      background: '#374f82',
    },
  },
  green: {
    'backgroundColor': '#4DCDB4',
    '&:hover': {
      background: '#41ab96',
    },
  },
  google: {
    'backgroundColor': '#FF3E30',
    '&:hover': {
      background: '#E72F3A',
    },
  },
  tokens: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
}), { name: 'Button' } )

export const Button = (props: ButtonProps) => {
  const classes = useStyles(props)
  const {
    mode = 'default',
    label,
    className,
    ...otherProps
  } = props

  return (
    <MuiButton
      className={classnames([
        classes[mode],
        className,
      ])}
      {...otherProps}
    >
      {label}
    </MuiButton>
  )
}

export default Button
