import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'

import Cup from 'components/icons/Cup'
import FinishFlag from 'components/icons/FinishFlag'
import Upcoming from 'components/icons/Upcoming'
import Lock from 'components/icons/Lock'

import Chip from 'components/atoms/Chip'

export type ChipQuizMode = 'upcoming' | 'ended' | 'running'

export interface ChipQuizProps extends React.HTMLProps<HTMLDivElement> {
  topChipLabel: React.ReactNode,
  bottomChipLabel: React.ReactNode,
  mode: ChipQuizMode,
  isLocked: boolean,
}

const useStyles = makeStyles<Theme, ChipQuizProps>((theme) => ({
  cup: {
    fill: theme.palette.secondary.main,
  },
}), { name: 'ChipQuiz' } )

export const ChipQuiz = (props: ChipQuizProps) => {
  const classes = useStyles(props)
  const {
    topChipLabel,
    bottomChipLabel,
    mode,
    isLocked,
  } = props

  return (
    <>
      <Chip
        label={topChipLabel}
        icon={
          mode === 'ended' ?
            <FinishFlag /> :
            mode === 'upcoming' ?
              <Upcoming />
              :
              <HourglassEmptyIcon />
        }
      />
      {
        isLocked ?
          <Chip
            label={'Konkurs specjalny'}
            icon={<Lock />}
          />
          :
          mode === 'ended' ?
            <Chip
              label={bottomChipLabel}
              icon={
                <Cup
                  className={classes.cup}
                />
              }
            />
            :
            null
      }
    </>
  )
}

export default ChipQuiz
