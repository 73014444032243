import React from 'react'

import { Typography, makeStyles, Theme } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'

import Card from 'components/atoms/Card'
import Button from 'components/atoms/Button'

import Uniform from 'components/molecules/Uniform'

export interface FormContactModel {
  email: string,
  firstName: string,
  lastName: string,
  message: string,
}

export interface FormContactProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  subtitle: string,
  contactFormSchema: Record<string, any>,
  onFormSubmit: (formValues: FormContactModel) => void,
  onFbButtonClick: () => void,
}

const useStyles = makeStyles<Theme, FormContactProps>((theme) => ({
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
  fb: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}), { name: 'FormContact' })


export const FormContact = (props: FormContactProps) => {
  const classes = useStyles(props)

  const {
    title,
    subtitle,
    contactFormSchema,
    onFormSubmit,
    onFbButtonClick,
  } = props

  return (
    <Card
      mode={'white'}
    >
      <Typography
        variant={'h3'}
        align={'left'}
        className={classes.shadow}
      >
        {title}
      </Typography>
      <Typography
        variant={'h5'}
        align={'left'}
      >
        {subtitle}
      </Typography>
      <Button
        fullWidth={true}
        className={classes.fb}
        label={'Znajdź nas na facebooku'}
        endIcon={<FacebookIcon />}
        onClick={onFbButtonClick}
      />
      <Uniform
        schema={contactFormSchema}
        onSubmit={(model: FormContactModel) => onFormSubmit(model)}
        submitField={() => (
          <Button
            type={'submit'}
            label={'Wyślij'}
            fullWidth={true}
          />
        )}
      />
    </Card>
  )
}

export default FormContact
