import React from 'react'

import { Grid } from '@material-ui/core'

import PageLayout from 'components/templates/PageLayout'

import WelcomeCard, { WelcomeCardProps } from 'components/organisms/WelcomeCard'
import WelcomeCardLoggedIn, { WelcomeCardLoggedInProps } from 'components/organisms/WelcomeCardLoggedIn'
import HowItWorksCard, { HowItWorksCardProps } from 'components/organisms/HowItWorksCard'
import BecomeSponsorCard, { BecomeSponsorCardProps } from 'components/organisms/BecomeSponsorCard'

export interface Routes {
  quizzes: () => void,
  howItWorks: () => void,
}

export interface PageHomeViewProps extends React.HTMLProps<HTMLDivElement> {
  welcomeCardProps: WelcomeCardProps,
  welcomeCardLoggedInProps: WelcomeCardLoggedInProps,
  howItWorksCardProps: HowItWorksCardProps,
  becomeSponsorCardProps: BecomeSponsorCardProps,
  isLoggedIn: boolean,
}

export const PageHomeView = (props: PageHomeViewProps) => {
  const {
    welcomeCardProps,
    howItWorksCardProps,
    becomeSponsorCardProps,
    welcomeCardLoggedInProps,
    isLoggedIn,
  } = props

  return (
    <PageLayout>
      <Grid container>
        <Grid
          item
          xs={12}
        >
          {
            isLoggedIn ?
              <WelcomeCardLoggedIn
                {...welcomeCardLoggedInProps}
              />
              :
              <WelcomeCard
                {...welcomeCardProps}
              />
          }
        </Grid>

        <Grid
          item
          xs={12}
        >
          <HowItWorksCard
            {...howItWorksCardProps}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <BecomeSponsorCard
            {...becomeSponsorCardProps}
          />
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default PageHomeView
