import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { SuggestQuestionData, SuggestQuestionResponse } from './types'

export const suggestQuestion = async (payload: SuggestQuestionData): Promise<SuggestQuestionResponse> => {
  const formData = new FormData()
  payload.media && formData.append('media', payload.media)
  payload.video && formData.append('video', payload.video)
  formData.append('question', payload.question)
  formData.append('answers', payload.answers)
  formData.append('category', payload.category)

  const response = await fetchCustomEndpoint<SuggestQuestionResponse>({
    url: '/questions/suggest',
    method: 'POST',
    data: formData,
  })

  const data: SuggestQuestionResponse = response.data

  return data
}

export const useSuggestQuestion = () => {
  return useAsyncFn(suggestQuestion)
}
