import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { Category } from './types'

export const findAllCategories = async (): Promise<Category[]> => {
  const response = await fetchCustomEndpoint<Category[]>({
    url: '/categories',
  })

  const data: Category[] = response.data

  return data
}

export const useFindAllCategories = () => {
  return useAsyncFn(findAllCategories)
}
