import React from 'react'

import { makeStyles, Theme, Typography } from '@material-ui/core'

import Cup from 'components/icons/Cup'

import Button from 'components/atoms/Button'
import Card from 'components/atoms/Card'

export interface QuestionsExceededMessageProps extends React.HTMLProps<HTMLDivElement> {
  onClickSuggestAQuestion: () => void,
}

const useStyles = makeStyles<Theme, QuestionsExceededMessageProps>((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  text: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  iconWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    width: 40,
    height: 40,
    marginBottom: theme.spacing(1),
    fill: theme.palette.primary.main,
  },
  button: {
    marginBottom: theme.spacing(1),
  },
  buttonLabel: {
    fontSize: 16,
  },
}), { name: 'QuestionsExceededMessage' } )

export const QuestionsExceededMessage = (props: QuestionsExceededMessageProps) => {
  const classes = useStyles(props)
  const {
    onClickSuggestAQuestion,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Card
        mode={'darkBlue'}
      >
        <Typography
          variant={'h3'}
          align={'center'}
          className={classes.text}
        >
          Gratulacje!
        </Typography>
        <Typography
          variant={'body1'}
          align={'center'}
          className={classes.text}
        >
          Wyczerpałeś pulę dostępnych pytań dla tego konkursu!
        </Typography>
        <div
          className={classes.iconWrapper}
        >
          <Cup
            className={classes.icon}
          />
        </div>
        <Typography
          variant={'body1'}
          align={'center'}
          className={classes.text}
        >
          Zabrakło Ci jakiegoś pytania? Daj nam znać i weź udział w konkursie dla autorów pytań!
        </Typography>
        <Button
          className={classes.button}
          classes={{ label: classes.buttonLabel }}
          color={'primary'}
          label={'ZAPROPONUJ SWOJE PYTANIE!'}
          onClick={onClickSuggestAQuestion}
          fullWidth={true}
        />
        <Typography
          variant={'body2'}
          align={'center'}
          className={classes.text}
        >
          Kolejne wyzwanie? ;)
        </Typography>
      </Card>
    </div>
  )
}

export default QuestionsExceededMessage
