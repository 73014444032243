import React from 'react'

import { Typography, Dialog, makeStyles, Theme, Fab } from '@material-ui/core'
import { Search } from '@material-ui/icons'

import SortingQuizzesList from 'components/molecules/SortingQuizzesList'
import Button from 'components/atoms/Button'

export interface QuizFilterDialogProps extends React.HTMLProps<HTMLDivElement> {
  open: boolean,
  availableCategories: { label: string, value: string }[],
  mapCategoriesFromQuizzes: () => void,
  setFilters: React.Dispatch<React.SetStateAction<{ category?: string | undefined, name?: string | undefined }>>,
  setIsDialogOpen: (isOpen: boolean) => void,
}

const useStyles = makeStyles<Theme, QuizFilterDialogProps>((theme) => ({
  root: {
    padding: 20,
    width: '80%',
    backgroundColor: theme.palette.secondary.main,
  },
  fab: {
    'position': 'fixed',
    'color': 'white',
    'bottom': 20,
    'right': 20,
    'backgroundColor': theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  whiteText: {
    color: 'white',
  },
  buttonClose: {
    marginTop: theme.spacing(1),
  },
}), { name: 'QuizFilterDialog' })

export const QuizFilterDialog = (props: QuizFilterDialogProps) => {
  const classes = useStyles(props)
  const {
    mapCategoriesFromQuizzes,
    setFilters,
    availableCategories,
    open,
    setIsDialogOpen,
  } = props

  const [width, setWidth] = React.useState<number>(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile: boolean = (width <= 768)

  return (
    <>
      <Fab
        className={classes.fab}
        onClick={() => setIsDialogOpen(!open)}
        size={isMobile ? 'small' : 'large'}
      >
        <Search />
      </Fab>
      <Dialog
        PaperProps={{ className: classes.root }}
        open={open}
        onBackdropClick={() => setIsDialogOpen(!open)}
      >
        <Typography
          variant={'h3'}
          align={'left'}
          color={'primary'}
          className={classes.whiteText}
          gutterBottom={true}
        >
          Wyszukaj Quiz!
        </Typography>
        <SortingQuizzesList
          mapCategoriesFromQuizzes={mapCategoriesFromQuizzes}
          setFilters={setFilters}
          availableCategories={availableCategories}
        />
        <Button
          className={classes.buttonClose}
          label={'Zamknij'}
          onClick={() => setIsDialogOpen(false)}
          fullWidth={true}
        />
      </Dialog>
    </>
  )
}

export default QuizFilterDialog
