// @HACK
// this is the content of https://github.com/directus/directus/blob/main/packages/sdk-js/src/handlers/auth.ts file
// we must change its behaviour to:
// - not skip the request when token is not expired as we want to user refresh to check if user is already logged in
// - do not send `Authorization` header with this request as it broke it returning 401

// @ts-ignores are added to allow overwriting private properties

import axios from 'axios'
import DirectusSDK from '@directus/sdk-js'
import { AuthHandler, AuthResponse } from '@directus/sdk-js/dist/types/handlers'

const refresh = async function refresh(this: AuthHandler): Promise<{ data: AuthResponse } | undefined> {
  // @ts-ignore
  this.removeTimeout()

  // @ts-ignore
  this.expiresAt = await this.storage.getItem('directus_access_token_expires')

  // @HACK this is 1/3 part of this hack
  // @ts-ignore
  // if (!this.expiresAt) return

  // @ts-ignore
  if (Date.now() + 10000 < this.expiresAt && this.autoRefresh) {
    // @ts-ignore
    this.autoRefreshTimeout = setTimeout(() => this.refresh(), this.expiresAt - Date.now() - 10000)

    // @HACK this is 2/3 part of this hack
    // return
  }

  // @ts-ignore
  const payload: Record<string, any> = { mode: this.mode }

  // @ts-ignore
  if (this.mode === 'json') {
    // @ts-ignore
    const refreshToken = await this.storage.getItem('directus_refresh_token')
    // @ts-ignore
    // eslint-disable-next-line camelcase
    payload.refresh_token = refreshToken
  }

  // @ts-ignore
  if (this.expiresAt < Date.now() + 1000) {
    this.token = null
  }

  // @HACK this is 3/3 part of this hack - use bare axios to strip unnecessary authorization headers
  // @ts-ignore
  const response = await axios.post<{ data: AuthResponse }>(this.axios.defaults.baseURL + '/auth/refresh', payload)

  const data = response.data.data
  // @ts-ignore
  this.token = data.access_token
  // @ts-ignore
  this.expiresAt = Date.now() + data.expires
  // @ts-ignore
  await this.storage.setItem('directus_access_token', this.token)
  // @ts-ignore
  await this.storage.setItem('directus_access_token_expires', this.expiresAt)

  // @ts-ignore
  if (this.mode === 'json') {
    // @ts-ignore
    await this.storage.setItem('directus_refresh_token', response.data.data.refresh_token)
  }

  // @ts-ignore
  if (this.autoRefresh) {
    // @ts-ignore
    this.autoRefreshTimeout = setTimeout(() => this.refresh(), data.expires - 10000)
  }
  return response.data
}

export const addCustomRefresh = (
  directus: DirectusSDK,
  { autoRefresh = false }: { autoRefresh: boolean }
): void => {
  // @ts-ignore
  directus.auth.autoRefresh = autoRefresh
  directus.auth.refresh = refresh
}
