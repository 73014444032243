/**
 * @HACK
 * Create React App do not allow to build the application with other `NODE_ENV` than `production`
 * JSS class name generation depends on that environment variable
 * we need to build application with `development` class names to allow E2E test to use them as components handles
 * so depending on `REACT_APP_ENV` value (that we can change) in `development` or `testing`
 * we using modified version of `createGenerateClassName` that always produces long class names
 */

import { createGenerateClassName as originalCreateGenerateClassName } from '@material-ui/core'

const provideFullClassNames = (window._env_.REACT_APP_ENV && ['development', 'testing'].includes(window._env_.REACT_APP_ENV)) ? true : false

export default provideFullClassNames ? createGenerateClassName : originalCreateGenerateClassName

/**
 * This is the content of `nested.js` file from Material UI library
 * https://github.com/mui-org/material-ui/blob/f232781d59531113f53742adaaee36a9bc574027/packages/material-ui-styles/src/ThemeProvider/nested.js
 */

const hasSymbol = typeof Symbol === 'function' && Symbol.for

const nested = hasSymbol ? Symbol.for('mui.nested') : '__THEME_NESTED__'

/**
 * END OF the content of `nested.js` file from Material UI library
 */

/**
 * This is the content of `createGenerateClassName.js` file from Material UI library
 * https://github.com/mui-org/material-ui/blob/f232781d59531113f53742adaaee36a9bc574027/packages/material-ui-styles/src/createGenerateClassName/createGenerateClassName.js
 * with 2 lines commented (mentioned with @HACK comment)
 */

/**
 * This is the list of the style rule name we use as drop in replacement for the built-in
 * pseudo classes (:checked, :disabled, :focused, etc.).
 *
 * Why do they exist in the first place?
 * These classes are used at a specificity of 2.
 * It allows them to override previously defined styles as well as
 * being untouched by simple user overrides.
 */
const pseudoClasses = [
  'checked',
  'disabled',
  'error',
  'focused',
  'focusVisible',
  'required',
  'expanded',
  'selected',
]

// Returns a function which generates unique class names based on counters.
// When new generator function is created, rule counter is reset.
// We need to reset the rule counter for SSR for each request.
//
// It's inspired by
// https://github.com/cssinjs/jss/blob/4e6a05dd3f7b6572fdd3ab216861d9e446c20331/src/utils/createGenerateClassName.js
function createGenerateClassName(options = {}) {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { disableGlobal = false, productionPrefix = 'jss', seed = '' } = options
  const seedPrefix = seed === '' ? '' : `${seed}-`
  let ruleCounter = 0

  const getNextCounterId = () => {
    ruleCounter += 1
    if (process.env.NODE_ENV !== 'production') {
      if (ruleCounter >= 1e10) {
        console.warn(
          [
            'Material-UI: You might have a memory leak.',
            'The ruleCounter is not supposed to grow that much.',
          ].join('')
        )
      }
    }
    return ruleCounter
  }

  // @ts-ignore
  return (rule, styleSheet) => {
    const name = styleSheet.options.name

    // Is a global static MUI style?
    if (name && name.indexOf('Mui') === 0 && !styleSheet.options.link && !disableGlobal) {
      // We can use a shorthand class name, we never use the keys to style the components.
      if (pseudoClasses.indexOf(rule.key) !== -1) {
        return `Mui-${rule.key}`
      }

      const prefix = `${seedPrefix}${name}-${rule.key}`

      if (!styleSheet.options.theme[nested] || seed !== '') {
        return prefix
      }

      return `${prefix}-${getNextCounterId()}`
    }

    // @HACK these line are commented out to always provide long class names
    // if (process.env.NODE_ENV === 'production') {
    //   return `${seedPrefix}${productionPrefix}${getNextCounterId()}`
    // }

    const suffix = `${rule.key}-${getNextCounterId()}`

    // Help with debuggability.
    if (styleSheet.options.classNamePrefix) {
      return `${seedPrefix}${styleSheet.options.classNamePrefix}-${suffix}`
    }

    return `${seedPrefix}${suffix}`
  }
}

/**
 * END OF the content of `createGenerateClassName.js` file from Material UI library
 */
