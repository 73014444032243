import React, { Dispatch, SetStateAction } from 'react'

import Button from 'components/atoms/Button'
import Dropzone from 'components/atoms/Dropzone'

import Uniform from 'components/molecules/Uniform'
import VideoPlayer from 'components/molecules/VideoPlayer'
import Loader from 'components/molecules/Loader'

import { Typography, Dialog, makeStyles, Theme } from '@material-ui/core'

import { makeSchema as makeAddFormSchema } from './addQuestionSchema'
import { schema as addMovieSchema } from './addMovieSchema'

export interface QuestionModelRaw {
  answerA: string,
  answerB: string,
  answerC: string,
  answerD: string,
  category: string,
  correctAnswer: number,
  question: string,
}

export interface AddQuestionFormProps {
  suggestQuestionState: {
    loading: boolean,
    error?: Error,
    value?: unknown,
  },
  categories: Category[],
  isModalOpen: boolean,
  closeModal: () => void,
  questionStep: number,
  setQuestionStep: Dispatch<SetStateAction<number>>,
  submitForm: (data: QuestionModelRaw) => void,
  videoLink: string,
  setVideoLink: Dispatch<SetStateAction<string>>,
  photo: File | undefined,
  setPhoto: Dispatch<SetStateAction<File | undefined>>,
}

const useStyles = makeStyles<Theme>((theme) => ({
  mainDialogContent: {
    width: '100%',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  loader: {
    minHeight: 0,
  },
  addMediaContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
  addMediaButton: {
    width: '45%',
  },
  cancelButton: {
    background: theme.palette.error.light,
    marginTop: theme.spacing(2),
  },
  acceptMovieButton: {
    marginTop: theme.spacing(2),
  },
}), { name: 'PageAddQuestionView' })

export interface Category {
  id: string,
  name: string,
}

export const AddQuestionForm = (props: AddQuestionFormProps) => {
  const {
    categories,
  } = props
  const classes = useStyles()

  const addFormSchema = React.useMemo(() => makeAddFormSchema(categories.map((category) => ({
    value: category.id,
    label: category.name,
  }))), [categories])

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      open={props.isModalOpen}
    >
      <Loader
        wrapperProps={{
          className: classes.loader,
        }}
        doNotUnmountChildren={true}
        opacity={0.8}
        loading={props.suggestQuestionState.loading}
        error={props.suggestQuestionState.error}
        cancelable={true}
      >
        <div className={classes.mainDialogContent}>
          {props.questionStep === 1 && <>
            <Typography
              variant={'h2'}
              align={'center'}
            >
              Dodaj nowe pytanie
            </Typography>
            <Uniform
              schema={addFormSchema}
              onSubmit={props.submitForm}
              submitField={() => <>
                <div className={classes.addMediaContainer}>
                  <Button
                    className={classes.addMediaButton}
                    label={'Dodaj zdjęcie'}
                    disabled={props.photo || props.videoLink ? true : false}
                    onClick={() => props.setQuestionStep(2)}
                  />
                  <Button
                    className={classes.addMediaButton}
                    disabled={props.photo || props.videoLink ? true : false}
                    label={'Dodaj film'}
                    onClick={() => props.setQuestionStep(3)}
                  />
                </div>
                {
                  (props.photo || props.videoLink) ?
                    <Typography>
                      Zdjęcie lub film jest już dodane
                    </Typography>
                    :
                    null
                }
                <Button
                  fullWidth
                  label={'Prześlij do weryfikacji'}
                  type={'submit'}
                  color={'secondary'}
                />
              </>}
            />
            <Button
              fullWidth
              className={classes.cancelButton}
              onClick={props.closeModal}
              label={'Anuluj'}
            />
          </>
          }
          {
            props.questionStep === 2 &&
            <>
              <Typography
                variant={'h2'}
                align={'center'}
              >
                Zdjęcie do pytania
              </Typography>
              <Dropzone
                submitLabel={'Kontynuuj'}
                onSubmit={(data) => {
                  props.setPhoto(data[0])
                  props.setQuestionStep(1)
                }}
              />
              <Button
                fullWidth
                className={classes.cancelButton}
                onClick={() => { props.setQuestionStep(1)}}
                label={'Anuluj'}
              />
            </>}
          {
            props.questionStep === 3 &&
            <>
              <Typography
                variant={'h2'}
                align={'center'}
              >
                Film do pytania
              </Typography>
              <VideoPlayer
                url={props.videoLink}
                key={props.videoLink}
              />
              <Typography
                align={'center'}
                variant={'body2'}
              >
                WAŻNE! Kliknij "Sprawdź link" zanim zatwierdzisz! Poprawny link powinien zostać wyświetlony powyżej.
              </Typography>
              <Uniform
                schema={addMovieSchema}
                onSubmit={(data) => props.setVideoLink(data.url)}
                submitField={() => (
                  <Button
                    type={'submit'}
                    fullWidth
                    label={'Sprawdź link'}
                  />
                )}
              />
              {
                props.videoLink &&
                <Button
                  fullWidth
                  className={classes.acceptMovieButton}
                  onClick={() => { props.setQuestionStep(1)}}
                  label={'Zatwierdź film'}
                />
              }
              <Button
                fullWidth
                className={classes.cancelButton}
                onClick={() => {
                  props.setQuestionStep(1)
                  props.setVideoLink('')
                }}
                label={'Anuluj'}
              />
            </>
          }
        </div>
      </Loader>

    </Dialog>
  )
}

export default AddQuestionForm
