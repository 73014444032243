import React from 'react'

import { makeStyles, Theme } from '@material-ui/core'
import { Button } from '@material-ui/core'
import Snackbars from 'components/atoms/Snackbars'

const useStyles = makeStyles<Theme>((theme) => ({
  snackbar: {},
}), { name: 'ServiceWorkerInstallSnackbar' } )

const ServiceWorkerInstallSnackbar = () => {
  const classes = useStyles()

  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(null)
  const [beforeInstallEvent, setBeforeInstallEvent] = React.useState<BeforeInstallPromptEvent | null>(null)
  const [promptResult, setPromptResult] = React.useState<{
    outcome: 'accepted' | 'dismissed',
    platform: string,
  } | null>(null)

  const beforeInstallHandler = React.useCallback((event: BeforeInstallPromptEvent) => {
    // if user already answered to the prompt do nothing
    if (promptResult) return
    setBeforeInstallEvent(event)
    setSnackbarMessage('Dodaj aplikacje do ekranu głównego!')
  }, [promptResult])

  const onInstallClick = React.useCallback(async () => {
    if (!beforeInstallEvent) return

    beforeInstallEvent.prompt()

    const result = await beforeInstallEvent.userChoice

    setPromptResult(result)
    setSnackbarMessage(null)
    setBeforeInstallEvent(null)
  }, [beforeInstallEvent])

  React.useEffect(() => {
    window.addEventListener('beforeinstallprompt',  beforeInstallHandler)
    return () => window.removeEventListener('beforeinstallprompt', beforeInstallHandler)
  }, [beforeInstallHandler])

  return (
    <>
      <Snackbars
        className={classes.snackbar}
        lower={true}
        snackbarMessage={snackbarMessage}
        autoHideDuration={null}
        alertProps={{
          severity: 'info',
          action: (
            <Button
              color={'inherit'}
              size={'small'}
              variant={'text'}
              onClick={onInstallClick}
            >
              DODAJ!
            </Button>
          ),
        }}
      />
    </>
  )
}

export default ServiceWorkerInstallSnackbar
