import React from 'react'

import { CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core'

export interface LoadingIndicatorProps extends React.HTMLProps<HTMLDivElement> {
  message?: React.ReactNode,
}

const useStyles = makeStyles<Theme, LoadingIndicatorProps>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}), { name: 'LoadingIndicator' } )

export const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const classes = useStyles(props)
  const {
    message,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <CircularProgress />
      <Typography variant={'caption'}>
        {message}
      </Typography>
    </div>
  )
}

export default LoadingIndicator
