import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { QuizStatus, QuizzesResponseRaw } from './types'
import { convertQuizzes } from './converters'

export const getQuizzes = async (status: QuizStatus) => {
  const response = await fetchCustomEndpoint<QuizzesResponseRaw>({
    url: `/quizzes/${status}`,
  })

  const data: QuizzesResponseRaw = response.data

  return {
    ...data,
    results: convertQuizzes(data.results),
  }
}

export const useGetQuizzes = (deps?: Parameters<typeof useAsyncFn>[1],  initialState?: Parameters<typeof useAsyncFn>[2]) => {
  return useAsyncFn(getQuizzes, deps, initialState)
}
