import React from 'react'

import Loader from 'components/molecules/Loader'
import QuizItem from 'components/molecules/QuizItem'

import QuestionsExceededMessage from 'components/organisms/QuestionsExceededMessage'

import PageLayout from 'components/templates/PageLayout'

import { useGetQuizzes } from 'domains/main/api/quizzes'

import { routeAddQuestion, routeQuiz } from 'domains/main'
import { makeImgSrc } from 'domains/main/api/media'

import { formatDateTime } from 'utils/formatDateTime'
import { makeCategoryName } from 'utils/makeCategoryName'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewQuestionsExceededProps extends React.HTMLProps<HTMLDivElement> {
  quizUuid: string,
}

export const ViewQuestionsExceeded = (props: ViewQuestionsExceededProps) => {
  const {
    quizUuid,
  } = props

  const routeToSuggestAQuestion = routeAddQuestion.useRouteTo()
  const routeToQuiz = routeQuiz.useRouteTo()

  const [quizzesState, getQuizzesByStatus] = useGetQuizzes([], { loading: true })
  const getQuizzes = React.useCallback(() => {
    getQuizzesByStatus('running')
  }, [getQuizzesByStatus])
  React.useEffect(() => {
    getQuizzes()
  }, [getQuizzes])

  const onClickCTAHandler = React.useCallback((newQuizUuid: string) => {
    routeToQuiz(newQuizUuid)
  }, [routeToQuiz])

  const quiz = quizzesState?.value?.results?.filter((newQuiz) => newQuiz.id !== quizUuid)?.[0]

  return (
    <Loader
      loading={quizzesState.loading}
      error={quizzesState.error}
      retry={getQuizzes}
      message={'Ładowanie quizu...'}
    >
      <PageLayout>
        <QuestionsExceededMessage
          onClickSuggestAQuestion={routeToSuggestAQuestion}
        />
        {
          quiz ?
            <QuizItem
              key={quiz.id}
              img={quiz?.prize?.media && makeImgSrc(quiz?.prize?.media)}
              category={makeCategoryName(quiz)}
              title={quiz.name}
              sponsor={quiz.sponsor?.name}
              isLocked={quiz.isLocked}
              isUpcoming={false}
              isEnded={false}
              timeChipLabel={formatDateTime(quiz.endTime)}
              winner={quiz?.winner?.email}
              onClickItem={() => onClickCTAHandler(quiz.id)}
              onClickCTA={() => onClickCTAHandler(quiz.id)}
            />
            :
            null
        }
      </PageLayout>
    </Loader>
  )
}

export default ViewQuestionsExceeded
