import React from 'react'

import { CoinsContext, CoinsContextState } from'./coinsContext'

import { useGetUserBalance } from 'domains/main/api/coinTransactions'

export interface CoinsContextProviderState {
  children: React.ReactNode | ((state: CoinsContextState) => React.ReactNode),
  checkOnMount: boolean,
}

export const CoinsContextProvider = (props: CoinsContextProviderState) => {
  const {
    children,
    checkOnMount = true,
  } = props

  const [getUserBalanceState, getUserBalance] = useGetUserBalance()

  const refreshCoins = React.useCallback(() => {
    return getUserBalance()
  }, [getUserBalance])

  React.useEffect(() => {
    if(checkOnMount) refreshCoins()
  // only for first mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextValue = React.useMemo(() => {
    return {
      coins: getUserBalanceState?.value?.coins ?? null,
      refreshCoins,
    }
  }, [getUserBalanceState?.value?.coins, refreshCoins])

  return (
    <CoinsContext.Provider
      value={contextValue}
    >
      {
        typeof children === 'function' ?
          children(contextValue)
          :
          children
      }
    </CoinsContext.Provider>
  )
}

export default CoinsContextProvider
