import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { ContactResponse, ContactData } from './types'

export const contact = async (data: ContactData): Promise<ContactResponse> => {
  const response = await fetchCustomEndpoint<ContactResponse>({
    url: '/contact',
    method: 'POST',
    data,
  })

  const result: ContactResponse = response.data

  return result
}

export const useContact = () => {
  return useAsyncFn(contact)
}
