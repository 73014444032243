import React from 'react'

import { makeStyles, Theme, Typography, Button } from '@material-ui/core'

export interface PageMaintenanceModeMessageProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  infoText: string,
  comeBackText: string,
  retry: () => void,
}

const useStyles = makeStyles<Theme, PageMaintenanceModeMessageProps>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  status: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  button: {
    marginTop: theme.spacing(2),
  },
}), { name: 'PageMaintenanceModeMessage' })

export const PageMaintenanceModeMessage = (props: PageMaintenanceModeMessageProps) => {
  const classes = useStyles(props)
  const {
    title,
    infoText,
    comeBackText,
    retry,
    ...otherProps
  } = props

  const [width, setWidth] = React.useState<number>(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile: boolean = (width <= 768)

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Typography
        variant={isMobile ? 'h3' : 'h1'}
        align={'center'}
        className={classes.status}
      >
        {title}
      </Typography>
      <Typography
        className={classes.message}
        variant={isMobile ? 'h5' : 'h3'}
        gutterBottom={true}
        align={'center'}
      >
        {infoText}
      </Typography>
      <Typography
        className={classes.message}
        variant={isMobile ? 'h5' : 'h3'}
        align={'center'}
      >
        {comeBackText}
      </Typography>
      <Button
        className={classes.button}
        variant={'text'}
        onClick={retry}
      >
        Sprawdź ponownie
      </Button>
    </div>
  )
}

export default PageMaintenanceModeMessage
