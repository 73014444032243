import React from 'react'

import { Chip } from '@material-ui/core'
import { HourglassEmpty } from '@material-ui/icons'

import Cup from 'components/icons/Cup'
import QuiziLogo from 'components/icons/QuiziLogo'
import FinishFlag from 'components/icons/FinishFlag'
import Upcoming from 'components/icons/Upcoming'

import Button from 'components/atoms/Button'
import Card from 'components/atoms/Card'

import { routeLogin, routeQuizQuestion, routeShop } from 'domains/main'
import { Quiz, QuizLeader } from 'domains/main/api/quizzes'

import { formatDateTime } from 'utils/formatDateTime'

export interface ViewHeaderQuizDetailsProps {
  classes: Record<string, string>,
  details: Quiz,
  ranking: QuizLeader[] | undefined,
  coins: number | null,
  isLoggedIn: boolean,
  openDialog: (v: string) => void,
}

const ViewHeaderQuizDetails = (props: ViewHeaderQuizDetailsProps) => {
  const routeToLogin = routeLogin.useRouteTo()
  const routeToQuizQuestion = routeQuizQuestion.useRouteTo()
  const routeToShop = routeShop.useRouteTo()
  const quizStatus = props.details.quizStatus

  const returnCorrectIconDetermninatedToQuizStatus = React.useCallback(() => {
    if (quizStatus === 'running') {
      return <HourglassEmpty />
    } else if (quizStatus === 'ended') {
      return <FinishFlag />
    } else if (quizStatus === 'upcoming') {
      return <Upcoming />
    } else {
      return <></>
    }
  }, [quizStatus])

  const returnCorrectDateDetermninatedToQuizStatus = React.useCallback(() => {
    if (quizStatus === 'running') {
      return formatDateTime(props.details.endTime)
    } else if (quizStatus === 'ended') {
      return formatDateTime(props.details.endTime)
    } else if (quizStatus === 'upcoming') {
      return formatDateTime(props.details.startTime)
    } else {
      return <></>
    }
  }, [props.details.endTime, props.details.startTime, quizStatus])

  return (
    <Card
      mode={'darkBlue'}
      className={props.classes.card}
    >
      <div className={props.classes.quizHeader}>
        <div className={props.classes.quizHeaderInfo}>

          <Chip
            color={'primary'}
            variant={'outlined'}
            className={props.classes.chip}
            icon={returnCorrectIconDetermninatedToQuizStatus()}
            label={returnCorrectDateDetermninatedToQuizStatus()}
          />
          {
            props.ranking && props.ranking.length && quizStatus === 'ended' &&
            <Chip
              color={'primary'}
              variant={'outlined'}
              icon={<Cup />}
              className={props.classes.chip}
              label={props.ranking[0].email}
            />
          }
          {
            quizStatus !== 'ended' &&
            <Chip
              color={'primary'}
              variant={'outlined'}
              className={props.classes.chip}
              icon={<QuiziLogo />}
              label={props.details.questionPrice}
            />
          }
        </div>
        <div
          className={props.classes.quizHeaderCta}
        >
          {
            !props.isLoggedIn &&
            <Button
              label={'Zaloguj się'}
              onClick={() => routeToLogin()}
              className={props.classes.radiusButton}
              color={'primary'}
            />
          }
          {
            (
              (!props.details.isLocked && quizStatus === 'running' && props.isLoggedIn && props.details.questionPrice === 0) ||
              (!props.details.isLocked && quizStatus === 'running' && props.coins && props.isLoggedIn && props.coins >= props.details.questionPrice)
            ) ?
              <Button
                label={'Wygraj'}
                onClick={() => routeToQuizQuestion(props.details.id)}
                className={props.classes.radiusButton}
                color={'primary'}
              />
              :
              null
          }
          {
            (
              (!props.details.isLocked && quizStatus === 'running' && props.coins === 0 && props.isLoggedIn && props.details.questionPrice !== 0) ||
              (!props.details.isLocked && quizStatus === 'running' && props.coins && props.isLoggedIn && props.coins < props.details.questionPrice)
            ) ?
              <Button
                label={'Doładuj'}
                onClick={() => routeToShop()}
                className={props.classes.radiusButton}
                color={'primary'}
              />
              :
              null
          }
          {
            quizStatus === 'upcoming' && props.isLoggedIn &&
            <Button
              label={'PRZYPOMNIJ'}
              onClick={() => props.openDialog('save')}
              className={props.classes.radiusButton}
              color={'primary'}
            />
          }
          {
            props.details.isLocked && quizStatus === 'running' && props.isLoggedIn &&
            <Button
              label={'Odblokuj'}
              onClick={() => props.openDialog('unlock')}
              className={props.classes.radiusButton}
              color={'primary'}
            />
          }
        </div>
      </div>
    </Card >
  )
}


export default ViewHeaderQuizDetails
