import React from 'react'

import PageLayout from 'components/templates/PageLayout'

import SuggestingIsNotActiveMessage from 'components/organisms/SuggestingIsNotActiveMessage'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewSuggestingIsNotActiveProps extends React.HTMLProps<HTMLDivElement> {
}

export const ViewSuggestingIsNotActive = (props: ViewSuggestingIsNotActiveProps) => {

  return (
    <PageLayout>
      <SuggestingIsNotActiveMessage />
    </PageLayout>
  )
}

export default ViewSuggestingIsNotActive
