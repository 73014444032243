import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { UploadAvatarResponse, UserAvatar } from './types'

export const updateAvatar = async (avatarData: UserAvatar) => {
  const formData = new FormData()
  formData.append('avatar', avatarData.avatar)

  const response = await fetchCustomEndpoint<UploadAvatarResponse>({
    url: '/profile/avatar',
    method: 'POST',
    data: formData,
  })

  const data: UploadAvatarResponse = response.data

  return data
}

export const useUpdateAvatar = () => {
  return useAsyncFn(updateAvatar)
}

