import React from 'react'

import { Typography, makeStyles, Theme } from '@material-ui/core'

import { Routes } from 'domains/main/views/PageHomeView'

import Card from 'components/atoms/Card'

import Button from 'components/atoms/Button'
import QuiziLogoText from 'components/icons/QuiziLogoText'
import QuiziLogo from 'components/icons/QuiziLogo'

export interface HowItWorksCardProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  subtitle: string,
  steps: string[],
  routes: Routes,
}

const useStyles = makeStyles<Theme, HowItWorksCardProps>((theme) => ({
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
  tokenIconBig: {
    width: 72,
    height: 72,
    filter: 'drop-shadow(3px 3px 9px #225998)',
  },
  logoInner: {
    textAlign: 'center',
  },
  logoText: {
    width: 120,
    filter: 'drop-shadow(10px 10px 15px #000000)',
  },
  shadowWhite: {
    filter: 'drop-shadow(1px 1px 3px #225998)',
  },
  listNumber: {
    margin: '-8px 5px 0',
    filter: 'drop-shadow(1px 1px 3px #225998)',
  },
  block: {
    position: 'relative',
    display: 'block',
    top: -20,
  },
  textContentContainer: {
    marginBottom: theme.spacing(2),
  },
  textContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoBox: {
    height: 110,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  howButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  howButton: {
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
}), { name: 'HowItWorksCard' })

export const HowItWorksCard = (props: HowItWorksCardProps) => {
  const classes = useStyles(props)
  const {
    title,
    subtitle,
    steps,
    routes,
  } = props

  const {
    quizzes,
    howItWorks,
  } = routes

  const StepsItems = () => {
    return (
      <>
        {steps.map((step, index) => (
          <div
            className={classes.textContent}
            key={index}
          >
            <Typography
              color={'textPrimary'}
              variant={'h2'}
              className={classes.listNumber}
            >
              {index + 1}.
            </Typography>
            <Typography
              color={'textPrimary'}
              variant={'h5'}
            >
              {step}
            </Typography>
          </div>
        ))}
      </>
    )
  }

  return (
    <Card
      mode={'white'}
    >
      <div>
        <Typography
          color={'textPrimary'}
          variant={'h1'}
          className={classes.shadow}
        >
          {title}
        </Typography>
        <Typography
          color={'textPrimary'}
          variant={'h5'}
          className={classes.shadow}
        >
          {subtitle}
        </Typography>
      </div>
      <div
        className={classes.logoBox}
      >
        <div
          className={classes.logoInner}
        >
          <QuiziLogo
            className={classes.tokenIconBig}
          />
          <div
            className={classes.block}
          >
            <QuiziLogoText
              className={classes.logoText}
            />
          </div>
        </div>
      </div>
      <div
        className={classes.textContentContainer}
      >
        <StepsItems />
      </div>
      <div
        className={classes.howButtons}
      >
        <Button
          className={classes.howButton}
          mode={'green'}
          label={'Wygraj!'}
          onClick={() => quizzes()}
        />
        <Button
          className={classes.howButton}
          color={'secondary'}
          label={'Dowiedz się więcej'}
          onClick={() => howItWorks()}
        />
      </div>
    </Card >
  )
}

export default HowItWorksCard
