import React from 'react'
import classnames from 'classnames'

// @ts-ignore this library do not have typings
import { Textfit } from 'react-textfit'

import { makeStyles, Theme, Typography } from '@material-ui/core'

import Ratio16X9 from 'components/atoms/Ratio16X9'
import Card, { CardProps } from 'components/atoms/Card'

export interface CardAnswerProps extends CardProps {
  selected: boolean,
  text: string,
}

const useStyles = makeStyles<Theme, CardAnswerProps>((theme) => ({
  root: {
  },
  inner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 20,
    height: '100%',
    overflow: 'hidden',
    color: theme.palette.common.white,
  },
  textFit: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}), { name: 'CardAnswer' })

export const CardAnswer = (props: CardAnswerProps) => {
  const classes = useStyles(props)
  const {
    text,
    selected,
    className,
    ...otherProps
  } = props

  return (
    <Card
      className={classnames([
        classes.root,
        className,
      ])}
      mode={selected ? 'blue' : 'darkBlue'}
      {...otherProps}
    >
      <Ratio16X9>
        <div
          className={classes.inner}
        >
          <Typography
            align={'center'}
            className={classes.text}
          >
            <Textfit
              mode={'multi'}
              max={20}
              className={classes.textFit}
            >
              {text}
            </Textfit>
          </Typography>
        </div>
      </Ratio16X9>
    </Card>
  )
}

export default CardAnswer
