import React, { Dispatch, ReactNode, SetStateAction } from 'react'

import { Typography, makeStyles, Theme } from '@material-ui/core'

import Card from 'components/atoms/Card'
import Button from 'components/atoms/Button'

import { formatDuration } from 'utils/formatDuration'

export interface TimerCardProps extends React.HTMLProps<HTMLDivElement> {
  text: string,
  time: number,
  title: string,
  label: string,
  children?: ReactNode,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,

}

const useStyles = makeStyles<Theme, TimerCardProps>((theme) => ({
  white: {
    color: theme.palette.common.white,
  },
  whiteWithShadow: {
    color: theme.palette.common.white,
    filter: 'drop-shadow(1px 1px 3px #225998)',
    marginBottom: theme.spacing(1),
  },
}), { name: 'TimerCard' })

export const TimerCard = (props: TimerCardProps) => {
  const classes = useStyles(props)
  const {
    text,
    title,
    time,
    label,
    ...otherProps
  } = props

  const [timeRemaining, setTimeRemaining] = React.useState<number>(time)

  React.useEffect(() => {
    if(timeRemaining === 0){
      return
    }

    const timeoutId = setTimeout(
      () => {
        const newTimeRemaining = timeRemaining - 1000
        setTimeRemaining(newTimeRemaining < 0 ? 0 : newTimeRemaining)
      },
      1000
    )

    return () => clearInterval(timeoutId)
  }, [timeRemaining])

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Card
        mode={'darkBlue'}
      >
        <Typography
          variant={'h5'}
          className={classes.white}
        >
          {title}
        </Typography>
        <Typography
          className={classes.white}
        >
          {text}
        </Typography>
        <Typography
          variant={'h3'}
          align={'center'}
          className={classes.whiteWithShadow}
        >
          {time && formatDuration(timeRemaining)}
        </Typography>
        <Button
          variant={'contained'}
          color={'primary'}
          label={label}
          onClick={() => props.setIsModalOpen(true)}
          fullWidth={true}
        />
      </Card>
    </div>
  )
}

export default TimerCard
