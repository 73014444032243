import React from 'react'

import { makeStyles, Theme, ListItem, ListItemProps, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core'

import FirstPlace from 'components/icons/FirstPlace'
import SecondPlace from 'components/icons/SecondPlace'
import ThirdPlace from 'components/icons/ThirdPlace'

const icons = [
  FirstPlace,
  SecondPlace,
  ThirdPlace,
]

export interface LeaderItemProps extends Omit<ListItemProps<'li', { button: false }>, 'button'> {
  avatar: string | null,
  email: string,
  score?: number,
  index: 0 | 1 | 2,
}

const useStyles = makeStyles<Theme, LeaderItemProps>((theme) => ({
  root: {
    justifyContent: 'center',
  },
  textWrapper: {
    flex: 'none',
    maxWidth: 240,
  },
  text: {
    paddingRight: theme.spacing(1),
  },
  iconWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minWidth: '10%',
  },
  avatarRoot: {

  },
  avatar: {
    width: 30,
    height: 30,
  },
  icon: {
    background: 'white',
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: '50%',
    padding: 5,
    position: 'absolute',
    left: -15,
    bottom: '50%',
    transform: 'translate(0, 50%)',
    width: 30,
    height: 30,
  },
}), { name: 'LeaderItem' })

export const LeaderItem = (props: LeaderItemProps) => {
  const classes = useStyles(props)
  const {
    avatar,
    email,
    score,
    index,
    ...otherProps
  } = props

  const Icon = icons[index]

  return (
    <ListItem
      className={classes.root}
      button={false}
      {...otherProps}
    >
      <ListItemAvatar
        className={classes.iconWrapper}
      >
        <>
          <Avatar
            className={classes.avatar}
            src={avatar || undefined}
          />
          <Icon
            className={classes.icon}
          />
        </>
      </ListItemAvatar>
      <ListItemText
        className={classes.textWrapper}
        primaryTypographyProps={{
          noWrap: true,
          className: classes.text,
          variant: 'subtitle1',
        }}
        primary={`${typeof score === 'number' ? `${score} pkt. ` : ''}${email}`}
      />
    </ListItem>
  )
}
export default LeaderItem
