
export const schema = {
  title: 'movie',
  type: 'object',
  properties: {
    url: {
      type: 'string',
      minLength: 1,
      label: 'Link do filmu (YouTube)',
      errorMessage: 'Wprowadź link do filmu',
      uniforms: {
        variant: 'outlined',
        type: 'text',
      },
    },
  },
  required: ['url' ],
}
