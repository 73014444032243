import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={'24px'}
      height={'24px'}
      viewBox={'0 0 730 730'}
      fill={'none'}
      {...props}
    >
      <path
        d={'M729 371c0 197.718-160.282 358-358 358S13 568.718 13 371 173.282 13 371 13s358 160.282 358 358z'}
        fill={'#385D80'}
        fillOpacity={0.2}
      />
      <circle
        cx={365}
        cy={365}
        r={365}
        fill={'#fff'}
      />
      <mask
        id={'prefix__a'}
        maskUnits={'userSpaceOnUse'}
        x={13}
        y={13}
        width={705}
        height={705}
      >
        <path
          d={'M718 365.5C718 560.18 560.18 718 365.5 718S13 560.18 13 365.5 170.82 13 365.5 13 718 170.82 718 365.5z'}
          fill={'#65A8E8'}
        />
      </mask>
      <g mask={'url(#prefix__a)'}>
        <path
          d={'M718 365.5C718 560.18 560.18 718 365.5 718S13 560.18 13 365.5 170.82 13 365.5 13 718 170.82 718 365.5z'}
          fill={'#57A5EF'}
        />
        <path
          // eslint-disable-next-line max-len
          d={'M366.5 363L208.45-2.25h316.1L366.5 363zM367 362.5l365.25-158.05v316.1L367 362.5zM366.5 363l158.05 365.25h-316.1L366.5 363zM366 362.5L.75 520.55v-316.1L366 362.5z'}
          fill={'#3794EC'}
        />
        <path
          d={'M662 362.5C662 525.7 529.7 658 366.5 658S71 525.7 71 362.5 203.3 67 366.5 67 662 199.3 662 362.5z'}
          fill={'#7EBBF4'}
        />
        <path
          d={'M602 362.5C602 492.563 496.563 598 366.5 598S131 492.563 131 362.5 236.437 127 366.5 127 602 232.437 602 362.5z'}
          fill={'#A6D1FB'}
        />
        <path
          // eslint-disable-next-line max-len
          d={'M498.378 483.09l38.283 49.429H459.61l-8.723-11.145c-16.476 8.722-34.083 14.538-52.821 17.445-18.415 2.908-36.83 2.908-55.244 0-18.415-2.584-36.345-8.076-53.791-16.476-17.123-8.4-32.468-19.707-46.037-33.922-18.415-19.061-31.66-39.737-39.737-62.029-8.077-22.292-11.792-44.583-11.146-66.875.969-22.614 5.815-44.26 14.538-64.936 8.723-20.676 20.838-38.929 36.345-54.76 15.507-16.153 33.922-28.914 55.244-38.283C319.561 191.846 342.983 187 368.505 187c25.199 0 48.299 4.684 69.298 14.053 20.999 9.369 39.091 21.969 54.275 37.799 15.184 15.83 27.138 33.922 35.861 54.275 9.045 20.353 14.053 41.514 15.022 63.483 1.292 21.968-1.615 43.937-8.723 65.905-7.107 21.646-19.061 41.837-35.86 60.575zm-40.222-51.852c12.6-19.061 19.223-39.252 19.869-60.575.969-21.322-3.069-40.868-12.115-58.636-8.723-18.092-21.646-32.953-38.768-44.584-16.8-11.63-36.507-17.445-59.121-17.445-18.738 0-35.215 3.715-49.429 11.146-14.215 7.107-26.007 16.476-35.376 28.106-9.369 11.631-16.315 24.877-20.838 39.738-4.2 14.538-5.815 29.399-4.846 44.583.969 15.184 4.684 29.883 11.146 44.098 6.461 13.892 15.992 25.846 28.591 35.861 16.477 13.568 34.891 21.807 55.244 24.714 20.354 2.585 40.061 0 59.122-7.753a2354.321 2354.321 0 00-16.477-20.353c-5.492-7.431-11.469-13.569-17.93-18.415-6.461-4.846-13.407-7.754-20.838-8.723-7.107-1.292-14.538.808-22.291 6.3-.969-9.046-.485-16.8 1.454-23.261 2.261-6.784 5.33-12.277 9.207-16.477 4.2-4.199 8.884-7.43 14.053-9.691 5.492-2.262 10.661-3.877 15.507-4.846 11.954-1.939 24.392.646 37.315 7.753 12.922 6.784 24.23 16.961 33.922 30.53l12.599 17.93z'}
          fill={'#385D80'}
          fillOpacity={0.1}
        />
        <path
          // eslint-disable-next-line max-len
          d={'M489.913 473.846l37.606 48.554h-75.687l-8.568-10.948c-16.185 8.568-33.48 14.28-51.886 17.136a172.86 172.86 0 01-54.266 0c-18.089-2.538-35.701-7.933-52.838-16.184-16.819-8.251-31.893-19.358-45.222-33.321-18.088-18.724-31.099-39.034-39.033-60.931-7.934-21.896-11.583-43.793-10.948-65.69.952-22.214 5.712-43.476 14.28-63.786 8.568-20.31 20.469-38.24 35.701-53.79 15.233-15.867 33.322-28.403 54.266-37.606 20.945-9.52 43.953-14.28 69.023-14.28 24.753 0 47.443 4.601 68.07 13.804 20.628 9.203 38.399 21.58 53.314 37.13 14.915 15.55 26.657 33.321 35.226 53.314 8.885 19.992 13.804 40.779 14.756 62.358 1.269 21.579-1.587 43.159-8.568 64.738-6.982 21.262-18.724 41.096-35.226 59.502zm-39.509-50.933c12.376-18.724 18.882-38.558 19.517-59.503.952-20.944-3.015-40.144-11.901-57.598-8.568-17.771-21.262-32.369-38.081-43.793-16.502-11.425-35.86-17.137-58.074-17.137-18.406 0-34.591 3.65-48.554 10.949-13.963 6.981-25.546 16.184-34.749 27.609-9.203 11.424-16.026 24.435-20.469 39.033-4.125 14.28-5.712 28.878-4.76 43.794.952 14.915 4.601 29.354 10.948 43.317 6.347 13.646 15.709 25.388 28.085 35.225 16.185 13.329 34.274 21.421 54.266 24.277 19.993 2.539 39.351 0 58.074-7.616a2343.89 2343.89 0 00-16.184-19.993c-5.395-7.299-11.266-13.328-17.613-18.088-6.347-4.761-13.17-7.617-20.469-8.569-6.981-1.269-14.28.794-21.896 6.189-.952-8.886-.476-16.502 1.428-22.849 2.221-6.664 5.236-12.059 9.044-16.185 4.125-4.125 8.727-7.299 13.804-9.52 5.395-2.222 10.473-3.808 15.233-4.76 11.742-1.905 23.96.634 36.653 7.616 12.694 6.664 23.801 16.661 33.322 29.989l12.376 17.613z'}
          fill={'#fff'}
        />
        <path
          fill={'#385D80'}
          fillOpacity={0.04}
          d={'M366 13h352v705H366z'}
        />
      </g>
    </svg>
  )
}

export default SvgComponent
