import React from 'react'
import classnames from 'classnames'

import { makeStyles, Theme, Snackbar, SnackbarProps } from '@material-ui/core'
import { Alert, AlertProps } from '@material-ui/lab'

const snackbarAnchor = {
  vertical: 'bottom' as const,
  horizontal: 'left' as const,
}

export interface SnackbarsProps extends SnackbarProps {
  lower?: boolean,
  snackbarMessage?: string | null,
  snackbarErrorMessage?: string | null,
  alertProps?: AlertProps,
  onClose?: () => void,
  onCloseError?: () => void,
}

const useStyles = makeStyles<Theme, SnackbarsProps>((theme) => ({
  root: {
    zIndex: ({ lower }) => lower ? 1399 : 1400,
  },
  alert: {
    'width': 400,
    '@media (max-width: 400px)': {
      width: '100vw',
    },
  },
}), { name: 'Snackbars' } )

export const Snackbars = (props: SnackbarsProps) => {
  const {
    lower = false,
    snackbarMessage,
    snackbarErrorMessage,
    alertProps,
    onClose,
    onCloseError,
    ...otherProps
  } = props
  const classes = useStyles({ lower })

  return (
    <>
      <Snackbar
        classes={classes}
        anchorOrigin={snackbarAnchor}
        open={Boolean(snackbarMessage)}
        autoHideDuration={6000}
        onClose={onClose}
        {...otherProps}
      >
        <Alert
          onClose={onClose}
          severity={'info'}
          {...alertProps}
          className={classnames([alertProps?.className, classes.alert])}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        classes={classes}
        anchorOrigin={snackbarAnchor}
        open={Boolean(snackbarErrorMessage)}
        autoHideDuration={6000}
        onClose={onCloseError}
        {...otherProps}
      >
        <Alert
          onClose={onCloseError}
          severity={'error'}
          {...alertProps}
          className={classnames([alertProps?.className, classes.alert])}
        >
          {snackbarErrorMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Snackbars
