import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { AnswerResult } from './types'

export const answerQuizQuestion = async (quizUuid: string, answer?: string): Promise<AnswerResult> => {
  const response = await fetchCustomEndpoint<AnswerResult>({
    url: `/quizzes/${quizUuid}/answer`,
    method: 'POST',
    data: { answer },
  })

  const data: AnswerResult = response.data

  return data
}

export const useAnswerQuizQuestion = (quizUuid: string) => {
  return useAsyncFn((answer?: string) => answerQuizQuestion(quizUuid, answer))
}
