import React from 'react'

import { CssBaseline, ThemeProvider } from '@material-ui/core'

import { themes, ThemeVariant } from '../index'

export interface AppThemeProviderProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode,
  themeVariant?: ThemeVariant,
}

export const AppThemeProvider = (props: AppThemeProviderProps) => {
  const {
    children,
    themeVariant = 'main',
  } = props

  const theme = themes[themeVariant]

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default AppThemeProvider
