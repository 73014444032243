/* eslint-disable max-len */
export const regulationsText = `Polityka dotycząca cookies i podobnych technologii.
Strona internetowa używa plików cookies i podobnych technologii m.in. po to, by dostosować serwis do potrzeb użytkowników, celów reklamowych i statystycznych.

Cookies mogą używać też serwisy, do których się odwołujemy pokazując np. multimedia.

W przeglądarce internetowej można zmienić ustawienia dotyczące cookies. Brak zmiany tych ustawień oznacza akceptację dla stosowanych cookies na stronie.

Cookies to niewielkie pliki tekstowe wysyłane przez serwis internetowy, który odwiedza internauta, do urządzenia internauty

Strona internetowa stosuje lub może stosować (w zależności na której podstronie użytkownik się znajduje) cookies wydajnościowe, czyli służące do zbierania informacji o sposobie korzystania ze strony, by lepiej działała, oraz funkcjonalne, czyli pozwalające „pamiętać” o ustawieniach użytkownika (np. język, rozmiar czcionki). To m.in.:

– cookies google-analytics.com – statystyki dla witryny 

– cookie powiązane z wtyczką „AddThis Social Bookmarking Widget”, która służy ona do łatwego dzielenia się treścią przez serwisy społecznościowe. Polityka prywatności serwisu Addthis opisana jest tu: http://www.addthis.com/privacy/privacy-policy#publisher-visitors (po wybraniu przez użytkownika serwisu, poprzez który będzie się dzielił treścią z witryny , w przeglądarce użytkownika pojawią się cookies z tamtej witryny)

– cokies sesyjne (wygasają po zakończeniu sesji)

– cokies reklamowe np. systemu OpenX

– cokies systemowe – powiązane z komentarzami i systemem głosowania, oraz innymi dodatkami strony, które mogą występować w celu interakcji z użytkownikiem

Serwisy, z których materiały przedstawiamy, mogą także używać cookies, które umożliwiają logowanie się, oraz służą dostarczaniu reklam odpowiadających upodobaniom i zachowaniom użytkowników w internecie. W szczególności takie cookies to:

W serwisie youtube.com – zawierające preferencje użytkownika, oraz liczydło kliknięć (opisane są w polityce prywatności http://www.google.pl/intl/pl/policies/privacy/)

W serwisie player.vimeo.com i av.vimeo.com – pozwalające się zalogować, a także cookies umieszczane przez reklamodawców pozwalające uzależniać wyświetlane reklamy od zachowania użytkownika (polityka w sprawie plików cookies dostępna jest pod adresem http://vimeo.com/cookie_policy)
Poziom ochrony przed cookies ustawia się w każdej przeglądarce:

Poziom ochrony przed cookies można ustawić w swojej przeglądarce internetowej – aż do całkowitego blokowania plików cookies. Zwiększa to poziom bezpieczeństwa i ochrony danych, ale może także uniemożliwiać niektóre funkcje, np. zalogowanie się na konto pocztowe.

Jak zmienia się ustawienia w najbardziej popularnych wyszukiwarkach

Google Chrome

Trzeba kliknąć na menu (w prawym górnym rogu), zakładka Ustawienia > Pokaż ustawienia zaawansowane. W sekcji „Prywatność” trzeba kliknąć przycisk Ustawienia treści. W sekcji „Pliki cookie” można zmienić następujące ustawienia plików cookie:

Usuwanie plików cookie

Domyślne blokowanie plików cookie

Domyślne zezwalanie na pliki cookie

Domyślne zachowywanie plików cookie i danych stron do zamknięcia przeglądarki

Określanie wyjątków dla plików cookie z konkretnych witryn lub domen

Więcej informacji można odnaleźć na stronie supportu: http://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647

Internet Explorer

Z menu przeglądarki (prawy górny róg): Narzędzia > Opcje Internetowe > Prywatność, przycisk Witryny. Suwakiem ustawiamy poziom, zmianę zatwierdzamy przyciskiem OK.

Więcej informacji można odnaleźć na stronie supportu: http://www.microsoft.com/pl-pl/security/resources/cookie-whatis.aspx

Mozilla Firefox

Z menu przeglądarki: Narzędzia > Opcje > Prywatność. Uaktywnić pole Program Firefox: „będzie używał ustawień użytkownika”.
O ciasteczkach (cookies) decyduje za klikanie – bądź nie – pozycji Akceptuj ciasteczka.

Więcej informacji można odnaleźć na stronie supportu: http://support.mozilla.org/pl/products/firefox?as=u&utm_source=inproduct

Opera

Z menu przeglądarki: Narzędzie > Preferencje > Zaawansowane.
O ciasteczkach decyduje zaznaczenie – bądź nie – pozycji Ciasteczka.

Więcej informacji można odnaleźć na stronie supportu: http://help.opera.com/Linux/9.22/pl/cookies.html

Safari

W menu rozwijanym Safari trzeba wybrać Preferencje i kliknąć ikonę Bezpieczeństwo. Tu wybiera się poziom bezpieczeństwa w obszarze ,,Akceptuj pliki cookie”.

`
