import { useAsyncFn } from 'utils/useAsyncFn'

import { directus } from 'api'

import { UserAddress } from './types'

export const updateAddress = async (data: UserAddress) => {
  await directus.users.me.update(data)
}

export const useUpdateAddress = () => {
  return useAsyncFn(updateAddress)
}
