import { useAsyncFn } from 'utils/useAsyncFn'

import { directus } from 'api'

import { UserPassword } from './types'

export const updatePassword = async (data: UserPassword) => {
  await directus.users.me.update(data)
}

export const useUpdatePassword = () => {
  return useAsyncFn(updatePassword)
}
