/* eslint-disable max-len */
import React from 'react'

function QuiziLogoPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={'2em'}
      height={'2em'}
      viewBox={'0 0 42 37'}
      fill={'none'}
      {...props}
    >
      <path
        d={'M17 34c9.389 0 17-7.611 17-17S26.389 0 17 0 0 7.611 0 17s7.611 17 17 17z'}
        fill={'#fff'}
      />
      <path
        d={'M31.959 17.247c0 8.125-6.587 14.712-14.712 14.712-8.126 0-14.713-6.587-14.713-14.712 0-8.126 6.587-14.713 14.713-14.713 8.125 0 14.712 6.587 14.712 14.713z'}
        fill={'#385D80'}
        fillOpacity={0.2}
      />
      <mask
        id={'prefix__b'}
        maskUnits={'userSpaceOnUse'}
        x={2}
        y={2}
        width={30}
        height={30}
      >
        <path
          d={'M31.507 17.02c0 8.001-6.486 14.487-14.487 14.487-8 0-14.486-6.486-14.486-14.487 0-8 6.486-14.486 14.486-14.486 8.001 0 14.487 6.486 14.487 14.486z'}
          fill={'#65A8E8'}
        />
      </mask>
      <g mask={'url(#prefix__b)'}>
        <path
          d={'M31.507 17.02c0 8.001-6.486 14.487-14.487 14.487-8 0-14.486-6.486-14.486-14.487 0-8 6.486-14.486 14.486-14.486 8.001 0 14.487 6.486 14.487 14.486z'}
          fill={'#57A5EF'}
        />
        <path
          d={'M17.062 16.918l-6.496-15.01h12.99l-6.494 15.01z'}
          fill={'url(#prefix__paint0_angular)'}
        />
        <path
          d={'M17.082 16.897l15.01-6.495v12.99l-15.01-6.495z'}
          fill={'url(#prefix__paint1_angular)'}
        />
        <path
          d={'M17.062 16.918l6.495 15.01h-12.99l6.495-15.01z'}
          fill={'url(#prefix__paint2_angular)'}
        />
        <path
          d={'M17.041 16.897l-15.01 6.495v-12.99l15.01 6.495z'}
          fill={'url(#prefix__paint3_angular)'}
        />
        <path
          d={'M29.206 16.897c0 6.707-5.438 12.144-12.144 12.144-6.707 0-12.144-5.437-12.144-12.144S10.355 4.753 17.062 4.753c6.706 0 12.143 5.437 12.143 12.144z'}
          fill={'#7EBBF4'}
        />
        <path
          d={'M26.74 16.897c0 5.345-4.333 9.678-9.678 9.678-5.345 0-9.678-4.333-9.678-9.678 0-5.345 4.333-9.678 9.678-9.678 5.345 0 9.678 4.333 9.678 9.678z'}
          fill={'#A6D1FB'}
        />
        <path
          d={'M22.481 21.853l1.573 2.031h-3.166l-.359-.458c-.677.359-1.4.598-2.17.717-.757.12-1.514.12-2.27 0a7.462 7.462 0 01-2.211-.677 6.65 6.65 0 01-1.892-1.394c-.757-.783-1.301-1.633-1.633-2.549a7.383 7.383 0 01-.458-2.748c.04-.93.239-1.82.597-2.669.359-.85.857-1.6 1.494-2.25a7.03 7.03 0 012.27-1.574c.877-.398 1.84-.597 2.888-.597 1.036 0 1.985.192 2.848.577a7.013 7.013 0 012.23 1.554 7.415 7.415 0 011.474 2.23c.372.837.577 1.706.617 2.61a7.333 7.333 0 01-.358 2.708c-.292.89-.783 1.719-1.474 2.489zm-1.653-2.13c.518-.784.79-1.614.817-2.49.04-.877-.126-1.68-.498-2.41a4.602 4.602 0 00-1.593-1.832c-.69-.478-1.5-.717-2.43-.717-.77 0-1.447.153-2.031.458a4.314 4.314 0 00-1.454 1.155 4.807 4.807 0 00-.856 1.633 5.33 5.33 0 00-.2 1.832c.04.624.193 1.228.458 1.813a4.05 4.05 0 001.175 1.473 4.522 4.522 0 002.27 1.016c.837.106 1.647 0 2.43-.319a97.964 97.964 0 00-.677-.836 3.64 3.64 0 00-.737-.757 1.8 1.8 0 00-.856-.358c-.292-.053-.597.033-.916.258-.04-.371-.02-.69.06-.956.093-.278.219-.504.378-.677.173-.172.365-.305.578-.398.225-.093.438-.16.637-.199.491-.08 1.002.027 1.533.319.531.278.996.697 1.394 1.254l.518.737z'}
          fill={'#385D80'}
          fillOpacity={0.1}
        />
        <path
          d={'M22.133 21.473l1.546 1.995h-3.11l-.353-.45a6.73 6.73 0 01-2.132.705 7.104 7.104 0 01-2.23 0 7.328 7.328 0 01-2.172-.665 6.532 6.532 0 01-1.858-1.37c-.743-.77-1.278-1.604-1.604-2.504-.326-.9-.476-1.8-.45-2.7.039-.912.235-1.786.587-2.62a7.04 7.04 0 011.467-2.211 6.907 6.907 0 012.23-1.546c.86-.39 1.806-.586 2.837-.586 1.017 0 1.95.189 2.797.567a6.89 6.89 0 012.191 1.526 7.284 7.284 0 011.448 2.19 7.02 7.02 0 01.606 2.563 7.203 7.203 0 01-.352 2.66c-.287.875-.77 1.69-1.448 2.446zM20.51 19.38c.508-.77.776-1.585.802-2.445.039-.861-.124-1.65-.49-2.367a4.52 4.52 0 00-1.564-1.8c-.678-.47-1.474-.704-2.387-.704-.756 0-1.421.15-1.995.45a4.239 4.239 0 00-1.428 1.134 4.721 4.721 0 00-.842 1.604 5.237 5.237 0 00-.195 1.8c.039.613.189 1.206.45 1.78.26.561.645 1.044 1.154 1.448.665.548 1.408.88 2.23.997a4.67 4.67 0 002.387-.313c-.209-.26-.43-.534-.666-.821a3.573 3.573 0 00-.723-.743 1.766 1.766 0 00-.841-.353c-.287-.052-.587.033-.9.255-.04-.365-.02-.678.058-.94.092-.273.215-.495.372-.664.17-.17.359-.3.567-.392.222-.09.43-.156.626-.195.483-.079.985.026 1.507.313.521.274.978.684 1.369 1.232l.509.724z'}
          fill={'#fff'}
        />
        <path
          d={'M31.507 2.534H17.04v28.973h14.466V2.534z'}
          fill={'#385D80'}
          fillOpacity={0.04}
        />
      </g>
      <path
        d={'M32 18.25a8.75 8.75 0 10.001 17.501A8.75 8.75 0 0032 18.25zm0 16.016A7.267 7.267 0 0124.734 27 7.267 7.267 0 0132 19.734 7.267 7.267 0 0139.266 27 7.267 7.267 0 0132 34.266z'}
        fill={'#005C4A'}
      />
      <path
        d={'M32 19.734A7.267 7.267 0 0024.734 27 7.267 7.267 0 0032 34.266 7.267 7.267 0 0039.266 27 7.267 7.267 0 0032 19.734zm3.75 7.735c0 .086-.07.156-.156.156h-2.969v2.969c0 .086-.07.156-.156.156h-.938a.157.157 0 01-.156-.156v-2.969h-2.969a.157.157 0 01-.156-.156v-.938c0-.086.07-.156.156-.156h2.969v-2.969c0-.086.07-.156.156-.156h.938c.086 0 .156.07.156.156v2.969h2.969c.086 0 .156.07.156.156v.938z'}
        fill={'#4DCDB4'}
      />
      <path
        d={'M35.594 26.375h-2.969v-2.969a.157.157 0 00-.156-.156h-.938a.157.157 0 00-.156.156v2.969h-2.969a.157.157 0 00-.156.156v.938c0 .086.07.156.156.156h2.969v2.969c0 .086.07.156.156.156h.938c.086 0 .156-.07.156-.156v-2.969h2.969c.086 0 .156-.07.156-.156v-.938a.157.157 0 00-.156-.156z'}
        fill={'#005C4A'}
      />
      <defs>
        <radialGradient
          id={'prefix__paint0_angular'}
          cx={0}
          cy={0}
          r={1}
          gradientUnits={'userSpaceOnUse'}
          gradientTransform={'matrix(0 7.50514 -6.4952 0 17.062 9.413)'}
        >
          <stop stopColor={'#70A4D6'} />
          <stop
            offset={0.505}
            stopColor={'#0C1116'}
            stopOpacity={0.313}
          />
          <stop
            offset={1}
            stopColor={'#375F85'}
            stopOpacity={0}
          />
        </radialGradient>
        <radialGradient
          id={'prefix__paint1_angular'}
          cx={0}
          cy={0}
          r={1}
          gradientUnits={'userSpaceOnUse'}
          gradientTransform={'matrix(0 6.4952 -7.50514 0 24.587 16.897)'}
        >
          <stop stopColor={'#70A4D6'} />
          <stop
            offset={0.505}
            stopColor={'#0C1116'}
            stopOpacity={0.313}
          />
          <stop
            offset={1}
            stopColor={'#375F85'}
            stopOpacity={0}
          />
        </radialGradient>
        <radialGradient
          id={'prefix__paint2_angular'}
          cx={0}
          cy={0}
          r={1}
          gradientUnits={'userSpaceOnUse'}
          gradientTransform={'matrix(0 7.50514 -6.4952 0 17.062 24.423)'}
        >
          <stop stopColor={'#70A4D6'} />
          <stop
            offset={0.505}
            stopColor={'#0C1116'}
            stopOpacity={0.313}
          />
          <stop
            offset={1}
            stopColor={'#375F85'}
            stopOpacity={0}
          />
        </radialGradient>
        <radialGradient
          id={'prefix__paint3_angular'}
          cx={0}
          cy={0}
          r={1}
          gradientUnits={'userSpaceOnUse'}
          gradientTransform={'matrix(0 6.4952 -7.50514 0 9.536 16.897)'}
        >
          <stop stopColor={'#70A4D6'} />
          <stop
            offset={0.505}
            stopColor={'#0C1116'}
            stopOpacity={0.313}
          />
          <stop
            offset={1}
            stopColor={'#375F85'}
            stopOpacity={0}
          />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default QuiziLogoPlus
