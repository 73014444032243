import React from 'react'
import ReactDOM from 'react-dom'

import packageJSON from '../package.json'

import App from './App'

import reportWebVitals from './reportWebVitals'

import './index.css'

console.log('QUIZI APP | VERSION: ', packageJSON.version)

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
