import React from 'react'
import classnames from 'classnames'

import { makeStyles, Theme, Card as MUICard, CardProps as MUICardProps } from '@material-ui/core'

export type CardMode = 'white' | 'blue' | 'darkBlue'

export interface CardProps extends React.HTMLProps<HTMLDivElement> {
  mode?: CardMode,
  cardProps?: MUICardProps,
}

const useStyles = makeStyles<Theme, CardProps>((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  default: {
    width: '100%',
  },
  white: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#FFF',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(1),
    },
  },
  blue: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(1),
    },
  },
  darkBlue: {
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(1),
    },
  },
}), { name: 'Card' })

export const Card = (props: CardProps) => {
  const classes = useStyles(props)
  const {
    mode = 'default',
    className,
    cardProps = {},
    children,
    ...otherProps
  } = props

  const {
    className: cardClassName,
    ...otherCardProps
  } = cardProps

  return (
    <div
      className={classnames([
        classes.root,
        className,
      ])}
      {...otherProps}
    >
      <MUICard
        className={classnames([
          classes[mode],
          cardClassName,
        ])}
        elevation={3}
        {...otherCardProps}
      >
        {children}
      </MUICard>
    </div>
  )
}

export default Card
