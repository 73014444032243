import React from 'react'

import Loader from 'components/molecules/Loader'

import FormProfile from 'components/organisms/FormProfile'

import PageLayout from 'components/templates/PageLayout'

import {
  useGetUser,
  useUpdateAddress,
  useUpdateUserName,
  useUpdatePassword,
  useUpdateAvatar,
  SchemaVariant
} from 'domains/main/api/user'

export const PageProfile = () => {

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false)
  const [selectedSchema, setSelectedSchema] = React.useState<SchemaVariant>(null)

  const [getUserState, getUser] = useGetUser()
  const [updateAddressState, updateAddress] = useUpdateAddress()
  const [updateUserNameState, updateUserName] = useUpdateUserName()
  const [updatePasswordState, updatePassword] = useUpdatePassword()
  const [updateAvatarState, updateAvatar] = useUpdateAvatar()

  React.useEffect(() => {
    getUser()
    // only at first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openSelectedDialog = React.useCallback((selectedVariant: SchemaVariant) => {
    setIsDialogOpen(true)
    setSelectedSchema(selectedVariant)
  }, [])

  const updateForm = React.useCallback(async (data: Record<string, any>) => {
    switch (selectedSchema) {
      case 'profileAddressSchema':
        await updateAddress({ street: data.street, city: data.city, zipcode: data.postCode, phone: data.phoneNumber })
        break
      case 'profileNameSchema':
        // eslint-disable-next-line camelcase
        await updateUserName({ first_name: data.firstName, last_name: data.lastName })
        break
      case 'profilePasswordSchema':
        if (data.newPassword === data.replyNewPassword) {
          await updatePassword({ password: data.newPassword })
        }
        break
      case 'profileAvatarSchema':
        console.log(data)
        await updateAvatar({ avatar: data[0] })
        break
    }
    setIsDialogOpen(false)
    await getUser()
  }, [selectedSchema, getUser, updateAddress, updateUserName, updateAvatar, updatePassword])

  return (
    <Loader
      opacity={getUserState.value ? 0.8 : 1}
      doNotUnmountChildren={getUserState.value ? true : false}
      loading={getUserState.loading}
      error={getUserState.error}
      retry={getUser}
      message={'Ładowanie użytkownika...'}
    >
      <Loader
        opacity={0.8}
        doNotUnmountChildren={true}
        loading={updateAddressState.loading}
        error={updateAddressState.error}
        message={'Zapisywanie danych...'}
        cancelable={true}
      >
        <Loader
          opacity={0.8}
          doNotUnmountChildren={true}
          loading={updateUserNameState.loading}
          error={updateUserNameState.error}
          message={'Zapisywanie danych...'}
          cancelable={true}
        >
          <Loader
            opacity={0.8}
            doNotUnmountChildren={true}
            loading={updatePasswordState.loading}
            error={updatePasswordState.error}
            message={'Zapisywanie danych...'}
            cancelable={true}
          >
            <Loader
              opacity={0.8}
              doNotUnmountChildren={true}
              loading={updateAvatarState.loading}
              error={updateAvatarState.error}
              message={'Zapisywanie danych...'}
              cancelable={true}
            >
              <PageLayout>
                {
                  getUserState.value ?
                    <FormProfile
                      isDialogOpen={isDialogOpen}
                      userData={getUserState.value}
                      selectedSchema={selectedSchema}
                      updateForm={updateForm}
                      setIsDialogOpen={setIsDialogOpen}
                      openSelectedDialog={openSelectedDialog}
                    />
                    :
                    null
                }
              </PageLayout>
            </Loader>
          </Loader>
        </Loader>
      </Loader>
    </Loader>
  )
}

export default PageProfile
