import React from 'react'
import Rollbar from 'rollbar'

import Loader, { LoaderProps } from './Loader'

const isRollbarSetup = Boolean(window._env_.REACT_APP_API_ROLLBAR_CLIENT_TOKEN)
const isProduction = Boolean(process.env.NODE_ENV === 'production')

const rollbar = isRollbarSetup ?
  new Rollbar({
    accessToken: window._env_.REACT_APP_API_ROLLBAR_CLIENT_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
  })
  :
  null

export interface LoaderErrorBoundaryProps extends LoaderProps {
  innerRef?: any,
}

export class LoaderErrorBoundary extends React.Component<LoaderErrorBoundaryProps, { error: Error | null }> {
  public state = { error: null }

  public static getDerivedStateFromError(error: Error) {
    return { error }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if(rollbar) rollbar.error(error, errorInfo)

    if(!isProduction) console.error(error, errorInfo)
  }

  public render() {
    if (this.state.error) {
      return (
        <Loader
          loading={false}
          error={isProduction ? new Error('Wystąpił błąd') : this.state.error}
        />
      )
    }

    return  (
      <Loader
        // @ts-ignore
        ref={this.props.innerRef}
        {...this.props}
      />
    )
  }
}

export default React.forwardRef<unknown, LoaderErrorBoundaryProps>((props, ref) => (
  // @ts-ignore
  <LoaderErrorBoundary
    innerRef={ref}
    {...props}
  />
))
