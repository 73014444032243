import React from 'react'

import { FieldProps, connectField, filterDOMProps } from 'uniforms'

import { makeStyles, Theme, TextField, StandardTextFieldProps, Typography } from '@material-ui/core'

export type TextFieldProps = FieldProps<string, StandardTextFieldProps>

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontWeight: 700,
    color: theme.palette.common.black,
  },
  labelAsterix: {
    color: theme.palette.error.main,
  },
  textField: {
    marginTop: 0,
  },
}), { name: 'TextFieldWithLabel' } )

const TextFieldWithLabel = (props: TextFieldProps) => {
  const {
    disabled,
    error,
    errorMessage,
    helperText,
    inputRef,
    label,
    name,
    placeholder,
    readOnly,
    showInlineError,
    type = 'text',
    value = '',
    required,
    onChange,
    ...otherProps
  } = props
  const classes = useStyles(props)
  return (
    <div
      className={classes.root}
    >
      <Typography
        className={classes.label}
      >
        {label}
        {
          required ?
            <Typography
              className={classes.labelAsterix}
              component={'span'}
            >
              {' *'}
            </Typography>
            :
            null
        }
      </Typography>
      <TextField
        classes={{ root: classes.marginTop }}
        disabled={disabled}
        error={!!error}
        fullWidth
        helperText={(error && showInlineError && errorMessage) || helperText}
        inputProps={{ readOnly }}
        margin={'none'}
        name={name}
        onChange={(event) => disabled || onChange(event.target.value)}
        placeholder={placeholder}
        ref={inputRef}
        type={type}
        value={value}
        {...filterDOMProps({ ...otherProps, required })}
      />
    </div>
  )
}

export default connectField(TextFieldWithLabel, { kind: 'leaf' })
