import React from 'react'

import { makeStyles, Theme, CardMedia, CardContent, Typography } from '@material-ui/core'

import Card from 'components/atoms/Card'
import ChipQuiz from 'components/molecules/ChipQuiz'
import Button from 'components/atoms/Button'

export interface QuizItemProps extends React.HTMLProps<HTMLDivElement> {
  img?: string,
  category: string,
  title: string,
  sponsor?: string,
  isLocked: boolean,
  isUpcoming: boolean,
  isEnded: boolean,
  timeChipLabel: string,
  winner?: string,
  onClickCTA: () => void,
  onClickItem?: () => void,
}

const useStyles = makeStyles<Theme, QuizItemProps>((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  img: {
    position: 'relative',
    paddingTop: '56.25%',
    height: 250,
    [theme.breakpoints.down('xs')]: {
      height: 150,
    },
  },
  chips: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(1),
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  content: {
    marginTop: theme.spacing(2),
  },
  centred: {
    marginBottom: theme.spacing(2),
  },
}), { name: 'QuizzItem' })

export const QuizzItem = (props: QuizItemProps) => {
  const classes = useStyles(props)
  const {
    img,
    category,
    title,
    sponsor,
    isLocked,
    isUpcoming,
    isEnded,
    timeChipLabel,
    winner,
    onClickCTA,
    onClickItem,
    ...otherProps
  } = props

  return (
    <div
      onClick={onClickItem}
      className={classes.root}
      {...otherProps}
    >
      <Card>
        <CardMedia
          image={img}
          className={classes.img}
        >
          <div
            className={classes.chips}
          >
            <ChipQuiz
              mode={isEnded ? 'ended' : isUpcoming ? 'upcoming' : 'running'}
              isLocked={isLocked}
              topChipLabel={timeChipLabel}
              bottomChipLabel={winner}
            />
          </div>
        </CardMedia>
        <CardContent
          className={classes.content}
        >
          <Typography
            gutterBottom={true}
            variant={'h5'}
            align={'center'}
            className={classes.category}
            color={'textSecondary'}
          >
            {`Kategoria: ${category}`}
          </Typography>
          <Typography
            gutterBottom={true}
            variant={'h3'}
            align={'center'}
            color={'textPrimary'}
            className={classes.centred}
          >
            {title}
          </Typography>
          <Typography
            variant={'h5'}
            align={'center'}
            color={'textSecondary'}
            className={classes.sponsor}
          >
            Sponsor quizu:
          </Typography>
          <Typography
            gutterBottom={true}
            variant={'h4'}
            align={'center'}
            color={'textPrimary'}
            className={classes.centred}
          >
            {sponsor}
          </Typography>
          <Button
            fullWidth={true}
            size={'small'}
            variant={'contained'}
            color={'secondary'}
            onClick={(e) => {
              e.stopPropagation()
              onClickCTA()
            }}
            label={
              isLocked ?
                'Odblokuj'
                :
                isUpcoming ?
                  'Przypomnij'
                  :
                  isEnded ?
                    'Zobacz więcej'
                    :
                    'Wygraj'
            }
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default QuizzItem
