import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { ReportQuestionResponse } from './types'

export const reportQuestion = async (questionId: string): Promise<ReportQuestionResponse> => {
  const response = await fetchCustomEndpoint<ReportQuestionResponse>({
    url: `/questions/report/${questionId}`,
  })

  const data: ReportQuestionResponse = response.data

  return data
}

export const useReportQuestion = () => {
  return useAsyncFn(reportQuestion)
}
