import React from 'react'

import { makeStyles, Theme, Typography } from '@material-ui/core'

import Button from 'components/atoms/Button'

export interface HowItWorksCardContentProps extends React.HTMLProps<HTMLDivElement> {
  index: number,
  label: string,
  title: string,
  text: string,
  special: boolean,
  onClick: () => void,
}

const useStyles = makeStyles<Theme, HowItWorksCardContentProps>((theme) => ({
  root: {},
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    transform: 'scale(1.5)',
    marginLeft: theme.spacing(2),
    fill: theme.palette.primary.main,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  textContent: {
    marginBottom: theme.spacing(2),
  },
}), { name: 'HowItWorksCardContent' })

export const HowItWorksCardContent = (props: HowItWorksCardContentProps) => {
  const classes = useStyles(props)

  const {
    index,
    label,
    title,
    text,
    onClick,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      key={index}
      {...otherProps}
    >
      <div
        className={classes.header}
      >
        <Typography
          variant={'h2'}
          align={'center'}
          color={'primary'}
          className={classes.title}
        >
          {title}
        </Typography>
      </div>
      <Typography
        variant={'h5'}
        align={'center'}
        color={'secondary'}
        className={classes.textContent}
      >
        {text}
      </Typography>
      <Button
        fullWidth={true}
        label={label}
        onClick={onClick}
        color={'primary'}
      />
    </div>
  )
}

export default HowItWorksCardContent
