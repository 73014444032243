import React from 'react'

import { makeStyles, Theme, IconButton } from '@material-ui/core'
import { Menu } from '@material-ui/icons'

import QuiziLogoPlus from 'components/icons/QuiziLogoPlus'

import Button from 'components/atoms/Button'

export interface MenuControlsProps extends React.HTMLProps<HTMLDivElement> {
  isLoggedIn: boolean,
  coinsAmount: number | null,
  onCoinClick: () => void,
  onClickOpenSideBar: () => void,
}

const useStyles = makeStyles<Theme, MenuControlsProps>((theme) => ({
  root: {},
  menu: {
    color: theme.palette.common.white,
    width: 52,
    height: 52,
  },
}), { name: 'MenuControls' } )

export const MenuControls = (props: MenuControlsProps) => {
  const classes = useStyles(props)
  const {
    isLoggedIn,
    coinsAmount,
    onCoinClick,
    onClickOpenSideBar,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      {
        isLoggedIn ?
          <Button
            onClick={onCoinClick}
            mode={'tokens'}
            label={coinsAmount}
            endIcon={<QuiziLogoPlus />}
            variant={'outlined'}
          />
          :
          null
      }
      <IconButton
        className={classes.menu}
        onClick={onClickOpenSideBar}
      >
        <Menu />
      </IconButton>

    </div>
  )
}

export default MenuControls
