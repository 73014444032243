import React from 'react'

import FormLogin from 'components/organisms/FormLogin'

import PageLayout from 'components/templates/PageLayout'

import { useAuthContext } from 'auth'
import { logInByGoogle, logInByFacebook } from 'api/auth'

import { routeHome, routeRegister, routeForgotPassword } from 'domains/main'

export const PageLogin = () => {
  const routeToHome = routeHome.useRouteTo()
  const routeToRegister = routeRegister.useRouteTo()
  const routeToForgetPassword = routeForgotPassword.useRouteTo()

  const {
    isLoggedIn,
    logInByEmailAndPassword,
  } = useAuthContext()

  React.useEffect(() => {
    if (isLoggedIn) routeToHome()
  }, [isLoggedIn, routeToHome])

  const logInByEmailAndPasswordHandler = React.useCallback(async (credentials) => {
    await logInByEmailAndPassword(credentials)
    routeToHome()
  }, [logInByEmailAndPassword, routeToHome])

  return (
    <PageLayout>
      <FormLogin
        onSubmitLoginByEmailAndPassword={logInByEmailAndPasswordHandler}
        onClickLoginByFacebook={logInByFacebook}
        onClickLoginByGoogle={logInByGoogle}
        onClickRegister={routeToRegister}
        onClickForgetPassword={routeToForgetPassword}
      />
    </PageLayout>
  )
}

export default PageLogin
