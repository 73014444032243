import React from 'react'

import { makeStyles, Theme, Typography } from '@material-ui/core'

export interface HeaderAnswerProps extends React.HTMLProps<HTMLDivElement> {
  text: string,
  isValid: boolean,
}

const useStyles = makeStyles<Theme, HeaderAnswerProps>((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  valid: {
    color: theme.palette.success.main,
  },
  notvalid: {
    color: theme.palette.error.main,
  },
}), { name: 'HeaderAnswer' })

export const HeaderAnswer = (props: HeaderAnswerProps) => {
  const classes = useStyles(props)
  const {
    text = '',
    isValid,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Typography
        align={'center'}
        color={'primary'}
        gutterBottom={true}
        className={isValid ? classes.valid : classes.notvalid}
        variant={'h4'}
      >
        {isValid ? 'Gratulacje!' : 'Nie tym razem...'}
      </Typography>
      <Typography
        align={'center'}
        color={'primary'}
        variant={'h5'}
      >
        {text}
      </Typography>
    </div>
  )
}

export default HeaderAnswer
