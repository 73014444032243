import React from 'react'

import { makeStyles, Theme, Avatar, Tooltip } from '@material-ui/core'

import Button from 'components/atoms/Button'

import SideBarMenu from 'components/organisms/SideBarMenu'

export interface UserControlProps extends React.HTMLProps<HTMLDivElement> {
  user: {
    avatar?: string | null,
    email?: string | null,
  } | null,
  isLoggedIn: boolean,
  onClickProfile: () => void,
  onClickLogin: () => void,
  onClickLogOut: () => void,
}

const useStyles = makeStyles<Theme, UserControlProps>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  menu: {
    width: '90%',
  },
  lowerButton: {
    marginBottom: theme.spacing(3),
    width: '90%',
  },
}), { name: 'UserControl' })

export const UserControl = (props: UserControlProps) => {
  const classes = useStyles(props)
  const {
    user,
    isLoggedIn,
    onClickProfile,
    onClickLogin,
    onClickLogOut,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      {
        isLoggedIn ?
          <>
            <Tooltip
              title={user?.email || ''}
            >
              <SideBarMenu
                className={classes.menu}
                menuItems={[
                  {
                    icon: (
                      <Avatar
                        src={user?.avatar ? user.avatar : ''}
                      />
                    ),
                    text: user?.email,
                    textSecondary: 'Edytuj profil',
                    onClick: onClickProfile,
                  },
                ]}
              />
            </Tooltip>
            <Button
              label={'Wyloguj'}
              color={'secondary'}
              className={classes.lowerButton}
              onClick={onClickLogOut}
            />
          </>
          :
          <Button
            label={'Zaloguj'}
            color={'secondary'}
            className={classes.lowerButton}
            onClick={onClickLogin}
          />
      }
    </div>
  )
}

export default UserControl
