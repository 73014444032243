import React from 'react'

import { Notifications, HowToRegOutlined, DoneOutline, Stars, Forum, Description, History } from '@material-ui/icons'

import ScrollIcon from 'components/icons/ScrollIcon'
import WalletPlus from 'components/icons/WalletPlus'
import PersonQuestion from 'components/icons/PersonQuestion'


import { SideBarMenuItem } from 'components/organisms/SideBarMenu'

import {
  routeShop,
  routeTransactionsHistory,
  routeAddQuestion,
  routeQuizzes,
  routeBecomeSponsor,
  routeHowItWorks,
  routeContact,
  routeRegulations
} from 'domains/main'


export const makeMenuPaths = ({ routeTo }: { routeTo: (path: string) => void }): (SideBarMenuItem & { authRequired: boolean })[] => {
  return [
    {
      authRequired: true,
      icon: <WalletPlus />,
      text: 'Doładuj tokeny',
      onClick: () => routeTo(routeShop.makePath()),
      isSpecial: true,
    },
    {
      authRequired: false,
      icon: <ScrollIcon />,
      text: 'Aktualne konkursy',
      onClick: () => routeTo(routeQuizzes.makePath('running')),
    },
    {
      authRequired: false,
      icon: <Notifications />,
      text: 'Nadchodzące konkursy',
      onClick: () => routeTo(routeQuizzes.makePath('upcoming')),
    },
    {
      authRequired: false,
      icon: <DoneOutline />,
      text: 'Zakończone konkursy',
      onClick: () => routeTo(routeQuizzes.makePath('ended')),
    },
    {
      authRequired: true,
      icon: <History />,
      text: 'Historia transakcji',
      onClick: () => routeTo(routeTransactionsHistory.makePath()),
    },
    {
      authRequired: true,
      icon: <PersonQuestion />,
      text: 'Zaproponuj pytanie',
      onClick: () => routeTo(routeAddQuestion.makePath()),
    },
    {
      authRequired: false,
      icon: <HowToRegOutlined />,
      text: 'Zostań patronem!',
      onClick: () => routeTo(routeBecomeSponsor.makePath()),
    },
    {
      authRequired: false,
      icon: <Forum />,
      text: 'Kontakt',
      onClick: () => routeTo(routeContact.makePath()),
    },
    {
      authRequired: false,
      icon: <Stars />,
      text: 'Jak działa Quizi?',
      onClick: () => routeTo(routeHowItWorks.makePath()),
    },
    {
      authRequired: false,
      icon: <Description />,
      text: 'Regulamin',
      onClick: () => routeTo(routeRegulations.makePath()),
    },
  ]
}
