import { fetchCustomEndpoint } from 'api/fetchCustomEndpoint'
import { useAsyncFn } from 'utils/useAsyncFn'

import { Quiz, QuizRaw } from './types'
import { convertQuiz } from './converters'

export const getQuiz = async (quizUuid: string): Promise<Quiz> => {
  const response = await fetchCustomEndpoint<QuizRaw>({
    url: `/quizzes/${quizUuid}`,
  })

  const data: QuizRaw = response.data

  return convertQuiz(data)
}

export const useGetQuiz = () => {
  return useAsyncFn(getQuiz)
}
