import React from 'react'

import { CardContent, Typography, makeStyles, Theme } from '@material-ui/core'

import Card from 'components/atoms/Card'
import Ratio16X9 from 'components/atoms/Ratio16X9'

export interface MediaCardProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  reward: boolean,
  sponsor?: string,
  category?: string,
  desc: string,
  img: string,
}

const useStyles = makeStyles<Theme, MediaCardProps>((theme) => ({
  root: {},
  cardFlex: {
    display: 'flex',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  category: {
    textTransform: 'uppercase',
    letterSpacing: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      fontWeight: 600,
    },
  },
  quizTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  sponsor: {
    textTransform: 'uppercase',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  sponsorLogo: {
    width: 150,
  },
}), { name: 'MediaCard' })

export const MediaCard = (props: MediaCardProps) => {
  const classes = useStyles(props)

  const {
    title,
    reward,
    sponsor,
    category,
    desc,
    img,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Card>
        <Ratio16X9>
          <img
            alt={'nagroda'}
            src={img}
            className={classes.img}
          />
        </Ratio16X9>
        {
          reward ?
            <CardContent>
              <Typography
                gutterBottom={true}
                variant={'h5'}
                color={'textPrimary'}
              >
                {title}
              </Typography>
              <Typography
                variant={'body1'}
                color={'textSecondary'}
              >
                {desc}
              </Typography>
            </CardContent>
            :
            <CardContent>
              <Typography
                gutterBottom={true}
                variant={'body1'}
                className={classes.category}
                color={'secondary'}
              >
                {category}
              </Typography>
              <Typography
                gutterBottom={true}
                variant={'h4'}
                className={classes.quizTitle}
              >
                {title}
              </Typography>
              <Typography
                variant={'body1'}
                color={'secondary'}
                className={classes.sponsor}
              >
                Sponsor: {sponsor}
              </Typography>
            </CardContent>
        }
      </Card>
    </div>
  )
}

export default MediaCard
