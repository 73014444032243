import { directus } from './directus'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export const fetchCustomEndpoint = <T = any>(options: AxiosRequestConfig) => {
  return directus.axios(
    '/custom' + options.url,
    {
      ...options,
      headers: {
        ...(directus.auth.token ? { Authorization: 'Bearer ' + directus.auth.token } : {}),
        ...options.headers,
      },
    }) as Promise<AxiosResponse<T>>
}
