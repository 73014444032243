import invariant from 'invariant'
import { createAutoField } from 'uniforms'

import { BoolField, DateField, ListField, NestField, NumField, RadioField, SelectField } from 'uniforms-material'

import TextFieldWithLabel from './customFields/TextFieldWithLabel'

export type AutoFieldProps = Parameters<typeof AutoField>[0]

const AutoField = createAutoField((props) => {
  if (props.allowedValues) {
    return props.checkboxes && props.fieldType !== Array
      ? RadioField
      : SelectField
  }

  switch (props.fieldType) {
    case Array:
      return ListField
    case Boolean:
      return BoolField
    case Date:
      return DateField
    case Number:
      return NumField
    case Object:
      return NestField
    case String:
      return TextFieldWithLabel
  }

  return invariant(false, 'Unsupported field type: %s', props.fieldType)
})

export default AutoField
