import React from 'react'
import classnames from 'classnames'

import { CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core'

import Card from 'components/atoms/Card'

export interface HeaderQuizQuestionProps extends React.HTMLProps<HTMLDivElement> {
  categoryName: string,
  quizName: string,
  answerTime: number,
  answerTimeRemaining: number,
  onTimeExceed: () => void,
}

const useStyles = makeStyles<Theme, HeaderQuizQuestionProps>((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  card: {
    height: 65,
    padding: '8px 16px',
  },
  text: {
    color: theme.palette.common.white,
  },
  textCategoryName: {
    fontSize: 14,
  },
  textQuizName: {
    fontSize: 18,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  col: {},
  colLeft: {
    textAlign: 'left',
  },
  colRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  progressWrapper: {
    color: theme.palette.common.white,
    position: 'relative',
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    color: theme.palette.common.white,
    position: 'absolute',
  },
  progressText: {
    position: 'absolute',
  },
}), { name: 'HeaderQuizQuestion' } )

export const HeaderQuizQuestion = (props: HeaderQuizQuestionProps) => {
  const classes = useStyles(props)
  const {
    categoryName,
    quizName,
    answerTime,
    answerTimeRemaining,
    onTimeExceed,
    ...otherProps
  } = props

  const lastStep = React.useRef<number | null>(null)
  const requestIdRef = React.useRef<number | null>(null)

  const [timeLeft, setTimeLeft] = React.useState(answerTimeRemaining)

  const update = React.useCallback((timestamp: number) => {
    const elapsed = lastStep.current === null ? 0 : timestamp - lastStep.current
    lastStep.current = timestamp
    // eslint-disable-next-line no-shadow
    setTimeLeft((timeLeft) => timeLeft - elapsed)
    requestIdRef.current = requestAnimationFrame(update)
  }, [])

  React.useEffect(() => {
    requestIdRef.current = requestAnimationFrame(update)

    return () => { if(requestIdRef.current) cancelAnimationFrame(requestIdRef.current) }
  // only for first mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const timeIsExceeded = timeLeft <= 0
  const timeLeftOverZero = timeIsExceeded ? 0 : timeLeft
  const displayedTime = Math.floor(timeLeftOverZero / 1000)

  React.useEffect(() => {
    if(timeIsExceeded) onTimeExceed()
  // only when `timeIsExceeded`
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeIsExceeded])

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Card
        mode={'darkBlue'}
        cardProps={{
          className: classes.card,
        }}
      >
        <div
          className={classes.row}
        >
          <div
            className={classnames([
              classes.col,
              classes.colLeft,
            ])}
          >
            <Typography
              className={classnames([
                classes.text,
                classes.textCategoryName,
              ])}
            >
              {categoryName}
            </Typography>
            <Typography
              className={classnames([
                classes.text,
                classes.textQuizName,
              ])}
            >
              {quizName}
            </Typography>
          </div>
          <div
            className={classnames([
              classes.col,
              classes.colRight,
              classes.progressWrapper,
            ])}
          >
            <Typography
              className={classnames([
                classes.text,
                classes.progressText,
              ])}
            >
              {displayedTime}
            </Typography>
            <CircularProgress
              className={classnames([
                classes.progress,
              ])}
              variant={'determinate'}
              value={timeLeftOverZero / answerTime * 100}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default HeaderQuizQuestion
