
export const makeSchema = (categoriesOptions: { label: string, value: string }[]) => ({
  title: 'question',
  type: 'object',
  properties: {
    category: {
      type: 'string',
      minLength: 1,
      label: 'Kategoria',
      errorMessage: 'Wprowadź kategorię',
      options: categoriesOptions,
      uniforms: {
        variant: 'outlined',
        type: 'text',
      },
    },
    question: {
      type: 'string',
      minLength: 1,
      label: 'Pytanie',
      errorMessage: 'Wprowadź pytanie',
      uniforms: {
        variant: 'outlined',
        type: 'text',
      },
    },
    answerA: {
      type: 'string',
      minLength: 1,
      label: 'Odpowiedź A',
      errorMessage: 'Wprowadź odpowiedź',
      uniforms: {
        variant: 'outlined',
        type: 'text',
      },
    },
    answerB: {
      type: 'string',
      minLength: 1,
      label: 'Odpowiedź B',
      errorMessage: 'Wprowadź odpowiedź',
      uniforms: {
        variant: 'outlined',
        type: 'text',
      },
    },
    answerC: {
      type: 'string',
      minLength: 1,
      label: 'Odpowiedź C',
      errorMessage: 'Wprowadź odpowiedź',
      uniforms: {
        variant: 'outlined',
        type: 'text',
      },
    },
    answerD: {
      type: 'string',
      minLength: 1,
      label: 'Odpowiedź D',
      errorMessage: 'Wprowadź odpowiedź',
      uniforms: {
        variant: 'outlined',
        type: 'text',
      },
    },
    correctAnswer: {
      type: 'number',
      label: 'Poprawna odpowiedź',
      errorMessage: 'Wskaż poprawną odpowiedź',
      default: 0,
      options: [{ label:'A', value: 0 }, { label:'B', value: 1 },{ label:'C', value: 2 }, { label:'D', value: 3 }],
    },
  },
  required: ['category', 'question', 'answerA', 'answerB', 'answerC', 'answerD', 'correctAnswer' ],
})
