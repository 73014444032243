import React from 'react'

import { makeStyles, Theme, Typography } from '@material-ui/core'

import Card from 'components/atoms/Card'

export interface HeaderQuizzesListProps extends React.HTMLProps<HTMLDivElement> {
  title: string,
  subtitle: string,
}

const useStyles = makeStyles<Theme, HeaderQuizzesListProps>((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  text: {
    color: theme.palette.common.white,
  },
}), { name: 'HeaderQuizzesList' } )

export const HeaderQuizzesList = (props: HeaderQuizzesListProps) => {
  const classes = useStyles(props)
  const {
    title,
    subtitle,
    ...otherProps
  } = props

  return (
    <div
      className={classes.root}
      {...otherProps}
    >
      <Card
        mode={'darkBlue'}
      >
        <Typography
          variant={'h3'}
          align={'center'}
          className={classes.text}
        >
          {title}
        </Typography>
        <Typography
          variant={'h5'}
          align={'center'}
          className={classes.text}
        >
          {subtitle}
        </Typography>
      </Card>
    </div>
  )
}

export default HeaderQuizzesList
