/* eslint-disable max-len */
import React from 'react'

function WalletPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={'24px'}
      height={'24px'}
      viewBox={'0 0 22 21'}
      fill={'none'}
      {...props}
    >
      <path
        d={'M3 0v3H0v2h3v3h2V5h3V3H5V0H3zm7 3v2h9v2h-6c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6v2H5v-9H3v9a2 2 0 002 2h14c1.1 0 2-.9 2-2v-2.28A2 2 0 0022 15V9a2 2 0 00-1-1.72V5c0-1.1-.9-2-2-2h-9zm3 6h7v6h-7V9zm3 1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z'}
        fill={'#225998'}
      />
    </svg>
  )
}

export default WalletPlus
