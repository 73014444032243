import React from 'react'

import { makeStyles, Theme, Divider, Typography } from '@material-ui/core'

import Button from 'components/atoms/Button'
import Card from 'components/atoms/Card'

import Uniform from 'components/molecules/Uniform'

import { schema } from './forgotPasswordSchema'

export interface ForgetPasswordFormModel {
  email: string,
}

export interface FormForgotPasswordProps extends React.HTMLProps<HTMLDivElement> {
  routeToLogin: () => void,
  forgetPasswordHandler: (model: ForgetPasswordFormModel) => void,
}

const useStyles = makeStyles<Theme, FormForgotPasswordProps>((theme) => ({
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  shadow: {
    filter: 'drop-shadow(1px 1px 3px #65A8E8)',
  },
}), { name: 'FormForgotPassword' })

export const FormForgotPassword = (props: FormForgotPasswordProps) => {
  const classes = useStyles(props)
  const {
    routeToLogin,
    forgetPasswordHandler,
    ...otherProps
  } = props

  return (
    <Card
      mode={'white'}
      {...otherProps}
    >
      <Typography
        variant={'h1'}
        align={'center'}
        className={classes.shadow}
        gutterBottom={true}
        color={'secondary'}
      >
        Przypomnij hasło
      </Typography>
      <Uniform
        schema={schema}
        onSubmit={(model: ForgetPasswordFormModel) => forgetPasswordHandler(model)}
        submitField={() => (
          <Button
            className={classes.button}
            type={'submit'}
            label={'Resetuj hasło!'}
            fullWidth={true}
          />
        )}
      />
      <Divider
        className={classes.divider}
      />
      <Button
        label={'Powrót'}
        fullWidth={true}
        color={'secondary'}
        className={classes.forgetPassword}
        onClick={routeToLogin}
      />
    </Card>
  )
}

export default FormForgotPassword
