import React from 'react'

import Loader from 'components/molecules/Loader'

import { useAsyncFn } from 'utils/useAsyncFn'

import { createAuthContext, createUseAuthContext } from './context'
import Snackbars from '../Snackbars'

export interface AuthProps<UserType extends Record<string, unknown>> {
  children?: React.ReactNode,
  logInByEmailAndPassword: (credentials: { email: string, password: string }) => Promise<UserType>,
  checkLogin: () => Promise<UserType>,
  logOut: () => Promise<void>,
  handleOAuthCallback?: () => Promise<void>,
}

export const createAuth =  <UserType extends Record<string, unknown>>() => {
  const AuthContext = createAuthContext<UserType>()
  const useAuthContext = createUseAuthContext<UserType>(AuthContext)

  const Auth =(props: AuthProps<UserType>) => {
    const {
      children,
      logInByEmailAndPassword: logInByEmailAndPasswordFromProps,
      checkLogin: checkLoginFromProps,
      logOut,
      handleOAuthCallback = () => Promise.resolve(),
    } = props

    const [userData, setUserData] = React.useState<UserType | null>(null)

    const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(null)
    const [snackbarErrorMessage, setSnackbarErrorMessage] = React.useState<string | null>(null)

    const [loginState, logIn] = useAsyncFn(logInByEmailAndPasswordFromProps)
    const [checkLoginState, checkLogin] = useAsyncFn(checkLoginFromProps, [], { loading: true })

    const checkLoginHandler = React.useCallback(() => {
      return checkLogin()
        .then((data) => setUserData(data))
        .catch(() => setUserData(null))
    }, [checkLogin])

    React.useEffect(() => {
      handleOAuthCallback().finally(() => checkLoginHandler())
      // only for first mount
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const logInHandler = React.useCallback(async (credentials) => {
      setSnackbarMessage(null)
      setSnackbarErrorMessage(null)
      try {
        const data: UserType = await logIn(credentials)
        setUserData(data)
        return data
      } catch (error) {
        console.error(error, error?.response?.data)
        const is401 = error?.response?.status === 401
        setUserData(null)
        setSnackbarErrorMessage(is401 ? 'Wpisany e-mail lub hasło są niepoprawne!' : 'Wystąpił błąd!')
        throw error
      }
    }, [logIn])
    const logOutHandler = React.useCallback(async () => {
      const data = await logOut()
      setUserData(null)
      return data
    }, [logOut])

    return (
      <AuthContext.Provider
        value={{
          isLoggedIn: Boolean(userData),
          userData,
          logInByEmailAndPassword: logInHandler,
          logOut: logOutHandler,
        }}
      >
        <Loader
          loading={checkLoginState.loading}
          // we do not want to display 401 when checking if user is logged in
          error={null}
        >
          <Loader
            doNotUnmountChildren={true}
            opacity={0.9}
            loading={loginState.loading}
            // we use snackbars for indicating
            error={null}
          >
            {children}
          </Loader>
        </Loader>
        <Snackbars
          snackbarMessage={snackbarMessage}
          snackbarErrorMessage={snackbarErrorMessage}
          onClose={() => setSnackbarMessage(null)}
          onCloseError={() => setSnackbarErrorMessage(null)}
        />
      </AuthContext.Provider>
    )
  }

  return {
    useAuthContext,
    AuthContext,
    Auth,
  }

}

export default createAuth
